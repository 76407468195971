import React, {Component} from 'react';
import { Container, Table } from 'reactstrap';
import { Spinner, Row, Col } from 'reactstrap';
import { InputGroup, InputGroupText, InputGroupAddon, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import HomeApi from '../../../services/API/HomeApi';
import { sortByColumn } from '../../functions';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/actions';
import { convertToDateYYYYMMDD, convertToDateDDMMYYYY } from '../../functions';
import TrackSampleCard from './trackSampleCard';
import PaginationComponent from '../../../components/pagination/pagination';

class TrackSampleTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
          testtableheader: [
            {table_header: "Sr No.", table_sort_name: ""},
            {table_header: "Order No.", table_sort_name: "id"},
            {table_header: "Order Date", table_sort_name: "createdOn"},
            {table_header: "Delivery Status", table_sort_name: ""},
            {table_header: "Lab Status", table_sort_name: ""},
          ],
          testtabledata: [],
          loading: false,
          filter: null,
          toggle: false,
          activeColumn: 0,
          lastActiveColumn: 0,
          modal: false,
          currentPage: 0
        }
        this.pageSize = 10;
    }


    componentWillReceiveProps(nextProps){
      this.setState({
        testtabledata:nextProps.phistoryData
      })
    }

    handleTrackClick = (e, index) => {
    
      e.preventDefault();
  
      this.setState({
        currentPage: index
      });
      
    }


    toggle = () => {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    updateSearch = (inputValue) => {
      let filter = this.state.filter;

      this.setState({
          filter: inputValue
      });
    }

    handleSearchChange = (event) => {
        this.updateSearch(event.target.value);
    }

    filter = (data) => {
      if (!this.state.filter) {
        return data;
      }
      return data.filter((data) => data.id.toString().indexOf(this.state.filter) >= 0 ||
                                   convertToDateDDMMYYYY(data.createdOn).toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0 
    )
      
    }

    handleClick = (title, key) => {
      let arrayCopy = [...this.state.testtabledata];
    if(key === 1 || key === 2){
      if (this.state.activeColumn === key) {
        let toggle = !this.state.toggle
        this.setState({
          toggle: toggle,
          activeColumn: key,
          testtabledata: sortByColumn(arrayCopy, title, toggle)
        })
      } else {
        this.setState({
          activeColumn: key,
          testtabledata: sortByColumn(arrayCopy, title, false)
        })
      }
    }
    }

    render() {
      //console.log("Patient Order Data");
      //console.log(this.props.phistoryData);

      //console.log("Patient logistics Statuses");
      //console.log(this.props.pstatusdata);

      //console.log("Patient livehealth Statuses");
      //console.log(this.props.lpstatusdata);

      // if(this.props.phistoryData && this.props.pstatusdata.length !== 0) {
      //   this.props.phistoryData.forEach((o) => {
      //       this.props.pstatusdata.forEach((p) => {
      //           if(o.awbNo === p.id) {
      //               o["logisticsStatus"] = p.status;
      //           }
      //       })
      //   })
      // }  
      
      const { currentPage } = this.state;
      if(this.state.testtabledata){
      this.pagesCount = Math.ceil(this.state.testtabledata.length / this.pageSize);
      

      this.state.testtabledata.map((el, key) => {
        el["srno"] = key + 1;
      })
    }

      let list = <Spinner animation="border" variant="danger" />
      if (!this.state.loading) {

        list = 
        <React.Fragment>
          <Row className="mt-5">
        <Col md={1} xs={3}className="text-center">Sr No.</Col>
        <Col md={1} xs={3} className="text-center">Order ID</Col>
        <Col md={2} xs={3} className="text-center">Order Date</Col>
        <Col md={2} xs={3} className="text-center">Sample Status</Col>
        <Col md={6} xs={12} className="text-center hidden-xs">Patient List</Col>
        </Row>
        <hr/>
        <Row>
                 <Col className="text-center">
                    {this.state.testtabledata ? this.filter(this.state.testtabledata).slice(
                      currentPage * this.pageSize,
                      (currentPage + 1) * this.pageSize
                    ).map((order, key) => 
                        <TrackSampleCard order={order} keyValue = {key} pstatusdata={this.props.pstatusdata}/>
                        ) : <Spinner color="danger" />
                    }

                 </Col>
               </Row>

          <PaginationComponent currentPage={this.state.currentPage} handleClick={this.handleTrackClick} pagesCount={this.pagesCount} />

        </React.Fragment>
        //   list = <Table>
        //   <tbody>
        //   {this.state.testtabledata ? this.filter(this.state.testtabledata).map((order, key) => {
        //       return <tr>
        //           <td>{key + 1}</td>
        //           <td>{order.id}</td>
        //           <td>{convertToDateYYYYMMDD(order.createdOn)}</td>
        //           <td>{order.logisticsStatus}</td>
        //           <td>
        //             {order.logisticsStatus === "SHIPMENT DELIVERED" ? 
        //             <Table bordered style={{tableLayout: 'fixed',  width:'400px', margin: 'auto'}}>
        //               <thead>
        //               <tr>
        //                 <th>Test Name</th>
        //                 <th>Status</th>
        //               </tr>
        //               </thead>
        //               <tbody>
        //                 {order.livehealthStatus ? order.livehealthStatus.map((t) =>
        //                   <tr>
        //                     <td style={{wordWrap: 'break-word'}}>{t["Test Name"]}</td>
        //                     <td>{t.status}</td>
        //                   </tr>
        //                 ) : null}
                      
        //               </tbody>
        //             </Table> : "-" }
        //               </td>
        //           {/* <td onClick={() => this.openTrackOrderModal(order.lhBillId)}>Track Order</td> */}
        //       </tr>
        //   }) : <Spinner color="danger" />
        // }
        //   </tbody>
        // </Table>
      }
        return(
          <React.Fragment>
               <Container className="mb-5">
               <Row className="mt-2">
                 <Col md="3">
                 <InputGroup size="lg">
                  <InputGroupAddon addonType="prepend">Search</InputGroupAddon>
                  <Input value={this.state.filter} onChange={this.handleSearchChange} type="text" style={{zIndex: 0}}/>
                </InputGroup>
                 </Col>
               </Row>
               <Row className="mt-2">
                 <Col className="text-center">
                 {list} 
                 </Col>
               </Row>
             </Container>

          </React.Fragment>
              
        )
    }
}

const mapStateToProps = state => {
  return {
      loading: state.loading,
      user: state.user,
      phistoryData: state.phistoryData,
      pstatusdata: state.pstatusdata,
      lpstatusdata: state.lpstatusdata
  }
}

const mapDispatchToProps = dispatch => {
  return {
    //onPatientGetOrderStatus: (billId) => dispatch(actions.patientGetOrderStatus(billId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackSampleTable);