import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Alert } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import * as actions from '../../redux/actions/actions';
import PatientForgotSuccess from './patientForgotSuccess';

class PatientForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
        form: this.defaultForm(),
        visible : false,
      };
  }

  defaultForm = () => {
    return {
        mobile: '',
        countryCode: '91',
    }
  }


  handleForgotPassword(e){
    let validationMessages = [];

    if (e.target.type === "number") {
      e.target.value = e.target.value.slice(0,10);
    } 
    
    if(e.target.type === "number" && e.target.value.length < 10){
      validationMessages.push("Required 10 digits number, match requested format.");
      this.setState({validationMessages});
    }
    else {
      validationMessages = [];
      this.setState({validationMessages});
    }

    const updatedForm = {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
      
      this.setState({form: updatedForm, validationMessages});
  }

  forgotPasswordSubmitHandler = (event) => {
    event.preventDefault();
    
    const updatedPatientForm = {
        ...this.state.form,
    }
    if(this.state.validationMessages.length === 0){
      this.props.onForgotPassword(updatedPatientForm);
    }

    this.setState({
      visible: true
    }, () => {
      window.setTimeout(() => {
        this.setState({
          visible: false
        })
      }, 5000)
    });
  }

render() {
return (
    <div class="container">
      <div class="row">
          <div class="col-md-6 offset-md-3 text-center">
            <div className="container-box text-center">
              <div class="logo">
                <img src={logo} className="tthomelogo"/>
              </div>
              {/* <h4>Centre for Oncopathology </h4> */}
              {this.props.success ? 
              <PatientForgotSuccess mobile={this.state.form.mobile}/> :
             <React.Fragment>
              <h2>Forgot Password</h2>
              
              <div className="register-text"><span>Already a member ? &nbsp;</span><span>
                <Link to="/patient-login">Sign In.</Link></span></div>
                <div className="login-box-inner">
                <form onSubmit={this.forgotPasswordSubmitHandler}>
                <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="mobile" name="mobile" type="number" pattern={0-9} placeholder="Mobile No." 
                      value={this.state.form.mobile} onChange={(event) => this.handleForgotPassword(event)} />
                      <label for="mobile" className="patientLabel">Mobile No.</label>
                      <p className="red-font mt-3">{this.state.validationMessages}</p>
                    </span>
                </div>
                <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="countryCode" name="countryCode" type="text" placeholder="Country Code" value={this.state.form.countryCode} />
                      <label for="countryCode" className="patientLabel">Country Code</label>
                    </span>
                </div>

                  <Button className="button-lightred" size="lg" style={{width: '190px'}}>Next</Button>{' '}

                  <div className="register-text"><span>New User ? &nbsp;</span><span>
                  <Link to="/request-otp">Register here.</Link></span></div>

                  {this.props.error ?
                    <Alert className="mt-4" color="danger" isOpen={this.state.visible}>Number is not registered. Please register.</Alert>
                    : null
                  }

                  {this.props.invalidFlag ?
                    <Alert className="mt-4" color="danger" isOpen={this.state.visible}>{this.props.message}</Alert>
                    : null
                  }

                  {this.props.invalidData === "403" ? 
                  <Alert className="mt-4" color="danger" isOpen={this.state.visible}>Invalid credentials, please try again.</Alert>
                  : null
                  }
                </form>
              </div> 
              </React.Fragment>
            }
            </div>
            
          </div>
      </div>
      {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
    </div>
);
}
}

const mapStateToProps = state => {
    return {
        userTypeId: state.userTypeId,
        success: state.success,
        message: state.message,
        error: state.error,
        invalidFlag: state.invalidFlag,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onForgotPassword: (patientForm) => dispatch(actions.patientForgotPassword(patientForm))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(PatientForgotPassword);