import React from "react";
import { Col, Collapse, Row } from "reactstrap";
import "./team.css";

class DescToggle extends React.Component {
  constructor(props) {
    super(props);

    //     this.state = { collapse: this.props.key === 0 ? true : false };
    //   }

    //   toggleArrow = () => {
    //     this.setState({ collapse: !this.state.collapse });
  }

  render() {
    //console.log(this.props.collapseId);
    return (
      <React.Fragment>
        <Row noGutters={true}>
          <Col xs={12} lg={12} md={12} sm={12}>
            {this.props.collapseId == this.props.data.id ? (
              <p style={{ textAlign: "justify" }} className="DescBox fade-in ">
                {this.props.data.team_collapse}
              </p>
            ) : null}
            {/* <Collapse
              toggler="togglerinner"
              isOpen={this.state.collapseId == this.props.data.id}
            > */}

            {/* </Collapse> */}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default DescToggle;
