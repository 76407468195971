import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import './Registration.css';

import doc from '../../assets/Doc.PNG';
import patient from '../../assets/Patient.PNG';
import trainee from '../../assets/Trainee.PNG';

import * as actions from '../../redux/actions/actions';

class RegisterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


render() {
return (
    <React.Fragment>
    <div class="row">
        <div class="col-12 text-center">
            <span style={{fontSize: '20px'}}>Who are you ?</span>
        </div>
    </div>
    <div class="row mb-5 users-avatar">
        <div class="col text-center" onClick={() => this.props.onSetUser(2)}>
            <Link to="/patient-login">
                <img src={patient} />
                <div style={{fontSize: '20px'}} className="red-font">Patient</div>
            </Link>
        </div>
        
        <div class="col text-center" onClick={() => this.props.onSetUser(1)}>
            <Link to="/login">
                <img src={doc} />
                <div style={{fontSize: '20px'}} className="red-font">Credit Partner</div>
            </Link>
        </div>

        {/* <div class="col text-center">
            <Link to="/register-form">
                <img src={trainee} />
                <div style={{fontSize: '20px'}} className="red-font">Trainee</div>
            </Link>
        </div> */}
    </div>
    <div class="row">
        <div class="col-12 col-md-8">
            {/* <span style={{fontSize: '15px'}}>Difficult to decide ? Read more about choosing your role here.</span> */}
        </div>
        
    </div>
    </React.Fragment>
);
}
}

const mapStateToProps = state => {
    return {
        userTypeId: state.userTypeId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetUser: (userId) => dispatch(actions.setUserType(userId))
    }
}

export default connect(null, mapDispatchToProps)(RegisterUser);