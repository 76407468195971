import React, { Component } from "react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";
import { Container, Row, Col, Spinner, UncontrolledCollapse } from "reactstrap";
import { Card, Button, Collapse } from "react-bootstrap";
import "./team.css";
import team from "../../assets/homeSec3.jpg";
import SubFooter from "../../components/subFooter/subFooter";
import HomeApi from "../../services/API/HomeApi";
import TitlebarComponent from "../../components/navbar/titlebar";
import TeamToggle from "./teamToggle";
import DescToggle from "./DescToggle";

class JoinTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamtitle: null,
      teamsubtitle: null,
      teamcontent: [],
      open: false,
      loading: false,
      collapseId: -1,
      width: null
    };
  }

  componentDidMount() {
    HomeApi.getTeamContent().then(data => {
      this.setState({
        teamtitle: data[0].team_title,
        teamsubtitle: data[0].team_sub_title,
        teamcontent: data
      });
    });
  }

  toggleArrow = key => {
    switch (key) {
      case key:
        this.setState(prevState => ({
          arrow: !prevState.arrow
        }));
        break;
    }
  };
  getcollapseId = activecollapse => {
    if (activecollapse !== this.state.collapseId) {
      this.setState({
        collapseId: activecollapse
      });
    } else {
      this.setState({
        collapseId: -1
      });
    }

    //console.log(this.state.collapseId);
  };
  render() {
    let isAuthenticated = localStorage.getItem("token") ? true : false;
    ////console.log(this.state.teamcontent);
    const { open } = this.state;
    this.state.width = window.innerWidth;

    if (this.state.width >= 768) {
      return (
        <React.Fragment>
          <TitlebarComponent loggedinpatient={isAuthenticated} />
          <Navbar />
          <Container>
            <p className="teamHeader m-4">Team</p>
            {/* <p className="teamSubHeader mt-5">Directors</p> */}
            {this.state.loading ? (
              <Spinner color="danger" />
            ) : this.state.teamcontent ? (
              this.state.teamcontent.map((data, key) => (
                <React.Fragment>
                  <Row>
                    <Col xs={12} className="text-center">
                      <p
                        className="teamSubHeader mt-3"
                        style={{ color: "#EC5F57", fontSize: "25px" }}
                      >
                        {data.team_title}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    {data.teammember_id
                      ? data.teammember_id.map(item => {
                          return (
                            <TeamToggle
                              data={item}
                              key={key}
                              getcollapseId={this.getcollapseId.bind(this)}
                              collapseId={this.state.collapseId}
                            />
                          );
                        })
                      : null}
                  </Row>
                  <Row>
                    {data.teammember_id
                      ? data.teammember_id.map(item => {
                          return (
                            <DescToggle
                              data={item}
                              key={key}
                              collapseId={this.state.collapseId}
                            />
                          );
                        })
                      : null}
                  </Row>
                </React.Fragment>
              ))
            ) : null}
            {/* <p className="teamSubHeader mt-5">Technical Experts</p> */}
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="text-center mb-3">
                <a href="/careers">
                  <Button
                    className="button-lightred"
                    size="lg"
                    style={{ width: "190px" }}
                  >
                    Join Our Team
                  </Button>{" "}
                </a>
              </Col>
            </Row>
          </Container>
          {/* <hr/>
        <SubFooter /> */}
          <Footer />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <TitlebarComponent loggedinpatient={isAuthenticated} />
          <Navbar />
          <Container>
            <p className="teamHeader m-4">Teams</p>
            {/* <p className="teamSubHeader mt-5">Directors</p> */}
            {this.state.loading ? (
              <Spinner color="danger" />
            ) : this.state.teamcontent ? (
              this.state.teamcontent.map((data, key) => (
                <React.Fragment>
                  <Row>
                    <Col xs={12} className="text-center">
                      <p
                        className="teamSubHeader mt-3"
                        style={{ color: "#EC5F57", fontSize: "25px" }}
                      >
                        {data.team_title}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    {data.teammember_id
                      ? data.teammember_id.map(item => {
                          return <TeamToggle data={item} key={key} />;
                        })
                      : null}
                  </Row>
                </React.Fragment>
              ))
            ) : null}
            {/* <p className="teamSubHeader mt-5">Technical Experts</p> */}
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="text-center mb-3">
                <a href="/careers">
                  <Button
                    className="button-lightred"
                    size="lg"
                    style={{ width: "190px" }}
                  >
                    Join Our Team
                  </Button>{" "}
                </a>
              </Col>
            </Row>
          </Container>
          {/* <hr/>
      <SubFooter /> */}
          <Footer />
        </React.Fragment>
      );
    }
  }
}

export default JoinTeam;
