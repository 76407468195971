import store from "../../redux/store";
import axios from 'axios';
import {
    changeName,
    loginStart,
    loginSuccess,
    loginFail,
    signupSuccess,
    patientSignupSuccess,
    signupFail,
    userDataFailure,
    userDataSuccess,
    searchPatientSuccess,
    searchPatientFailure,
    creditPartnerOrderSuccess,
    creditPartnerOrderFailure,
    courseDataSuccess, 
    courseDataFailure,
    patientOrderSuccess,
    patientOrderFailure,
    patientOrderStatusSuccess,
    patientOrderStatusFailure,
    pinSuccess,
    patientInvalidLogin,
    patientHistoryDataSuccess,
    patientHistoryDataFailure,
    pinFailure,
    resetSuccess,
    resetFail,
    courseEWSuccess,
    courseEWFailure,
    creditPartnerOrderPatientHistoryDataSuccess,
    creditPartnerOrderPatientHistoryDataFailure,
    careerSuccess,
    careerFailure,
    creditpartnerPatientOrderDetailsSuccess,
    creditPartnerPatientStatus,
    pordermessage,
    orderCompleteSuccess,
    orderCompleteFailure,
    apSearchSuccess,
    apSearchFailure,
    apSaveSuccess,
    apSaveFailure,
    logisticCostSuccess,
    logisticCostFailure,
    sampleTypeSuccess,
    sampleTypeFailure,
    getPAddSuccess,
    getPAddFailure,
    sampleTestSuccess,
    sampleTestFailure,
    editAddSuccess, 
    editAddFailure,
    editOrderSuccess,
    editOrderFailure,
    patientUpdatedAddSuccess,
    patientUpdatedAddFailure,
    userEnrollDataSuccess,
    userEnrollDataFailure,
    userWishlistDataSuccess,
    userWishlistDataFailure,
    userForgotSuccess,
    userForgotFailure,
    CPpatientOrderStatusSuccess,
    creditBalanceSuccess,
    creditBalanceFailure,
} from "../../redux/actions/actions";
import HplServices from "../services";

class LoginRegisterApi {

    static reduxLogin(email, password, token) {

        let url;
        const loginData = {
            email: email,
            password: password,
        };

        if(token) {
            loginData.token = token
        } 

        var config = {
            headers: {
                'Content-type': 'application/json',
            }
        };
        
        if(token){
        url = 'https://www.oncopath.org/api/auth/signin/' + token;
        }
        else{
        url = 'https://www.oncopath.org/api/auth/signin';  
        }

        axios.post(url, loginData)
            .then( response => {
                let userCopy = {
                    ...response.data.user
                }
                
                if(response.data.status === "403"){
                    store.dispatch(patientInvalidLogin(response.data.status));
                }
                else if(response.data.status === "601"){
                    store.dispatch(patientInvalidLogin(response.data.status));
                }
                else if(response.data.status === "200"){
                localStorage.setItem('token', response.data.accessToken);
                localStorage.setItem('lhtoken', response.data.lhToken);
                localStorage.setItem('user', JSON.stringify(userCopy));
                localStorage.setItem('userTypeId', response.data.user.userTypeId);
                store.dispatch(loginSuccess(response.data.accessToken, response.data.user, response.data.user.userTypeId));
                }
            })
            .catch( error => {
                store.dispatch(loginFail(error.message));
        } );

        // HplServices.postaxios(url, loginData, config).then(response => {
        //     // localStorage.setItem('token', response.data.idToken);
        //     // localStorage.setItem('userId', response.data.userId);
        //     store.dispatch(loginSuccess(response.accessToken, response.user, response.message));
        // }).catch(err => {
        //     //console.log(err);
        //     store.dispatch(loginFail(err.message));
        // });
    }

    static reduxSignup(formData) {

        let url = 'https://www.oncopath.org/api/auth/signup';

        var config = {
            headers: {
                'Content-type': 'application/json',
            }
        };

        axios.post(url, formData)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                let errorFlag = response.data.status === "400" ? true : false;
                store.dispatch(signupSuccess(successFlag, response.data.message, errorFlag));
            })
            .catch( error => {
                store.dispatch(signupFail(error));
        } );

        // HplServices.postaxios(url, formData, config).then(response => {
        //     // localStorage.setItem('token', response.data.idToken);
        //     // localStorage.setItem('userId', response.data.userId);
        //     store.dispatch(signupSuccess(response.success, response.message));
        // }).catch(err => {
        //     //console.log(err);
        //     store.dispatch(signupFail(err));
        // });

    }

    static reduxUserCheck(userCheckForm) {

        let url = 'https://www.oncopath.org/api/auth/checkUser';

        var config = {
            headers: {
                'Content-type': 'application/json',
            }
        };

        let formData = new FormData(); 

        formData.append('mobile', '8879919770');   //append the values with key, value pair
        formData.append('countryCode', '91');
        formData.append('developerId', '0aea8dc0-6675-11e9-a923-1681be663d3e');

        axios.post(url, userCheckForm)
            .then( response => {
                //console.log(response);
                let successFlag = response.data.code === 200 ? true : false;
                let errorFlag = response.data.code === 404 ? true : false;
                store.dispatch(signupSuccess(successFlag, response.data.message, errorFlag));
            })
            .catch( error => {
                store.dispatch(signupFail(error.message));
        } );

    //     const data = new URLSearchParams(userCheckForm);
    //     fetch(url, {
    //         method: 'post',
    //         body: data
    //       }, config)
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       //console.log(result);
    //     },
    //     // Note: it's important to handle errors here
    //     // instead of a catch() block so that we don't swallow
    //     // exceptions from actual bugs in components.
    //     (error) => {
    //         //console.log(error);
    //     }
    //   )
    }

    static reduxPatientOtpRequest(patientForm) {

        let url = 'https://www.oncopath.org/api/auth/requestOTP';

        var config = {
            headers: {
                'Content-type': 'application/json',
            }
        };

        axios.post(url, patientForm)
            .then( response => {
                let successFlag = response.data.code === 200 ? true : false;
                let errorFlag = response.data.code === 401 ? true : false;
                store.dispatch(signupSuccess(successFlag, response.data.message, errorFlag));
            })
            .catch( error => {
                store.dispatch(signupFail(error.message));
        } );

    }

    static reduxPatientForgotPassword(patientForm) {

        let url = 'https://www.oncopath.org/api/auth/requestOTPforForgotPassword';

        var config = {
            headers: {
                'Content-type': 'application/json',
            }
        };

        axios.post(url, patientForm, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                let errorFlag = response.data.status === "404" ? true : false;
                let invalidFlag = response.data.status === "400" ? true : false;
                store.dispatch(signupSuccess(successFlag, response.data.message, errorFlag, invalidFlag));
            })
            .catch( error => {
                store.dispatch(signupFail(error.message));
        } );

    }

    static reduxPatientResetPassword(patientForm) {

        let url = 'https://www.oncopath.org/api/auth/ResetPassword';

        var config = {
            headers: {
                'Content-type': 'application/json',
            }
        };

        axios.post(url, patientForm, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                let errorFlag = response.data.status === "400" ? true : false;
                store.dispatch(resetSuccess(successFlag, response.data.message, errorFlag));
            })
            .catch( error => {
                store.dispatch(resetFail(error.message));
        } );

    }

    static reduxPatientSignUp(patientData) {

        let url = 'https://www.oncopath.org/api/auth/signup';

        var config = {
            headers: {
                'Content-type': 'application/json',
            }
        };

        axios.post(url, patientData)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                let errorFlag = response.data.status === "400" ? true : false;

                store.dispatch(patientSignupSuccess(successFlag, response.data.message, errorFlag));
            })
            .catch( error => {
                store.dispatch(signupFail(error.message));
        } );

    }

    static reduxPatientLogin(patientLoginForm) {
        var config = {
            headers: {
                'Content-type': 'application/json',
            }
        };

        let url = 'https://www.oncopath.org/api/auth/signin';

        axios.post(url, patientLoginForm)
            .then( response => {
                let userCopy = {
                   ...response.data.user
                }

                if(response.data.status === "403"){
                    store.dispatch(patientInvalidLogin(response.data.status));
                }
                else if(response.data.status === "601"){
                    store.dispatch(patientInvalidLogin(response.data.status));
                }
                else if(response.data.status === "200"){
                localStorage.setItem('token', response.data.accessToken);
                localStorage.setItem('lhtoken', response.data.lhToken);
                localStorage.setItem('user', JSON.stringify(userCopy));
                localStorage.setItem('userTypeId', response.data.user.userTypeId);
                store.dispatch(loginSuccess(response.data.accessToken, response.data.user, response.data.user.userTypeId));
                }
            })
            .catch( error => {
                store.dispatch(loginFail(error.message));
        } );
    }

    static reduxTestSampleDetails(patientTestDetails) {

        let url = 'https://www.oncopath.org/api/order/saveOrder';

        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };

        axios.post(url, patientTestDetails, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                store.dispatch(patientOrderSuccess(successFlag, response.data.message));
            })
            .catch( error => {
                store.dispatch(patientOrderFailure(error.message));
        } );

    }

    static reduxSearchPatient(id,data) {

    let url = 'https://www.oncopath.org/api/data/getCreditPartnerPatientByName/'+id+'/'+data;

        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };

        axios.get(url, config)
            .then( response => {
                store.dispatch(searchPatientSuccess(response.data.code === 200 ? true : false, response.data.object));
            })
            .catch( error => {
                store.dispatch(searchPatientFailure());
        } );

    }

    static reduxBulkTestSampleDetails(id,bulkPatientTestDetails,courierCost) {
        
        let url;
        if(courierCost === true){
            url = 'https://www.oncopath.org/api/order/placeBulkOrder/'+id+'?parceledByCreditPartner=1';
        }
        else{
            url = 'https://www.oncopath.org/api/order/placeBulkOrder/'+id;
        }

        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };

        axios.post(url, bulkPatientTestDetails, config)
            .then( response => {
                let successFlag = response.data.code === 200 ? true : false;
                let errorFlag = response.data.code === 400 ? true : false;
                let limitError = response.data.code === 422 ? true : false;
                store.dispatch(creditPartnerOrderSuccess(successFlag, errorFlag, limitError, response.data.object));
            })
            .catch( error => {
                store.dispatch(creditPartnerOrderFailure(true));
        } );

    }

    static reduxPinVerification(pincode) {

        let url = 'https://www.oncopath.org/api/order/searchPincode/'+pincode;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                localStorage.setItem('pincode', response.data.pincode);
                let successFlag = response.data.object === "Yes" ? true : false;
                let invalidFlag = response.data.object === "Invalid" ? true : false;
                let errorFlag = response.data.object === "No" ? true : false;
                
                store.dispatch(pinSuccess(successFlag, invalidFlag, errorFlag));
            })
            .catch( error => {
                store.dispatch(pinFailure());
        } );
    
    }

    static reduxOrderHistory(id) {

        let url = 'https://www.oncopath.org/api/order/getPatientsAllOrder/'+ id;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                store.dispatch(userDataSuccess(response.success, response.data, response.error));
            })
            .catch( error => {
                store.dispatch(userDataFailure(error.message));
        } );
    }

    static reduxcourseData() {

        var user = JSON.parse(localStorage.getItem('user'));

        let url = 'https://www.oncopath.org/api/trainee/getCourses/'+ user.id;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                store.dispatch(courseDataSuccess(response.data));
            })
            .catch( error => {
                store.dispatch(courseDataFailure(error.message));
        });
    }

    static reduxcourseAndWishlist(id) {

        var user = JSON.parse(localStorage.getItem('user'));

        let url = 'https://www.oncopath.org/api/trainee/getCoursesAndWishlist/'+ id;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                store.dispatch(courseEWSuccess(response.data));
            })
            .catch( error => {
                store.dispatch(courseEWFailure(error.message));
        });
    }

    static reduxcourseDataOpen() {

        let url = 'https://www.oncopath.org/api/auth/getCourses';
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url)
            .then( response => {
                store.dispatch(courseDataSuccess(response.data));
            })
            .catch( error => {
                store.dispatch(courseDataFailure(error.message));
        });
    }

    static reduxCourseEnroll(userId, courseId) {
        
        const coursesEnrolled = {
            userId: userId,
            courseId: courseId
        };

        let url = 'https://www.oncopath.org/api/trainee/enroll';
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.post(url, coursesEnrolled, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                store.dispatch(userEnrollDataSuccess(successFlag , response.data.message));
            })
            .catch( error => {
                store.dispatch(userEnrollDataFailure(error.message));
        });
    }

    static reduxCourseWishlist(userId, courseId) {
        
        const coursesEnrolled = {
            userId: userId,
            courseId: courseId
        };

        let url = 'https://www.oncopath.org/api/trainee/wishlist';
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.post(url, coursesEnrolled, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                store.dispatch(userWishlistDataSuccess(successFlag, response.data.message, response.error));
            })
            .catch( error => {
                store.dispatch(userWishlistDataFailure(error.message));
        });
    }

    static reduxPatientOrderList(id) {

        let url = 'https://www.oncopath.org/api/order/getPatientsAllOrder/'+id;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.get(url, config)
            .then( response => {
                let successFlag = response.data.code === 200 ? true : false;
                let errorFlag = response.data.code === 400 ? true : false;
                store.dispatch(patientHistoryDataSuccess(successFlag, errorFlag, response.data.object));
            })
            .catch( error => {
                store.dispatch(patientHistoryDataFailure(error.message));
        });
    }

    static reduxCreditPartnerOrderList(id) {

        let url = 'https://www.oncopath.org/api/order/getCreditPartnerOrders/'+id;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.get(url, config)
            .then( response => {
                let successFlag = response.data.code === 200 ? true : false;
                let errorFlag = response.data.code === 400 ? true : false;
                store.dispatch(patientHistoryDataSuccess(successFlag, errorFlag, response.data.object));
            })
            .catch( error => {
                store.dispatch(patientHistoryDataFailure(error.message));
        });
    }
    

    static reduxCreditPartnerOrderPatientsList(id) {

        let url = 'https://www.oncopath.org/api/order/getCreditPartnerOrderPatients/'+id;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.get(url, config)
            .then( response => {
                let successFlag = response.data.code === 200 ? true : false;
                let errorFlag = response.data.code === 400 ? true : false;
                //console.log("CreditPartnerOrderPatientsList");
                //console.log(response.data.object);
                store.dispatch(creditPartnerOrderPatientHistoryDataSuccess(successFlag, errorFlag, response.data.object));
            })
            .catch( error => {
                store.dispatch(creditPartnerOrderPatientHistoryDataFailure(error.message));
        });
    }
    

    static reduxCreditPartnerPatientOrderDetails(orderId, patientId) {

        let url = 'https://www.oncopath.org/api/order/getCreditPartnerPatientOrderDetails/'+ orderId + '/' + patientId;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.get(url, config)
            .then( response => {
                let successFlag = response.data.code === 200 ? true : false;
                let errorFlag = response.data.code === 400 ? true : false;
                store.dispatch(creditpartnerPatientOrderDetailsSuccess(successFlag, response.data.object));
            })
            .catch( error => {
                //console.log(error);
        });
    }

    static reduxPatientGetOrderStatus(billId) {

        let url = 'https://www.oncopath.org/api/order/getOrderStatus/'+ billId;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.get(url, config)
            .then( response => {
                store.dispatch(patientOrderStatusSuccess(response.data.code, null, response.data.reportDetails));
            })
            .catch( error => {
                store.dispatch(patientOrderStatusFailure(error));
        });
    }

    static reduxCPPatientGetOrderStatus(billId) {

        let url = 'https://www.oncopath.org/api/order/getOrderStatus/'+ billId;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.get(url, config)
            .then( response => {
                store.dispatch(CPpatientOrderStatusSuccess(response.data.code, null, response.data.reportDetails));
            })
            .catch( error => {
                store.dispatch(patientOrderStatusFailure(error));
        });
    }

    static reduxPatientGetOrderLogisticsStatus(awbNo) {

        let url = 'https://www.oncopath.org/api/order/logisticsOrderStatus/'+ awbNo;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.get(url, config)
            .then( response => {
                let statusArr = {id: awbNo, status: response.data.object};
                Object.assign(response.data, {statusArr: statusArr});

                store.dispatch(patientOrderStatusSuccess(response.data.code, statusArr, null));
            })
            .catch( error => {
                store.dispatch(patientOrderStatusFailure(error));
        });
    }

    static reduxCPPatientGetOrderLogisticsStatus(awbNo) {

        let url = 'https://www.oncopath.org/api/order/logisticsOrderStatus/'+ awbNo;
    
        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.get(url, config)
            .then( response => {
                let statusArr = {status: response.data.object};
                Object.assign(response.data, {statusArr: statusArr});

                store.dispatch(patientOrderStatusSuccess(response.data.code, statusArr, null));
            })
            .catch( error => {
                store.dispatch(patientOrderStatusFailure(error));
        });
    }

    static reduxCareerForm(form) {

        let url = 'https://www.oncopath.org/api/auth/upload';

        const formData = new FormData(form);
        formData.append('name', form.name)
        formData.append('file', form.file)

        var config = {
            headers: {
                'Content-type': 'multipart/form-data',
            }
        };
    
        axios.post(url, formData, config)
            .then( response => {
                store.dispatch(careerSuccess(response.data));
            })
            .catch( error => {
                store.dispatch(careerFailure(error));
        });
    }

    static reduxCompleteOrder(orderId) {

        let url = 'https://www.oncopath.org/api/order/completeOrder/'+ orderId;

        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.get(url, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                store.dispatch(orderCompleteSuccess(successFlag, response.data.message));
            })
            .catch( error => {
                store.dispatch(orderCompleteFailure(error));
        });
    }

    static reduxAdminPatientSearch(name, mobile) {

        let url = 'https://www.oncopath.org/api/data/admin/searchPatient/'+ name + '/' + '91'+mobile;

        let token = localStorage.getItem('token');

        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                let successFlag = response.status === 200 ? true : false;
                store.dispatch(apSearchSuccess(successFlag, response.data));
            })
            .catch( error => {
                store.dispatch(apSearchFailure(error));
        });
    }

    static reduxAdminSavePatientDetails(id, data) {

        let details = Object.values(data)[0];

        let url = 'https://www.oncopath.org/api/order/saveAdminOrder';

        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.post(url, details, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                store.dispatch(apSaveSuccess(successFlag, response.data.message));
            })
            .catch( error => {
                store.dispatch(apSaveFailure(error));
        });
    }

    static reduxPayNowOrder(orderId, courierCost, admin) {
        
        let url; 

        if(admin === 1){
            url = 'https://www.oncopath.org/api/order/payNow/' + orderId + '?parceledByCustomer=' + courierCost;
        }
        else{
            url = 'https://www.oncopath.org/api/order/payNow/' + orderId;
        }
        
        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                store.dispatch(patientOrderSuccess(successFlag, response.data.message));
            })
            .catch( error => {
                store.dispatch(patientOrderFailure(error));
        });
    }

    static reduxLogisticCost() {

        let url = 'https://www.oncopath.org/api/data/logisticCost';

        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                let successFlag = response.status === 200 ? true : false;
                store.dispatch(logisticCostSuccess(successFlag, response.data));
            })
            .catch( error => {
                store.dispatch(logisticCostFailure(error));
        });
    }

    static reduxSampleType() {

        let url = 'https://www.oncopath.org/api/data/sample';

        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                let successFlag = response.status === 200 ? true : false;
                store.dispatch(sampleTypeSuccess(successFlag, response.data));
            })
            .catch( error => {
                store.dispatch(sampleTypeFailure(error));
        });
    }

    static reduxSampleTest(id) {

        let url = 'https://www.oncopath.org/api/data/sampleTest/' + id;

        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                let successFlag = response.status === 200 ? true : false;
                store.dispatch(sampleTestSuccess(successFlag, response.data));
            })
            .catch( error => {
                store.dispatch(sampleTestFailure(error));
        });
    }

    static reduxGetPatientAddress(userId) {

        let url = 'https://www.oncopath.org/api/data/getPatientAddresses/' + userId;

        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                let successFlag = response.status === 200 ? true : false;
                store.dispatch(getPAddSuccess(successFlag, response.data));
            })
            .catch( error => {
                store.dispatch(getPAddFailure(error));
        });
    }

    static reduxSubmitEditOrder(data) {

        let url = 'https://www.oncopath.org/api/order/editOrder';

        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.post(url, data, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                store.dispatch(editOrderSuccess(successFlag, response.data.message));
            })
            .catch( error => {
                store.dispatch(editOrderFailure(error));
        });
    }

    static reduxPatientEditAddress(updatedAddress) {

        let url = 'https://www.oncopath.org/api/user/updatePatientAddress';

        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.post(url, updatedAddress, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                store.dispatch(editAddSuccess(successFlag, response.data.message));
            })
            .catch( error => {
                store.dispatch(editAddFailure(error));
        });
    }

    static reduxGetPatientUpdatedAddress(id) {

        let url = 'https://www.oncopath.org/api/data/getPatientAddress/' + id;

        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        return axios.get(url, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                store.dispatch(patientUpdatedAddSuccess(successFlag, response.data.data));
            })
            .catch( error => {
                store.dispatch(patientUpdatedAddFailure(error));
        });
    }

    static reduxUserForgotPassword(username) {

        let url = 'https://www.oncopath.org/api/auth/forgotpassword?email=' + username;

        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url)
            .then( response => {
                let successFlag = response.status === 200 ? true : false;
                store.dispatch(userForgotSuccess(successFlag, response.data));
            })
            .catch( error => {
                store.dispatch(userForgotFailure(error));
        });
    }

    static reduxCreditBalance(id) {

        let url = 'https://www.oncopath.org/api/user/getUpdatedCreditAmount/' + id;

        let token = localStorage.getItem('token');
        
        var config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + token ,
            }
        };
    
        axios.get(url, config)
            .then( response => {
                let successFlag = response.data.status === "200" ? true : false;
                let errorFlag = response.data.status === "400" ? true : false;
                store.dispatch(creditBalanceSuccess(successFlag, errorFlag, response.data.data));
            })
            .catch( error => {
                store.dispatch(creditBalanceFailure(error));
        });
    }

}

export default LoginRegisterApi;