import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Button } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';

class PatientPaymentFailure extends Component {

    render() {
    return (
    <div class="container">
    <div class="row">
    <div class="col-md-6 offset-md-3 text-center">
    <div className="container-box text-center">
        <div class="logo">
            <img src={logo} className="tthomelogo"/>
        </div>
        {/* <h4>Centre for Oncopathology </h4> */}
    
        <Row className="pt-3 pb-3">
          <Col><p className="red-font raleway-light font-size">Order was unsuccessfull, payment process failed.<br />Kindly go to home page.
           <br /></p></Col>
        </Row>
        {/* <Row className="pb-3">
          <Col><p className="red-font raleway-light ">To learn more about how Tata Trusts collects, uses, shares and protects your <br /> personal data please read Tata Trusts' Privacy Policy. </p><br />
          </Col>
        </Row> */}
        <Row>
            <Col>
                <Link to="/patientHome"><Button className="button-lightred mt-0 mb-5" size="md" style={{width: '160px', fontSize: '18px'}} >Next</Button>{' '}</Link>
            </Col>
        </Row>
    </div>
    </div>
    </div>
    {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
    </div>
        
    )
}
}
export default PatientPaymentFailure;