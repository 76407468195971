import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Button, Spinner } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import * as actions from '../../redux/actions/actions';
import { connect } from 'react-redux';

class PatientPaymentSuccess extends Component {

    constructor(props){
        super(props);
        this.state = {
            orderOpenToggle: false
        }
    }
    
    componentDidMount() {
        let orderId = this.props.data.orderId;
        //console.log(this.props.data.orderId);

        this.props.onCompleteOrder(orderId);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.ocsuccess){
            this.setState({
                orderOpenToggle: true
            })
        }
    }

    render() {

    return (
    <div class="container">
    <div class="row">
    <div class="col-md-6 offset-md-3 text-center">
    <div className="container-box text-center">
        <div class="logo">
            <img src={logo} className="tthomelogo"/>
        </div>
        {/* <h4>Centre for Oncopathology </h4> */}

        {this.props.ocsuccess !== null ?
        
        this.props.ocsuccess ?
        <Row className="pt-3 pb-3">
          <Col><p className="red-font raleway-light font-size">Order Placed Successfully, Thankyou for ordering.<br />
          You can track your order in Order History section of home page.<br/>
          {/* Track your order here - 
          <Link to={{pathname: '/patientHome', state: {orderOpenToggler: this.state.orderOpenToggle}}}>Click</Link><br/> */}
          Kindly go to home page.<br /></p></Col>
        </Row> :
        <Row className="pt-3 pb-3">
        <Col><p className="red-font raleway-light font-size">Order not placed, please try again.<br />Kindly go to home page.
         <br /></p></Col>
        </Row> 
        
    : <Spinner animation="border" variant="danger" className="text-center" />
    }
        {/* <Row className="pb-3">
          <Col><p className="red-font raleway-light ">To learn more about how Tata Trusts collects, uses, shares and protects your <br /> personal data please read Tata Trusts' Privacy Policy. </p><br />
          </Col>
        </Row> */}
        <Row> 
            <Col>
                <Link to="/patientHome"><Button className="button-lightred mt-0 mb-5" size="md" style={{width: '160px', fontSize: '18px'}} >Next</Button>{' '}</Link>
            </Col>
        </Row>
    </div>
    </div>
    </div>
    {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
    </div>
        
    )
}
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        ocsuccess: state.ocsuccess,
        ocmessage: state.ocmessage,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onCompleteOrder: (orderId) => dispatch(actions.completeOrder(orderId))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PatientPaymentSuccess);