import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';
import TitlebarComponent from '../../components/navbar/titlebar';
import { Card, CardBody, Container, Row, Col, Spinner, Button, FormGroup, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import PatientPaymentForm from './patientPayForm';
import queryString from 'query-string';
import PatientPaymentSuccess from './patientPaymentSuccess';
import PatientPaymentFailure from './patientPaymentFailure';
import { convertToDateFormat, convertToDateYYYYMMDD } from '../functions';

class PatientCashReceipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courierCost: false,
        }
    }

    componentDidMount() {
        this.props.onGetPatientUpdatedAddress(this.props.user.id);
        this.props.onLogisticCost();
    }

    payNowOrder = () => {
        let orderId = this.props.location.state.order.id;
        let admin = this.props.location.state.order.orderedByAdmin;
        this.props.onPayNowOrder(orderId, this.state.courierCost, admin);
    }

    handleCourierCost = () => {
        this.setState(prevState => ({
            courierCost: !prevState.courierCost
        }))
    }

render() {
//console.log(this.props.location.state.order);
//console.log(this.props.user);
let params = queryString.parse(this.props.location.search);
let isAuthenticated = localStorage.getItem('token') ? true : false;
var date = new Date().getDate();
var month = ((new Date().getMonth() + 1) <10?'0':'') + (new Date().getMonth() + 1) ;
var year = new Date().getFullYear();
var hours = new Date().getHours();
var time = new Date();
var min = (time.getMinutes()<10?'0':'') + time.getMinutes() ;

let orderReceiptData = this.props.location.state.order;

return (
<React.Fragment>
{params.paymentStatus === "SUCCESS" ? 
<PatientPaymentSuccess data={params} userId={this.props.user.id}/> :
params.paymentStatus === "FAILURE" ? 
<PatientPaymentFailure data={params} /> :

this.props.pordermessage ? 
<PatientPaymentForm message={this.props.pordermessage}/> :

<React.Fragment>
<TitlebarComponent loggedinpatient={isAuthenticated} />
<Container className="mt-5 mb-5">
<Card className="collapse-box">
    <CardBody>
        <Row>
            <Col md={11}></Col>
            <Col md={1}>
                <Link to="/patientHome"><div>X</div></Link>
            </Col>
        </Row>
        <Row className="mt-5 ml-3">
            <Col md={6}><h5>Patient Name: {this.props.user.firstName + ' ' + this.props.user.lastName}</h5></Col>
            <Col md={6}><h5>Order ID: {orderReceiptData.id}</h5></Col>
        </Row>
        <Row className="mt-3 ml-3">
            <Col md={6}><h5>Patient ID: {this.props.user.id}</h5></Col>
            <Col md={6}><h5>Date: {convertToDateYYYYMMDD(orderReceiptData.createdOn)}</h5></Col>
        </Row>
        {/* <Row className="mt-3 ml-3">
            <Col md={6}><h5>Transaction ID: </h5></Col>
        </Row> */}

        <Row className='mt-5'>
            <Col md={3} xs={2} className="text-center"><b>Sample Name</b></Col>
            <Col md={6} xs={7} className="text-center"><b>Test Name</b></Col>
            <Col md={3} xs={3} className="text-center"><b>Amount</b></Col>
        </Row><hr/>
        
        {orderReceiptData.id ? orderReceiptData.userPatientOrderDetails.map((data) => {
            return <React.Fragment>
            <Row className='mt-4'>
            <Col md={3} xs={2} className="text-center">{data.sampleTest.sample.sampleName}</Col>
            <Col md={6} xs={7} className="text-center">{data.sampleTest.testName}</Col>
            <Col md={3} xs={3} className="text-center">&#8377; {(data.sampleTest.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</Col>
            </Row><hr/>
            </React.Fragment>
        }) : <Spinner color="danger" />
        }
        
        {this.props.patientupdatedAddsuccess ?
            this.props.patientupdatedAddData.islogisticserviceable === 1 && orderReceiptData.parceledByCustomer === 0 ?
        <React.Fragment>
        <Row className='mt-4'>
            <Col md={3} xs={2} className="text-center"></Col>
            <Col md={6} xs={7} className="text-center"><b>Logistic Cost</b></Col>
            <Col md={3} xs={3} className="text-center">&#8377; {orderReceiptData.logisticAmount}</Col>
        </Row><hr/>
        </React.Fragment>
        : null :null}

        <Row className='mt-4'>
            <Col md={3} xs={2} className="text-center"></Col>
            <Col md={6} xs={7} className="text-center"><b>Grand Total</b></Col>
            <Col md={3} xs={3} className="text-center">&#8377; {(orderReceiptData.totalAmount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</Col>
        </Row><hr/>

        {this.props.patientupdatedAddsuccess ?
        <Row>
            <Col>
            {this.props.patientupdatedAddData.islogisticserviceable === 0 && orderReceiptData.orderedByAdmin === 1 ?
            <p className="mb-5 mt-5"><b style={{fontSize: '15px'}}>Centre for Oncopathology's sample pickup services are not available at your address, in pincode - {this.props.patientupdatedAddData.pincode}. 
            Please use any courier service to send the sample to the Centre for Oncopathology, Mumbai.</b></p>
            : null }

            {this.props.patientupdatedAddData.islogisticserviceable === 1 && orderReceiptData.orderedByAdmin === 1 ?
            <React.Fragment>
             <p className="mt-5"><b style={{fontSize: '15px'}}>Centre for Oncopathology offers sample pickup services from your address in pincode - {this.props.patientupdatedAddData.pincode}. <br/><br/>
                 If you don’t wish to use our services, Select the "No pickup service required" checkbox below.</b></p>   
            <Row className="mb-5">
            <Col md={12}>
            <FormGroup check>
            <Label check>
                <Input type="checkbox" value={this.state.courierCost} onChange={this.handleCourierCost}/>{' '}
                No pickup service required 
            </Label>

            </FormGroup>
            </Col>
            </Row>
            </React.Fragment>
            : null }
            </Col>
        </Row>
        : null}

        {this.props.patientupdatedAddsuccess ?
            this.props.patientupdatedAddData.islogisticserviceable === 1 && this.state.courierCost === false && this.props.lcdata? 
            <p className="text-center mt-4" style={{fontWeight: 'bold'}}>Total Charge: &#8377; {(orderReceiptData.testAmount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} (Test Charge) + &#8377; {this.props.lcdata.value} (Logistic Charge) = &#8377; {(Number((this.props.lcdata.value)) + Number((orderReceiptData.testAmount))).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
            :  null :null}

        {this.props.patientupdatedAddsuccess ?
            (this.props.patientupdatedAddData.islogisticserviceable === 0) || (this.props.patientupdatedAddData.islogisticserviceable === 1 && this.state.courierCost === true)? 
            <p className="text-center mt-4" style={{fontWeight: 'bold'}}>Total Charge : &#8377; {(orderReceiptData.testAmount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p> 
            : null :null}

        <Row>
        <Col md={12} className="mt-3 text-center">
            <Button onClick={this.payNowOrder} className="button-lightred" size="md" style={{width: '190px', cursor: 'pointer'}} >Confirm</Button>
        </Col>
        </Row> 

            </CardBody>
            </Card>
            </Container>
            </React.Fragment>
            }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        user: state.user,
        pordersuccess: state.pordersuccess,
        pordermessage: state.pordermessage,
        pordererror: state.pordererror,
        patientupdatedAddsuccess: state.patientupdatedAddsuccess,
        patientupdatedAddData: state.patientupdatedAddData,
        lcsuccess: state.lcsuccess,
        lcdata: state.lcdata,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      onPatientOrderList: (id) => dispatch(actions.patientOrderList(id)),
      onPayNowOrder: (orderId, courierCost, admin) => dispatch(actions.PayNowOrder(orderId, courierCost, admin)),
      onGetPatientUpdatedAddress: (id) => dispatch(actions.getPatientUpdatedAddress(id)),
      onLogisticCost: () => dispatch(actions.logisticCost()),
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(PatientCashReceipt);