import React from 'react';
import { Collapse, Row, Col, Container, Form, Table, Button, CardBody, Card, Alert, FormGroup, Input, Label, } from 'reactstrap';
import Picky from "react-picky";
import * as actions from '../../redux/actions/actions';
import { connect } from 'react-redux';

class AdminCard extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {collapse: this.props.keyValue === 0 ? true : false};
    }

    toggleCard = () => {
      this.setState({ collapse: !this.state.collapse });
    }
    
    render() {
      return (
        <Row > 
        <Col md={12} className="mt-5 mb-3">
        <div className="profile-tab" id="togglerinner" onClick={this.toggleCard}>
        {this.state.collapse ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>}
        {this.props.pdata.firstName ? this.props.pdata.firstName + ' ' + this.props.pdata.lastName
        : 'Patient' + ' ' + (this.props.keyValue+1) } 

        {this.props.pdata.validationMessages ? <span style={{color: 'red', fontWeight: 500}}>{' - ' + this.props.pdata.validationMessages}</span> : null }
        
        <i class="fa fa-close mr-1 mt-1 pull-right" onClick={() => this.props.removePatient(this.props.pdata, this.props.keyValue)}></i> </div>
        
        <Collapse toggler="togglerinner" isOpen={this.state.collapse}>
        <Card className="collapse-box">
        <CardBody>
        <Row>
        <Col md={4} className="mt-3">
        <FormGroup>
            <Label for="title"><b>Title</b></Label>
            <Input type="text" name="designation" value={this.props.pdata.designation} />
        </FormGroup>
        </Col>
        <Col md={4} className="mt-3">
        <FormGroup>
            <Label for="fname"><b>First Name</b></Label>
            <Input type="text" name="firstName" value={this.props.pdata.firstName} />
        </FormGroup>
    </Col>
    <Col md={4} className="mt-3">
        <FormGroup>
            <Label for="lname"><b>Last Name</b></Label>
            <Input type="text" name="lastName" value={this.props.pdata.lastName} />
        </FormGroup>
    </Col>
    </Row>
    <Row>
    <Col md={4} className="mt-3">
        <FormGroup>
            <Label for="email"><b>Email</b></Label>
            <Input type="email" name="email" value={this.props.pdata.email} />
        </FormGroup>
    </Col>
    <Col md={4} className="mt-3">
        <FormGroup>
            <Label for="mobile"><b>Mobile Number</b></Label>
            <Input type="text" name="mobile" value={this.props.pdata.mobile} />
        </FormGroup>
    </Col>
    <Col md={4} className="mt-3">
    <Label for="state"><b>Date of Birth</b></Label>
        <FormGroup>
        <Input type="date" name="dob" value={this.props.pdata.dob} />
        </FormGroup>
    </Col>
    </Row>
    <Row>
    <Col md={4} className="mt-3">
    <FormGroup tag="fieldset">
        <legend className="col-form-label"><b>Gender</b></legend>
        <Col>
        <FormGroup check>
            <Label check>
            <Input type="radio" checked={true} name={'gender' + this.props.keyValue} value="Male" checked={this.props.pdata.gender === "M"} />
            Male
            </Label>
        </FormGroup>
        <FormGroup check>
            <Label check>
            <Input type="radio" checked={true} name={'gender' + this.props.keyValue} value="Female" checked={this.props.pdata.gender === "F"} />
            Female
            </Label>
        </FormGroup>
        <FormGroup check >
            <Label check>
            <Input type="radio" checked={true} name={'gender' + this.props.keyValue} value="Other" checked={this.props.pdata.gender === "Other"} />
            Other
            </Label>
        </FormGroup>
        </Col>
    </FormGroup>
    </Col>
    </Row>
    <hr/>
    
    {this.props.pdata.testList ? this.props.pdata.testList.map((item, key1) => 
    <Row>
    <Col md={1}>
        <i class="fa fa-trash mr-1 pr-3 pt-5 pb-5 pull-right" style={{fontSize: '20px'}} onClick={() => this.props.removeSample(this.props.pdata, this.props.keyValue, key1)}></i>
    </Col>
    <Col md={3} className="mt-3">
        <FormGroup>
            <Label for="exampleSelect"><b>Select Department:</b></Label>
                <Input type="select" name="selectedSample" id="sampleSelect" onChange={(event) => this.props.inputChangedPatientHandler(event, this.props.keyValue, key1)} value={item.selectedSample}>
                <option>Select Sample</option>
                {this.props.stdata.map((el) => <option value={el.id}>{el.sampleName}</option> )}    
                </Input>
        </FormGroup>
    </Col>
    {this.props.stestdata ?
    <Col md={3} className="mt-3">
            <Label for="exampleSelect" id="selectTestLabelAdmin"><b>Select Tests:</b></Label>
            <div>
            <Picky
                value={item.testListArr}
                options={this.props.stestdata}
                onChange={(undefined) => {this.props.selectPatientMultipleOption(undefined, this.props.keyValue, key1); document.getElementById("selectTestLabelAdmin").click()}}
                tabIndex={this.props.keyValue}
                open={false}
                valueKey="id"
                labelKey="testName"
                multiple={true}
                includeSelectAll={true}
                includeFilter={true}
                dropdownHeight={600}
                numberDisplayed={1}
                manySelectedPlaceholder="%s selected"
                allSelectedPlaceholder="%s selected"
            />
            </div>
    </Col>
    : null }
    <Col md={5} className="mt-3">
        {item.testListArr ? item.testListArr.length !== 0 ? <Table bordered>
            <thead>
                <tr>
                <th>Sr No.</th>
                <th>Test Name</th>
                <th>Test Charges</th>
                </tr>
            </thead>
            <tbody>
                {item.testListArr.map((test, key) => 
                <tr>
                <td>{key + 1}</td>
                <td>{test.testName}</td>
                <td>{test.price}</td>
                </tr>)
                }
            </tbody>
        </Table> : null 
        : null
        }
    </Col>
    
</Row>

): null }

{(this.props.grandTotalCharge !== 0) ?
    <Row>
        <Col md={6} className="mt-3 text-right">
            

        </Col>
        <Col md={6} className="mt-3">
            <Row>
                <Col md={9}><b>Total</b></Col>
                <Col md={3}><b>&#8377; {(this.props.grandTotalCharge).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</b></Col>
            </Row>

        </Col>
    </Row>
    : null}
    
<Row>
    <Col md={12} className="mt-3 text-right">
        <Button className="button-lightred" size="md" style={{width: '190px'}}  onClick={() => this.props.addSample(this.props.pdata, this.props.keyValue)}>Add Test(s)</Button>{' '}
    </Col>
</Row>
    </CardBody>
</Card>
</Collapse>

        
    </Col>
    </Row>
      );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        user: state.user,
        stestsuccess: state.stestsuccess,
        stestdata: state.stestdata,
    }
    }
    
    const mapDispatchToProps = dispatch => {
    return {
        onSampleTest: (id) => dispatch(actions.sampleTest(id)),
    }
    }
      
export default connect(mapStateToProps, mapDispatchToProps)(AdminCard);


