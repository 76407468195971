import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Alert } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import './patient.css';

import * as actions from '../../redux/actions/actions';
import { Spinner, Row, Col } from 'react-bootstrap';

class PatientLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationMessages: [],
      form: this.defaultForm(),
      loading: false,
      visible : false
    };
  }


defaultForm = () => {
  return {
      email: '91',
      password: '',
      userType: 2,
  }
}

inputChangedHandler(e) {
  let validationMessages = [];

  if (e.target.type === "number") {
    e.target.value = e.target.value.slice(0,12);
  }
  
  if(e.target.type === "number" && e.target.value.length < 12){
    validationMessages.push("Required 12 digits number, match requested format.");
    this.setState({validationMessages});
  }
  else {
    validationMessages = [];
    this.setState({validationMessages});
  }

  if (e.target.type === "number") {
    e.target.value = e.target.value.slice(0,12); 
  }

  const updatedForm = {
    ...this.state.form,
    [e.target.name]: e.target.value
  }

  this.setState({
    form: updatedForm, 
    validationMessages
  });
    
  }


  submitHandler = (event) => {
    //console.log("login clicked!");
    event.preventDefault();

    this.setState({
      loading: true
    });
    
    const patientLoginForm = {
        ...this.state.form,
        email: this.state.form.email
    }
    this.props.onPatientLogin(patientLoginForm);

    this.setState({
      visible: true
    }, () => {
      window.setTimeout(() => {
        this.setState({
          visible: false
        })
      }, 5000)
    });
  }

  

render() {

return (
  <React.Fragment>
    <div class="container">
      <div class="row">
          <div class="col-md-6 offset-md-3 text-center">
            <div className="container-box text-center">
              <div class="logo">
                <img src={logo} className="tthomelogo"/>
              </div>
              {/* <h4>Centre for Oncopathology </h4> */}
              <h2>Log In</h2>
              <div className="login-box-inner">
                <form onSubmit={this.submitHandler}>
                  <div class="form-group">
                    <span class="has-float-label">
                      {/* <p style={{display: 'inline',position: 'absolute',left: '11px',top: '20px', color: '#9e9e9e'}}>91 - </p> */}
                      <input required class="form-control" id="username" name="email" type="number" pattern={0-9} min="0" max="999999999999" maxLength="12" placeholder="Patient Mobile No." value={this.state.form.email} onChange={(event) => this.inputChangedHandler(event)}/>
                      <label for="username" className="patientLabel">Patient Mobile No.</label>
                      <p className="red-font mt-3">{this.state.validationMessages}</p>
                    </span>
                  </div>
                  <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="password" name="password" type="password" placeholder="Password" value={this.state.form.password} onChange={(event) => this.inputChangedHandler(event)}/>
                      <label for="password" className="patientLabel">Password</label>
                    </span>
                  </div>
                  <div className="register-text forgotpass-text" style={{textAlign: 'left'}}><span>
                  <Link to="/patient-forgotPassword">Forgot Password ?</Link></span></div>
                  <Button className="button-lightred" size="lg" style={{width: '190px'}} >Submit</Button>{' '}
                  {this.props.loading ? 
                                <Row>
                                <Col md={12} className="mt-3 text-center">
                                    <Spinner animation="border" variant="danger" className="text-center" />
                                </Col>
                                </Row>
                                 : null}
                  <div className="register-text">
                      <span>New User ? &nbsp;</span>
                      <span><Link to="/request-otp">Register here.</Link></span> 
                    </div>
                  {/* {this.props.error ? 
                    <h5 className="mt-4" style={{color:'red'}}>Number is not registered. Please register.</h5> : null
                  } */}
                  {this.props.invalidData === "403" ? 
                  <Alert className="mt-4" color="danger" isOpen={this.state.visible}>Invalid credentials, please try again.</Alert>
                  : null
                  }
                  {this.props.invalidData === "601" ? 
                  <Alert className="mt-4" color="danger" isOpen={this.state.visible}>Number is not registered. Please register.</Alert>
                  : null
                  }
                  <h5>{this.props.success}</h5>
                </form>
              </div>
            </div>
            
          </div>
      </div>
      {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
    </div>
    </React.Fragment>
);
}
}

const mapStateToProps = state => {
  return {
      loading: state.loading,
      error: state.error,
      success: state.success,
      userTypeId: state.userTypeId,
      isAuthenticated: state.token !== null,
      token: state.token,
      user: state.user,
      invalidData: state.invalidData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onPatientLogin: (patientLoginForm) => dispatch(actions.patientLogin(patientLoginForm)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientLogin));