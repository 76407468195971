import store from "../../redux/store";
import axios from 'axios';

class HomeApi {

    // static getHomeContent() {
    //     return HplServices.get('/homes').then(response => {
    //         // store.dispatch(changeName(response));
    //         //console.log(response);
    //         return response;
    //     }, error => {
    //         //console.log(error);
    //         return error;
    //     })
    // };


    static getHomeContent() {
        return axios.get('/homes')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    };

    static getAboutUsContent(){
        return axios.get('/abouts')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    };

    static getServiceContent(){
        return axios.get('/servicepages')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    };

    static getHomeTeamContent(){
        return axios.get('/hometeamnews')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    };

    static getTeamContent(){
        return axios.get('/teams')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    };

    static getContactContent(){
        return axios.get('/contacts')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    };

    static getCareerContent(){
        return axios.get('/careers')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    }

    static getTestListContent(){
        return axios.get('/listoftestsoffereds')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    }

    static getResearchMaterialContent(){
        return axios.get('/researchmaterials')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    }

    static getInsightResearchContent(){
        return axios.get('/insightresearches')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    }

    static getAboutCOPContent(){
        return axios.get('/aboutcops')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    }

    static getRegisteredAddress(){
        return axios.get('/registeredaddresses')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    }

    static getBankDetails(){
        return axios.get('/bankdetails')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    }

}

export default HomeApi;

