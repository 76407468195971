export const convertToDateFormat = (isoDate) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    var dateValue = new Date(isoDate);

    let date = dateValue.getDate();

    let month = monthNames[dateValue.getMonth() + 1];

    let year = dateValue.getFullYear();

    let separator = ' ';


    let lastdate = date.toString().slice(-1);
    let extended;



    switch (lastdate) {


        case "1":
            extended = 'st';
            break;
        case "2":
            extended = 'nd';
            break;
        case "3":
            extended = 'rd';
            break;
        default:
            extended = 'th';
            break;
    }



    return `${date}${extended}${separator}${month},${separator}${year}`


}

export const convertToDateYYYYMMDD = (fulldate) => {
    // var dateValue = new Date(fulldate);

    var t = fulldate.split(/[-T:.+]/);
    var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
    var actiondate = d.getDate();
    var actionmonth = d.getMonth() + 1;
    if(actionmonth < 10) { actionmonth = '0' + actionmonth;}
    var actionyear = d.getFullYear();

    // let date = dateValue.getDate();
    // let month = dateValue.getMonth() + 1;
    // if(month < 10) { month = '0' + month;}
    // let year = dateValue.getFullYear();

    let separator = '-';

    return `${actionyear}${separator}${actionmonth}${separator}${actiondate}`

}

export const convertToDateDDMMYYYY = (fulldate) => {
  // var dateValue = new Date(fulldate);

  var t = fulldate.split(/[-T:.+]/);
  var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
  var actiondate = d.getDate();
  var actionmonth = d.getMonth() + 1;
  if(actionmonth < 10) { actionmonth = '0' + actionmonth;}
  var actionyear = d.getFullYear();

  // let date = dateValue.getDate();
  // let month = dateValue.getMonth() + 1;
  // if(month < 10) { month = '0' + month;}
  // let year = dateValue.getFullYear();

  let separator = '-';

  return `${actiondate}${separator}${actionmonth}${separator}${actionyear}`

}

export const convertDOB = (fulldate) => {
  var dateValue = new Date(fulldate);

  let date = dateValue.getDate();
  let month = dateValue.getMonth() + 1;
  if(month < 10) { month = '0' + month;}
  let year = dateValue.getFullYear();

  let separator = '-';

  return `${year}${separator}${month}${separator}${date}`

}

export const sortByColumn = (a, colIndex, reverse) => {
    if (reverse == true) {
      a.sort(sortFunction).reverse();
    } else {
      a.sort(sortFunction);
    }
  
    function sortFunction(a, b) {
      if (a[colIndex] === b[colIndex]) {
        return 0;
      } else {
        return (a[colIndex] < b[colIndex]) ? -1 : 1;
      }
    }
    return a;
  }

export const convertToTimeHHMMSS = (fulldate) => {
  // var timeValue = new Date(fulldate);

  var t = fulldate.split(/[-T:.+]/);
  var timeValue = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);

  let timeHour = timeValue.getHours();
  if(timeHour < 10) { timeHour = '0' + timeHour;} 

  let timeMin = timeValue.getMinutes();
  if(timeMin < 10) { timeMin = '0' + timeMin;} 

  let timeSec = timeValue.getSeconds();
  if(timeSec < 10) { timeSec = '0' + timeSec;}

  let separator = ':';

  return `${timeHour}${separator}${timeMin}${separator}${timeSec}`
}