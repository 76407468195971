import axios from "axios";
axios.defaults.baseURL = "https://www.oncopath.org:5000/";

class HplServices {
  static getaxios(endpoint) {
    return axios
      .get(endpoint)
      .then(response => {
        //console.log(response.data);
        return response.data;
      })
      .catch(error => {
        //console.log(error);
        return error;
      });
  }

  static postaxios(endpoint, data, config) {
    return axios
      .post(endpoint, data, config)
      .then(response => {
        //console.log(response.data);
        return response.data;
      })
      .catch(error => {
        //console.log(error);
        throw error;
      });
  }
}

export default HplServices;
