import React, { Component } from "react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import "./contact.css";
import SubFooter from "../../components/subFooter/subFooter";
import HomeApi from "../../services/API/HomeApi";
import TitlebarComponent from "../../components/navbar/titlebar";
import PractiseMap from "../GoogleMap/PractiseMap";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacttitle: null,
      contactcardtitle: null,
      contactaddress: null,
      contactphone: null,
      contactemail: null,
      addressline1: null,
      addressline2: null,
      addressline3: null,
      addressline4: null,
      contactwhatsapp: null,
      registertitle: null,
      registerline1: null,
      registerline2: null,
      registerline3: null,
      registerline4: null,
      bankdetailstitle: null,
      bankname: null,
      foundationname: null,
      bankaccount: null,
      bankIFSC: null,
      bankMICR: null
    };
  }

  componentDidMount() {
    HomeApi.getContactContent().then(data => {
      this.setState({
        contacttitle: data[0].contact_title,
        contactcardtitle: data[0].contact_card_title,
        contactaddress: data[0].contact_address,
        addressline1: data[0].address_line1,
        addressline2: data[0].address_line2,
        addressline3: data[0].address_line3,
        addressline4: data[0].address_line4,
        contactphone: data[0].contact_phone,
        contactemail: data[0].contact_email,
        contactwhatsapp: data[0].contact_whatsapp
      });
    });

    HomeApi.getRegisteredAddress().then(data => {
      this.setState({
        registertitle: data[0].register_title,
        registerline1: data[0].register_line1,
        registerline2: data[0].register_line2,
        registerline3: data[0].register_line3,
        registerline4: data[0].register_line4
      });
    });

    HomeApi.getBankDetails().then(data => {
      this.setState({
        bankdetailstitle: data[0].bank_details_title,
        bankname: data[0].bank_name,
        foundationname: data[0].foundation_name,
        bankaccount: data[0].bank_account,
        bankIFSC: data[0].bank_IFSC,
        bankMICR: data[0].bank_MICR
      });
    });
  }

  render() {
    let isAuthenticated = localStorage.getItem("token") ? true : false;
    return (
      <React.Fragment>
        <TitlebarComponent loggedinpatient={isAuthenticated} />
        <Navbar />
        <p className="red-font ml-5 mt-4 contactHeader">
          {this.state.contacttitle}
        </p>
        <Container>
          <Card className="m-lg-5 mb-md-5 mt-md-4 mb-5 contactCardBorder">
            <CardBody className="contactCardBody">
              <Row noGutters={true}>
                {/* <Col md={7} className="p-4" noGutters={true}>
                                <p className="ml-4">Send us a Message</p>
                                <div class="contactInputBox">
                                    <input placeholder="Name" type="text" autocomplete="off" />
                                    <label for="name">Name:</label>
                                </div>
                                <div class="contactInputBox">
                                    <input placeholder="Email Address" type="email" autocomplete="off" />
                                    <label for="email">Email Address:</label>
                                </div>
                                <div class="contactInputBox">
                                    <input placeholder="Phone" type="text" autocomplete="off" />
                                    <label for="phone">Phone:</label>
                                </div>
                                <label className="mt-3 ml-4 contactMessageTitle" for="message">Message:</label>
                                <div>
                                    <textarea className="ml-4 mt-2 contactMessageBox" type="text" autocomplete="off" />
                                </div>
                                <button type="submit" className='contactSubmitButton mt-4 ml-4'>Submit</button>
                                </Col> */}
                <Col
                  md={{ size: "6", offset: "" }}
                  xs={{ size: "12" }}
                  className="contactNextColumn"
                >
                  <h4 className="contactNextColumnHeader text-center mt-5 mb-5">
                    {this.state.contactcardtitle}
                  </h4>{" "}
                  <div className="mb-3">
                    <Row noGutters={true}>
                      <Col xs={1}>
                        <span>
                          <i
                            class="fa fa-2x fa-map-marker contactIconsColor mr-3 ml-3"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Col>
                      <Col xs={10} className="ml-1">
                        <span className="contactWhiteClass">
                          {this.state.contactaddress}
                          <br />
                          {this.state.addressline4}
                          <br />
                          {this.state.addressline1}
                          <br />
                          {this.state.addressline2}
                          <br />
                          {this.state.addressline3}
                        </span>
                      </Col>
                    </Row>
                    {/* <span>
                                            <i class="fa fa-2x fa-map-marker contactIconsColor mr-3 ml-3" aria-hidden="true"></i>
                                        </span>
                                        <span className="contactWhiteClass">{this.state.contactaddress}<br/>
                                        {this.state.addressline1}<br/>
                                        {this.state.addressline2}<br/>
                                        {this.state.addressline3}
                                        </span> */}
                  </div>
                  <div className="mb-3">
                    <span>
                      <i
                        class="fa fa-2x fa-phone contactIconsColor mr-3 ml-3"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span className="contactWhiteClass">
                      {this.state.contactphone}
                    </span>
                  </div>
                  <div className="mb-4">
                    <span>
                      <i
                        class="fa fa-2x fa-at contactIconsColor mr-3 ml-3"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span className="contactWhiteClass">
                      {this.state.contactemail}
                    </span>
                  </div>
                  <div className="mb-4">
                    <span>
                      <i
                        class="fa fa-whatsapp fa-2x fa-at contactIconsColor mr-3 ml-3"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span className="contactWhiteClass">
                      {this.state.contactwhatsapp}
                    </span>
                  </div>
                </Col>

                <Col className="mapp" md={{ size: "6" }} xs={{ size: "12" }}>
                  <Row noGutters={true}>
                    <Col
                      className="text-left ml-3"
                      md={{ size: "12" }}
                      xs={{ size: "12" }}
                    >
                      <p className="mt-5">
                        <h3>{this.state.registertitle}</h3>
                        {this.state.registerline1} <br />
                        {this.state.registerline2} <br />
                        {this.state.registerline3} <br />
                        {this.state.registerline4}
                      </p>
                    </Col>
                    <Col
                      className="text-left mt-4 ml-3"
                      md={{ size: "12" }}
                      xs={{ size: "12" }}
                    >
                      <p>
                        {" "}
                        <h3> {this.state.bankdetailstitle}</h3>
                        {this.state.foundationname} <br />
                        {this.state.bankname} <br />
                        {this.state.bankaccount} <br />
                        {this.state.bankIFSC} <br />
                        {this.state.bankMICR}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row noGutters={true}>
            <Col
              md={{ size: "6" }}
              sm={{ size: "12" }}
              xs={{ size: "12" }}
              style={{ padding: "10px" }}
              className="text-left mt-n5 regOfcAddress"
            ></Col>
          </Row>

          <Row noGutters={true}>
            <Col
              md={{ size: "6" }}
              sm={{ size: "12" }}
              xs={{ size: "12" }}
              style={{ padding: "10px" }}
              className="text-left mt-2 regOfcAddress"
            ></Col>
          </Row>
        </Container>
        {/* <hr/>
               <SubFooter /> */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default ContactUs;
