import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';
import '../login/Login.css';

class CheckUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
        form: this.defaultForm()
      };
  }

  defaultForm = () => {
    return {
        mobile: '',
        countryCode: '',
    }
  }
  
  handleUserCheck(e){
    const updatedForm = {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
      let validationMessages = [];
      this.setState({form: updatedForm, validationMessages});
  }

  userCheckSubmitHandler = (event) => {
    //console.log("User Check clicked!");
    event.preventDefault();
    
    const userCheckForm = {
        ...this.state.form,
    }
    this.props.onUserCheck(userCheckForm);
  }



render() {
  
  let userPresent = null;
  if(this.props.success) {
    userPresent = <Redirect to="/patient-login" />
  }
  if(this.props.error) {
    userPresent = <Redirect to="/request-otp" />
  }
  
return (
    <React.Fragment>
      {userPresent}
    <div class="container">
      <div class="row">
          <div class="col-md-6 offset-md-3 text-center">
            <div className="container-box text-center">
              <div class="logo">
                <img src={logo} className="tthomelogo"/>
              </div>
              {/* <h4>Centre for Oncopathology </h4> */}
              <h2>Check if user exists ?</h2>
              
              <div className="login-box-inner">
                <form onSubmit={this.userCheckSubmitHandler}>
                <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="mobile" name="mobile" type="text" placeholder="Mobile No." value={this.state.form.mobile} onChange={(event) => this.handleUserCheck(event)}/>
                      <label for="mobile" className="patientLabel">Mobile No.</label>
                    </span>
                </div>
                <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="countryCode" name="countryCode" type="text" placeholder="Country Code of the Mobile No." value={this.state.form.countryCode} onChange={(event) => this.handleUserCheck(event)}/>
                      <label for="countryCode" className="patientLabel">Country Code</label>
                    </span>
                </div>
                  
                
                  <Button className="button-lightred" size="lg" style={{width: '190px'}}>
                   Submit
                  </Button>
                  
                  <h5>{this.props.error}</h5>
                  <h5>{this.props.message}</h5>


                </form>
              </div>
            </div>
            
          </div>
      </div>
      {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
    </div>
    </React.Fragment>
);
}
}

const mapStateToProps = state => {
    return {
        userTypeId: state.userTypeId,
        success: state.success,
        message: state.message,
        error: state.error,
        user: state.user,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      onUserCheck: (userCheckForm) => dispatch(actions.userCheck(userCheckForm))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CheckUser);