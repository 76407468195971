import React from "react";
import "./PractiseMap.css";

class Popup extends React.Component {
  render() {
    return (
      <div className="popup">
        <i
          class="fa fa-times-circle fa-lg"
          aria-hidden="true"
          onClick={this.props.closePopup}
        ></i>
        {/* <button onClick={this.props.closePopup}></button> */}
        <div className="popupinner">
          <h1 className="MapHtag text-center">Centre for Oncopathology</h1>
          <p className="MapPtag text-center">
          3rd floor, Rectifier House,
570, Naigaum Cross Road,
Wadala, Mumbai – 400 031.
          </p>
        </div>
      </div>
    );
  }
}

export default Popup;
