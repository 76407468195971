import React from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';

const AdminTitle = (props) => (
     <React.Fragment>
       <Container fluid>
        <Row noGutters={true}>
          <Col xs="4"><img src={logo} className="tthomelogo ml-0 ml-md-5"/></Col>
          {/* <Col xs="12 pl-3" lg="3"><h4 className="red-font ml-3 p-3 text-center">Centre for Oncopathology </h4></Col> */}
          {/* <Col xs="12 pl-3" lg="2" className="text-center mb-1 pr-3 text-lg-right"><span id="demo-2" className="navSearch mt-lg-3"><input type="Search" /></span> </Col> */}
          <Col xs="12 pl-3" lg="5" className="text-center mb-1 pr-3 text-lg-right">
          {!props.loggedinpatient ?
          null
          : 
          <a href="/logout">
          <div className="mr-5 mt-3 mb-3 red-font" style={{fontSize: '18px', fontWeight: '600'}}>Logout</div>
          </a>
         }
        </Col>
        </Row>
        {/* <Row>
          <Col className="text-right mb-3">
          <span className="mr-5 mb-3">07-06-19 V-2.1</span>
          </Col>
        </Row> */}
        </Container>
      </React.Fragment>
);

export default AdminTitle;