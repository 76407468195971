import React from 'react';
import { Collapse, Table, Row, Col, ListGroup, ListGroupItem} from 'reactstrap';
import Picky from "react-picky";
import { convertToDateYYYYMMDD, convertToTimeHHMMSS, convertToDateDDMMYYYY } from '../../functions';
import { connect } from 'react-redux';

class TrackSampleCard extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {collapse : false};
    }

    toggleCard = () => {
        this.setState({ collapse: !this.state.collapse });     
    }

    render() {
      return (
        <React.Fragment>
            <Row className="mt-3">
               <Col md={1} xs={3} className="text-center">{this.props.order.srno}</Col>
               <Col md={1} xs={3} className="text-center">{this.props.order.id}</Col>
               <Col md={2} xs={3} className="text-center">{convertToDateDDMMYYYY(this.props.order.createdOn)}</Col>
               {this.props.order.parceledByCreditPartner === 1 ? 
               <Col md={2} xs={4} className="text-center">Not Applicable</Col> 
               : 
               this.props.order.awbNo !== null && this.props.order.logisticsStatus ? 
               this.props.order.logisticsStatus.status.status === "Incorrect Waybill number or No Information" && this.props.order.pickUpTime ? 
               <Col>Sample pickup scheduled on {convertToDateDDMMYYYY(this.props.order.pickUpTime)} between 4:00 PM to 7:00 PM</Col> :
               <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus.status.status}</Col> 
               : null

              //  this.props.order.awbNo !== null ?
              //   this.props.order.logisticsStatus ? 
              //   this.props.order.logisticsStatus.status.status === "Incorrect Waybill number or No Information" ?
              //   this.props.order.pickUpTime ? <Col>Sample pickup scheduled on {convertToDateDDMMYYYY(this.props.order.pickUpTime)} at {convertToTimeHHMMSS(this.props.order.pickUpTime)}</Col>: 
              //   <Col>-</Col> : 
              //   <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus.status.status}</Col> : 
              //   <Col>-</Col>  
              //  :
              //  this.props.order.parceledByCreditPartner === 1 || this.props.order.parceledByCreditPartner === 0?
              //  <Col md={2} xs={4} className="text-center">{this.props.order.logisticStatus.status}</Col> :
              //  <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus}</Col>
               }
               {/* {this.props.order.parceledByCreditPartner === 0 ?
               <Col md={2} xs={4} className="text-center">{this.props.order.logisticStatus.status}</Col> :
               <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus}</Col>
               } */}
               {/* {this.props.order.isLogisticServiceable === 1 ? 
               <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus}</Col> :
               <Col md={2} xs={4} className="text-center">NA</Col>
               } */}
               <Col md={6} xs={12} className="text-center">
               <div onClick={this.toggleCard}>
               <Collapse toggler="togglerinner" isOpen={this.state.collapse}>
                {this.props.order.hasOwnProperty('creditPartnerPatients') ? this.props.order.creditPartnerPatients.map((orderPatient) => 
                    <React.Fragment>
                    {/* {this.props.order.sampleStatus.desc === "Sample Not Received" ? 
                  <Table bordered style={{tableLayout: 'fixed', margin: 'auto'}}>
                  <thead>
                      <tr>
                          <th colSpan={1} style={{backgroundColor: 'rgba(204, 204, 204, 0.6509803921568628)'}}>{orderPatient.firstName + " " + orderPatient.lastName}</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>{this.props.order.sampleStatus.desc}</td>
                      </tr>
                  </tbody>
                </Table>  : */}
                  
                   { this.props.order.logisticsStatus === "SHIPMENT DELIVERED" || (this.props.order.parceledByCreditPartner === 1 && this.props.order.logisticStatus.status) || this.props.order.parceledByCreditPartner === 0? 
                    <Table bordered style={{tableLayout: 'fixed', margin: 'auto'}}>
                      {orderPatient.creditPartnerDetails ?
                      orderPatient.creditPartnerDetails.sampleStatus.id === 2 ?
                      <React.Fragment>
                      <thead>
                      <tr>
                          <th colSpan={1} style={{backgroundColor: 'rgba(204, 204, 204, 0.6509803921568628)'}}>{orderPatient.firstName + " " + orderPatient.lastName}</th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{orderPatient.creditPartnerDetails.sampleStatus.desc}</td>
                        </tr>
                      </tbody>
                      </React.Fragment> :
                      <React.Fragment>
                        <thead>
                            <tr>
                                <th colSpan={2} style={{backgroundColor: 'rgba(204, 204, 204, 0.6509803921568628)'}}>{orderPatient.firstName + " " + orderPatient.lastName}</th>
                            </tr>
                        <tr>
                          <th>Test Name</th>
                          <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                          {orderPatient.livehealthStatus ? orderPatient.livehealthStatus.map((t) =>
                            <tr>
                              <td style={{wordWrap: 'break-word'}}>{t["Test Name"]}</td>
                              <td>{t.status}</td>
                            </tr>
                          ) : null}
                        
                          </tbody>
                        </React.Fragment> : null}
                      </Table> : "-" }
                    </React.Fragment>
                    ) : null}
                </Collapse>
                {this.state.collapse ? 
                    <i class="fa fa-angle-double-up fa-2x"></i> : 
                    <i class="fa fa-angle-double-down fa-2x"></i>
                }
               </div>
               </Col>
            </Row>
            <hr/>
        </React.Fragment>  
        );
    }

}
  

export default TrackSampleCard;



