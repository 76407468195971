import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import React, { Component } from "react";
import Popup from "./Popup";
import "./PractiseMap.css";
class PractiseMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPopup: false };
  }
  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }
  render() {
    return (
      <Map
        className="mapsizee"
        google={this.props.google}
        zoom={12}
        initialCenter={{ lat: 19.0115112, lng: 72.8521545 }}
        // style={{ height: "380px", width: "400px" }}
      >
        <Marker
          position={{ lat: 19.0115112, lng: 72.8521545 }}
          onClick={this.togglePopup.bind(this)}
        />
        {this.state.showPopup ? (
          <Popup closePopup={this.togglePopup.bind(this)} />
        ) : null}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCOJDrZ_DXmHzbzSXv74mULU3aMu3rNrQc"
})(PractiseMap);
