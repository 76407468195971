import React from 'react';
import { Collapse, Table, Row, Col} from 'reactstrap';
import Picky from "react-picky";
import { convertToDateYYYYMMDD, convertToDateDDMMYYYY } from '../functions';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';

class OrderDataCard extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {collapse : false};
    }

    toggleCard = () => {
        this.setState({ collapse: !this.state.collapse });     
    }

    render() {
        //console.log(this.props.order.creditPartnerPatients);
      return (
        <React.Fragment>
            <Row className="mt-3">
               <Col md={1} xs={4} className="text-center">{this.props.order.srno}</Col>
               <Col md={2} xs={4} className="text-center">{this.props.order.id}</Col>
               <Col md={2} xs={4} className="text-center">{convertToDateDDMMYYYY(this.props.order.createdOn)}</Col>
               <Col md={7} xs={12} className="text-center">
                   
               <div onClick={this.toggleCard}>
               <Collapse toggler="togglerinner" isOpen={this.state.collapse}>
                    <Row>
                        <Col md={2}>Patient ID</Col>
                        <Col md={3}>Name</Col>
                        <Col md={2}>Sample</Col>
                        <Col md={3}>Test Name</Col>
                        <Col md={2}>Price</Col>
                    </Row><hr/>
                    {this.props.order.hasOwnProperty('creditPartnerPatients') ? this.props.order.creditPartnerPatients.map((orderPatient) =>
                    <React.Fragment>
                    <Row>
                        <Col md={2}>{orderPatient.id}</Col>
                        <Col md={3}>{orderPatient.firstName + " " + orderPatient.lastName}</Col>
                        <Col md={7}>
                        {orderPatient.creditPartnerDetails ? orderPatient.creditPartnerDetails.creditPartnerPatientOrderDetails.map((testSample) => {
                            return <React.Fragment>
                                <Row>
                                    <Col md={4}>{testSample.sampleTest.sample.sampleName}</Col>
                                    <Col md={4}>{testSample.sampleTest.testName}</Col>
                                    <Col md={4}>&#8377; {(testSample.sampleTest.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</Col>
                                </Row><hr/>
                                </React.Fragment>
                        }) : null}
                        </Col>
                    </Row><hr/>
                    </React.Fragment>
                    ) : null}
                </Collapse>
                {this.state.collapse ? 
                    <i class="fa fa-angle-double-up fa-2x"></i> : 
                    <i class="fa fa-angle-double-down fa-2x"></i>
                }
               </div>
               </Col>
            </Row>
            <hr/>
            
        </React.Fragment>  
        );
    }

}
  

export default OrderDataCard;



