import React, { Component } from "react";
import "./home.css";
import { Row, Col, Container, Card, Spinner, Button } from "reactstrap";

class HomePageSection1 extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <Row noGutters={true}>
          <div class="ticker-wrap">
<div class="ticker">
  <div class="ticker__item">“Dear Doctor / Patient,</div>
  <div class="ticker__item">In these difficult times of COVID-19 related lockdown, many of our colleagues are unable to reach the workplace. Courier facilities are also not available.</div>
  <div class="ticker__item">Hence, our laboratory will not be able to accept samples currently.  We regret the inconvenience caused to you.</div>
  <div class="ticker__item">We will let you know once we are functional again.      Thank you.” </div>
   
</div>
</div></Row> */}
        <Row noGutters={true}>

          <div className="ReceptionBackground">
            <Col md={{ size: 12 }}>
              <p className="homeHeader">
                {this.props.title}
                <a href="/aboutCOP">
                  <Button
                    className="button-lightred ml-3 text-left"
                    size="md"
                    style={{
                      width: "110px",
                      marginTop: "-10px",
                      fontSize: "12px"
                    }}
                  >
                    ...Know More
                  </Button>
                </a>
              </p>
            </Col>
          </div>
        </Row>

        <hr />
        <Container>
          <p className="homeSubHeader mt-5">Key Objectives</p>
          <Row className="mb-5">
            {this.props.homekey ? (
              this.props.homekey.map(data => {
                return (
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <Card className="homeKeyCard text-center">
                      <p>{data.key_title}</p>
                      <hr />
                      <p className="mt-4">{data.key_content}</p>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <Spinner color="danger" />
            )}
          </Row>
        </Container>
        <hr />
        <Container>
          <p className="homeSubHeader mt-5">Values</p>
          <Row className="mt-4 mb-5">
            {this.props.homevalue ? (
              this.props.homevalue.map(data => {
                return (
                  <Col lg={3} md={6} sm={12} xs={12}>
                    <Card className="valuesCard">
                      <Row>
                        <Col className="homeValueNo" xs={2}>
                          {data.value_number}
                        </Col>
                        <Col className="homeValue pt-2" xs={10}>
                          {data.value_text}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <Spinner color="danger" />
            )}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default HomePageSection1;
