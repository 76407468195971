import React, {Component} from 'react';
import Picky from "react-picky";
import { Label, Row, Col, FormGroup, Input, Table} from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';

class PatientPicky extends Component {

    render() {
        return (
            <Picky
                value={this.props.valueData}
                options={this.props.optionData}
                onChange={(undefined) => {this.props.selectMultipleOption(undefined, this.props.keyValue); document.getElementById("selectTestLabelPatientHome").click()}}
                open={false}
                valueKey= "id"
                labelKey="testName"
                multiple={true}
                includeSelectAll={true}
                includeFilter={true}
                numberDisplayed={1}
                manySelectedPlaceholder="%s selected"
                allSelectedPlaceholder="%s selected"
                dropdownHeight={600}
            />
        )
    }
}
  
export default PatientPicky;
