import axios from 'axios';

class MediaApi {

    static getMediaContent() {
        return axios.get('/media')
            .then( response => {
                //console.log(response.data);
                return response.data;
            })
            .catch( error => {
                //console.log(error);
                return error;
        } );
    };



}

export default MediaApi;
