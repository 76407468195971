import React, {Component} from 'react';
import { Container, Row, Col, Label, Input, Button, ListGroup, ListGroupItem, Spinner, Alert } from 'reactstrap';
import * as actions from '../../redux/actions/actions';
import { connect } from 'react-redux';
import '../register/Registration.css';
import HomeApi from '../../services/API/HomeApi';
import AdminPatientAddress from './patientAddress';
import AdminCard from './adminCard';
import AdminTitle from './adminTitle';
import ModalComponent from '../../components/modal/modal';

class AdminHome extends Component {
    constructor(props){
        super(props);
        this.state = {
            form: this.defaultForm(),
            testList: this.defaultTestDetails(),
            testtableheader: [],
            testtabledata: [],
            patient: [],
            sampleTypes: ['Select Sample', 'Sample 1', 'Sample 2', 'Sample 3'],
            filter: null,
            address: this.defaultAPAddress(),
            loading: false,
            visible: false,
            yesToggle: false,
            noLGCharge: false,
            islogisticserviceable: null,
            cardVisible: true,
            saveButtonvisible: false,
        }
    }

    componentDidMount(){
        // HomeApi.getTestListContent().then(res => {
        //   //console.log(res);
        //   this.setState({ 
        //       testtableheader: res[0].listoftestsheaders,
        //       testtabledata: res[0].listoftestsoffereddata,
        //       loading: false,
        //       filter: null
        //    });
        // });

        this.props.onSampleType();
        this.props.onLogisticCost();
    }

    defaultForm = () => {
        return {
            name: '',
            mobile: '',
            countryCode: '91',
        }
    }

    defaultTestDetails = () => {
        return {
            value: null,
            selectedSample: '',
            testListArr: []
        }
    }

    defaultAPAddress = () => {
        return {
            line1:'',
            line2:'',
            city:'',
            state:'',
            pincode:''
        }
    }

    inputChangedHandler(e) {
        const updatedForm = {
          ...this.state.form,
          [e.target.name]: e.target.value
        }
        this.setState({
            form: updatedForm
        });
    }

    submitHandler = (event) => {
        event.preventDefault();

        this.props.onAdminPatientSearch(this.state.form.name, this.state.form.mobile);
    }

    selectExistingPatient = (existingPatient) => {
        
        this.props.onAdminPatientSearch("","");

        let patientwithExistingPatient = {   
            userId: existingPatient.id,
            designation: existingPatient.designation,
            firstName: existingPatient.firstName,
            lastName: existingPatient.lastName,
            email: existingPatient.emailId,
            mobile: existingPatient.contactNo,
            gender: existingPatient.gender,
            dob: existingPatient.dob,
            testList: [
                {
                    value: null,
                    selectedSample: '',
                    testListArr: [],
                }
            ],
        };

        this.setState(prevState => ({
            filter: "",
            patient: [...prevState.patient, patientwithExistingPatient]
          }))

        this.setState({
            form: {
                name: '',
                mobile: ''
            },
            saveButtonvisible: true
        })

        this.props.ongetPatientAddress(existingPatient.id);
    }

    removePatient = (patientData, key) => {
        const patientafterDeleteArr = this.state.patient;

        patientafterDeleteArr.splice(key, 1);

        this.setState({
            patient: patientafterDeleteArr,
            saveButtonvisible: false
        });
    }

    addSample = (pdata, key) => {
        pdata.testList.push({
            value: null,
            selectedSample: '',
            testListArr: []
        });

        const updatedPatientTestDetails = [
            ...this.state.patient.slice(0,key),
            Object.assign({}, this.state.patient[key], {testList: pdata.testList}),
           ...this.state.patient.slice(key+1)
        ]
        
        this.setState({ patient: updatedPatientTestDetails });
    }

    removeSample = (pdata, key, key1) => {

        pdata.testList.splice(key1, 1);

        const updatedPatientTestDetails = [
            ...this.state.patient.slice(0,key),
            Object.assign({}, this.state.patient[key], {testList: pdata.testList}),
           ...this.state.patient.slice(key+1)
        ]

        this.setState({
            patient: updatedPatientTestDetails
        });
    }

    inputChangedPatientHandler = (e, key, key1) => {
        
            let patient = this.state.patient;
            let id = e.target.value;
        
            if(e.target.value !== "Select Sample"){
            this.props.onSampleTest(id);
            }

            const updatedForm = [
                ...patient[key].testList.slice(0,key1),
                Object.assign({}, patient[key].testList[key1], {[e.target.name]: e.target.value}),
                ...patient[key].testList.slice(key1 + 1),
            ]

              const updatedPatient = [
                  ...this.state.patient.slice(0,key),
                  Object.assign({}, this.state.patient[key], {testList: updatedForm}),
                  ...this.state.patient.slice(key + 1),
              ]

              let validationMessages = [];
              this.setState({
                patient: updatedPatient, 
                validationMessages
              });
        
    }

    inputChangedAPHandler = (e) => {
        e.preventDefault();
        const updatedForm = {
            ...this.state.address,
            [e.target.name]: e.target.value
        }
        this.setState({
            address: updatedForm
        });
    }

    selectPatientMultipleOption = (value, key, key1) => {
        console.count('onChange')
        //console.log("Val", value);

        let patient = this.state.patient;

        const updatedPatientTestDetails = [
            ...patient[key].testList.slice(0,key1),
            Object.assign({}, patient[key].testList[key1], {testListArr: value}),
            ...patient[key].testList.slice(key1 + 1),
          ]
  
        const updatedPatient = [
              ...patient.slice(0,key),
              Object.assign({}, patient[key], {testList: updatedPatientTestDetails}),
              ...patient.slice(key + 1),
          ]

        this.setState({ patient: updatedPatient }, () => {
            this.calculateCharge(value, key, key1);
        });
    }


    calculateCharge = (test, key, key1) => {
        // let testListChargeArr = [];
        // this.state.patient.map((el) => {
        //     el.testList.map((elsub) => {
        //     testListChargeArr.push(elsub.testListArr);
        // })
        // })
 

        let testTotalCharge = test.reduce((acc, test) => {
            return acc + Number(test.test_charge)
        }, 0)

        const updatedPatientTestDetails = [
            ...this.state.patient[key].testList.slice(0,key1),
            Object.assign({}, this.state.patient[key].testList[key1], {testTotalCharge: testTotalCharge}),
            ...this.state.patient[key].testList.slice(key1 + 1),
          ]
  
        const updatedPatient = [
              ...this.state.patient.slice(0,key),
              Object.assign({}, this.state.patient[key], {testList: updatedPatientTestDetails}),
              ...this.state.patient.slice(key + 1),
          ]

          this.setState({ patient: updatedPatient });
    }

    checkPatientDetails = (patients) => {

        let flag = null;

        patients.forEach(function (patient) {
            if(patient.designation !== "" && patient.firstName !== "" && patient.lastName !== "" && patient.email !== "" && patient.mobile !== "" && patient.dob !== "") {
                patient.testList.forEach(function (test) {
                    if(flag || flag === null) {
                    if(test.selectedSample !== "" && test.testListArr.length !== 0) {
                        flag = true;
                    }
                    else {
                        flag = false;
                    }
                }
                });
            }
            else {
                flag = false;
            }
        });
        //console.log(flag);
        return flag;
    }

    savePatientTestDetails = (event) => {
        event.preventDefault();
        this.props.onAdminPatientSearch("","");
        let bulkPatientTestDetails = this.state.patient.map((patient, key) => {
            const patientTestListIdArr = patient.testList.map((el) => {
                return el.testListArr.map(function(subtest) {
                    return subtest.lhTestId;
                  })
            })

            var patientTestListId = [].concat.apply([],patientTestListIdArr).filter((elem, index, self) => {
                return index === self.indexOf(elem);
            })

            let noLGChargeData = this.state.noLGCharge;
            let islogisticserviceable;
            if(noLGChargeData === false){
                // this.setState({
                //     islogisticserviceable: 1
                // })
                islogisticserviceable= 1
            }
            if(noLGChargeData === true){
                // this.setState({
                //     islogisticserviceable: 0
                // })
                islogisticserviceable= 0
            }
            
            const patientTestDetails = {
                userId: patient.userId,
                testList: [...patientTestListId],
            }
            return patientTestDetails;
        })

        //console.log(bulkPatientTestDetails);
        //console.log(this.props.user.id);

        let id = this.props.user.id;

        this.props.onAdminSavePatientDetails(id, bulkPatientTestDetails);

        this.setState({
            cardVisible: false,
            saveButtonvisible: false
        })

        this.setState({
            visible: true
          }, () => {
            window.setTimeout(() => {
              this.setState({
                visible: false
              })
            }, 5000)
          });
    }

    componentWillReceiveProps(){
        if(this.props.apsavesuccess){
            this.setState({
                patient: [],
                form: []
            })
        }
    }

    handlePinVerification = (e) => {
        this.setState({
            pincode: e.target.value,
            address: {
                ...this.state.address,
                pincode: e.target.value
            }
        })
    }

    pinVerification = (event) => {
        event.preventDefault();
        
        this.props.onPinVerification(this.state.address.pincode);

        this.setState({
            visible: true
          }, () => {
            window.setTimeout(() => {
              this.setState({
                visible: false
              })
            }, 5000)
          });

        this.setState(prevState => ({
            pinmodal: !prevState.pinmodal,
            noLGCharge: false,
        }))
    }

    togglemodal = () => {
        this.setState(prevState => ({
            pinmodal: !prevState.pinmodal
        }));
    }

    yescloseToggle = () => {
        this.setState(prevState => ({
            pinmodal: !prevState.pinmodal
        }));

        this.setState({
            yesToggle: true,
            noLGCharge: true,
        })
    }

    nocloseToggle = () => {
        this.setState(prevState => ({
            pinmodal: !prevState.pinmodal
        }));

        this.setState({
            yesToggle: false
        })
    }

    getAddressInputChangedHandler = (event) => {
       //console.log(event.target.value);
       
       if(event.target.value === "Select..."){
            this.setState({
                address: {}
        })
        }

       let id = event.target.value;
       
       this.props.getPAdata.map((data) => {
        return data.id === Number(id) ?
        this.setState({
            address: data
        })
        : null
       })
    }

    render() {

    //console.log(this.props.stdata);

    let userTypeId = localStorage.getItem('userTypeId');
    let isAuthenticated = localStorage.getItem('token') ? true : false;
    let grandTotalArr = [];
    let charge = this.props.lcdata;
    this.state.patient.forEach((el) => {
        el.testList.forEach((testEl) => {
            grandTotalArr.push(testEl.testListArr);
        })
    })
        
    let grandTotalCharge = [].concat.apply([],grandTotalArr).reduce((acc, test) => {
        return acc + Number(test.price)
    }, 0)
        //console.log(this.props.apsearchdata);
        return (
            <React.Fragment>
                <AdminTitle loggedinpatient={isAuthenticated}/>
                <Container>
                    <Row><h4 className="ml-3 mt-3">Search for existing patient</h4></Row>
                    <Row className="mt-4">
                        <Col md="3" className="text-center mt-3">
                        <Label>Name :</Label>
                        <Input type="text" value={this.state.form.name} name="name" onChange={(event) => this.inputChangedHandler(event)} required></Input>
                        </Col>
                    
                        <Col md="3" className="text-center mt-3">
                        <Label>Mobile No. :</Label>
                        <Row>
                        <Col xs={2} sm={2} md={2} lg={2} className="text-center" style={{paddingRight: '0px'}}>
                        <Input type="text" className="contactInitialTextNumber" value={this.state.form.countryCode} name="countryCode" onChange={(event) => this.inputChangedHandler(event)} required style={{padding: '0px'}}></Input>  
                        </Col>
                        <Col xs={10} sm={10} md={10} lg={10} className="contactInitialTextNumber">
                        <Input type="number" className="contactInitialTextNumber" value={this.state.form.mobile} name="mobile" onChange={(event) => this.inputChangedHandler(event)} required></Input>
                        </Col>
                        </Row>
                        </Col>
                        <Col md="1" className="text-center mt-5">
                        <Button onClick={this.submitHandler}>Search</Button>
                        </Col>
                    </Row>
                    {this.props.apsearchdata ? 
                    <ListGroup flush>
                        {this.props.apsearchdata.map((el) => 
                        <ListGroupItem style={{cursor: 'pointer'}} onClick={() => this.selectExistingPatient(el)}>{el.firstName + ' ' + el.lastName + ' - (' + el.contactNo + ')'}</ListGroupItem>
                        )
                        }
                    </ListGroup>
                        :
                    <div className="text-center p-3">
                        {!this.props.apsearchsuccess ? this.props.loading ? <Spinner animation="border" variant="danger" /> : null : <div style={{color: 'red'}}>Patient Not Found</div>}
                    </div> 
                    }

                    {this.state.patient && this.state.cardVisible ? this.state.patient.map((pdata, key) =>
                    <React.Fragment>
                     <AdminCard testtabledata={this.state.testtabledata} sampleTypes={this.state.sampleTypes} keyValue={key} pdata={pdata} removePatient={this.removePatient} inputChangedPatientHandler={this.inputChangedPatientHandler} removeSample={this.removeSample} 
                     addSample={this.addSample} selectPatientMultipleOption={this.selectPatientMultipleOption} stdata={this.props.stdata} stestdata={this.props.stestdata} grandTotalCharge={grandTotalCharge} />
                     {/* <AdminPatientAddress pinVerification={this.pinVerification} handlePinVerification={this.handlePinVerification} inputChangedAPHandler={this.inputChangedAPHandler} apAddress={this.state.address}
                                          pinsuccess={this.props.pinsuccess} pinfailure={this.props.pinfailure} pindata={this.props.pindata} visible={this.state.visible} togglemodal={this.togglemodal} 
                                          yescloseToggle={this.yescloseToggle} nocloseToggle={this.nocloseToggle} pinmodal={this.state.pinmodal} getPAdata={this.props.getPAdata}
                                          getAddressInputChangedHandler={this.getAddressInputChangedHandler}/> */}
                    </React.Fragment>
                    ) : null}

                    {/* <Row>
                    <Col md={12} className="mt-3 text-center">
                        {this.state.cardVisible && grandTotalCharge && (this.props.pinsuccess || (this.props.pinfailure && this.state.yesToggle)) ?
                        this.state.noLGCharge ? 
                        <p className="text-center" style={{fontWeight: 'bold'}}>Total Charge : &#8377; {grandTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p> :
                        <p className="text-center" style={{fontWeight: 'bold'}}>Total Charge: Test Charge + &#8377; {charge.value} (Logistic Charge) = &#8377; {(Number(charge.value) + Number(grandTotalCharge)).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
                        : null }

                    </Col>
                    </Row> */}
                    
                    
                    {this.state.patient && this.state.cardVisible && this.state.saveButtonvisible ?
                    <Row className="mb-5">
                    <Col md={12} className="mt-3 text-center">
                        <Button onClick={this.savePatientTestDetails} disabled={this.checkPatientDetails(this.state.patient) ? false : true} className="button-lightred" size="md" style={{width: '190px'}} >Save Details</Button>{' '}
                    </Col>
                    </Row>
                    : null
                    }

                    {this.props.apsavesuccess === true ? 
                      <Alert className="mt-4" color="success" isOpen={this.state.visible}>{this.props.apsavemessage}</Alert>
                    : null
                    }

            {/* {this.props.pinfailure ?
            <ModalComponent modal={this.state.pinmodal} pinVerification={this.pinVerification} className="trainee-modal">
                <h5>The area is un-serviceable. If you still want to continue, Kindly send sample to XYZ address.</h5>
                <Button color="success" onClick={this.yescloseToggle}>Yes</Button>
                <Button color="danger" onClick={this.nocloseToggle} className="ml-3">No</Button>
            </ModalComponent>
            : null } */}

        </Container>
        </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
return {
    loading: state.loading,
    apsearchsuccess: state.apsearchsuccess,
    apsearchdata: state.apsearchdata,
    user: state.user,
    apsavesuccess: state.apsavesuccess,
    apsavemessage: state.apsavemessage,
    pinsuccess: state.pinsuccess,
    pindata: state.pindata,
    pinfailure: state.pinfailure,
    lcsuccess: state.lcsuccess,
    lcdata: state.lcdata,
    getPAsuccess: state.getPAsuccess,
    getPAdata: state.getPAdata,
    stsuccess: state.stsuccess,
    stdata: state.stdata,
    stestsuccess: state.stestsuccess,
    stestdata: state.stestdata,
}
}

const mapDispatchToProps = dispatch => {
return {
    onAdminPatientSearch: (name, mobile) => dispatch(actions.adminPatientSearch(name, mobile)),
    onAdminSavePatientDetails: (id, data) => dispatch(actions.adminSavePatientDetails(id, data)),
    onPinVerification: (pincode) => dispatch(actions.pinVerification(pincode)),
    onLogisticCost: () => dispatch(actions.logisticCost()),
    ongetPatientAddress: (userId) => dispatch(actions.getPatientAddress(userId)),
    onSampleType: () => dispatch(actions.sampleType()),
    onSampleTest: (id) => dispatch(actions.sampleTest(id)),
}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);