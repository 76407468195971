import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { convertDOB } from '../functions';
import * as actions from '../../redux/actions/actions';
import PatientRegisterSuccess from './patientRegisterSuccess';
import ModalComponent from '../../components/modal/modal';
import { runInThisContext } from 'vm';
import DatePicker from "react-date-picker";

class PatientSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: this.defaultForm(),
      modal: false,
      mandatoryMessage: false,
      patientSignUpError: this.props.patientSignUpError,
    };
  }


defaultForm = () => {
  return {
      designation: '',
      firstName: '',
      lastName: '',
      emailId: '',
      contactNo: this.props.location.state.contactNo,
      userTypeId: 2,
      gender: '',
      dob: new Date(),
      password: '',
      countryCode: this.props.location.state.countryCode,
      age: '22',
      otp: '',
      line1:'',
      line2:'',
      city:'',
      pincode:''
  }
}


inputChangedHandler(e) {
  let validationMessages = [];

  if (e.target.type === "number") {
    e.target.value = e.target.value.slice(0,10);
  } 
  
  if(e.target.type === "number" && e.target.value.length < 10){
    validationMessages.push("Required 10 digits number, match requested format.");
    this.setState({validationMessages});
  }
  else {
    validationMessages = [];
    this.setState({validationMessages});
  }

  const updatedForm = {
    ...this.state.form,
    [e.target.name]: e.target.value
  }
  this.setState({form: updatedForm, validationMessages});
    
}

inputChangedAddressHandler (e) {
  const updatedForm = {
    ...this.state.form,
    [e.target.name]: e.target.value
  }
  this.setState({form: updatedForm});
}

handleDOBChange(date) {
  //let convertedDate = convertToDateYYYYMMDD(date);
  const updatedFormDate = {
    ...this.state.form,
    dob: date
  }
  let validationMessages = [];
  this.setState({
    form: updatedFormDate, validationMessages
  });
}


  submitHandler = (event) => {
    //console.log("login clicked!");
    event.preventDefault();

    const updatedPatientForm = {
      designation: this.state.form.designation,
      firstName: this.state.form.firstName,
      lastName: this.state.form.lastName,
      emailId: this.state.form.emailId,
      contactNo: this.props.location.state.contactNo,
      userTypeId: 2,
      gender: this.state.form.gender,
      dob: convertDOB(this.state.form.dob),
      password: this.state.form.password,
      countryCode:this.props.location.state.countryCode,
      age: '22',
      otp: this.state.form.otp,
      address: {
        line1: this.state.form.line1,
        line2: this.state.form.line2,
        city: this.state.form.city,
        pincode: this.state.form.pincode
      }
    }

    this.props.onPatientSignUp(updatedPatientForm);

    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  checkRegisterFormDetails = () => {

    let flag = null;
    if(flag || flag === null){
            if(this.state.form.designation !== "" && this.state.form.firstName !== "" && this.state.form.lastName !== "" && this.state.form.emailId !== ""
            && this.state.form.contactNo !== "" && this.state.form.gender !== "" && this.state.form.dob !== "" && this.state.form.password !== "" 
            && this.state.form.countryCode !== "" && this.state.form.otp !== "" && this.state.form.line1 !== "" && this.state.form.city !== ""
            && this.state.form.pincode !== "") {
                flag = true;
            }
            else {
                flag = false;
            }
    }
    //console.log(flag);
    return flag;
}


render() {
//console.log(this.state.form);
return (
  
  <div class="container">
  <div class="row">
  <div class="col-12 text-center">
    <div className="register-box">
      <div class="logo">
        <img src={logo} className="tthomelogo"/>
      </div>
      {/* <h4 className="red-font raleway-light">Centre for Oncopathology </h4> */}
      
      <div className="register-container-box login-box-inner">
      {this.props.successflag ? 
        <PatientRegisterSuccess  /> 
      :
      <React.Fragment>
        <div class="row">
            <div class="col-11 register-heading">
                <span>Patient Sign Up</span>
            </div>
        {/* <div class="col-1">
            <Link to="/request-otp" ><div>X</div></Link>
        </div> */}
        </div>
        <div class="row">
      {/* <div class="col-12 text-center mb-5">
          <span style={{fontSize: '18px'}}>Enter Your Details</span>
      </div> */}
      <div class="col-12 mb-5 mt-5">
          <span style={{fontSize: '18px'}}>Please create the account with patients details as the reports generated 
          for the tests ordered will have registered users' name only.</span>
      </div>
      {/* <p className="red-font ml-4 mb-4">*All fields are mandatory</p> */}
  </div>     
    <Form>
      <Row form>
      <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
        <FormGroup>
          <Label for="state"><span className="starSize">*</span>OTP</Label>
          <Input required type="text" value={this.state.form.otp} name="otp" placeholder="OTP" onChange={(event) => this.inputChangedHandler(event)}/>
        </FormGroup>
      </Col>
      </Row>
      <Row form>
        <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
          <FormGroup>
            <Label for="title"><span className="starSize">*</span>Title</Label>
            <Input type="select" name="designation" value={this.state.form.designation} placeholder="Select" onChange={(event) => this.inputChangedHandler(event)}>
              <option>Select Title</option>
              <option>Mr.</option>
              <option>Mrs.</option>
              <option>Miss.</option>
              <option>Ms.</option>
              <option>Master</option>
              <option>Dr.</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
          <FormGroup>
            <Label for="fname"><span className="starSize">*</span>First Name</Label>
            <Input required type="text" value={this.state.form.firstName} name="firstName" placeholder="First Name" onChange={(event) => this.inputChangedHandler(event)} />
          </FormGroup>
        </Col>
        <Col md={3} className="ml-md-3 ml-lg-5 mr-md-3 mr-lg-5">
          <Label for="lname"><span className="starSize">*</span>Last Name</Label>
          <FormGroup>
            <Input required type="text" value={this.state.form.lastName} name="lastName" placeholder="Second Name" onChange={(event) => this.inputChangedHandler(event)} />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
      <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
      <FormGroup>
          <Label for="address"><span className="starSize">*</span>Country Code</Label>
          <Input required type="text" value={this.state.form.countryCode} name="countryCode" placeholder="Country Code" onChange={(event) => this.inputChangedHandler(event)}/>
        </FormGroup>
      </Col>
      <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
      <FormGroup>
          <Label for="mobile"><span className="starSize">*</span>Patient Mobile Number</Label>
          <Input required type="number" value={this.state.form.contactNo} name="contactNo" placeholder="Patient Mobile Number" onChange={(event) => this.inputChangedHandler(event)} />
          <p className="red-font mt-3">{this.state.validationMessages}</p>
        </FormGroup>
      </Col>
      <Col md={3} className="ml-md-3 ml-lg-5 mr-md-3 mr-lg-5">
      <FormGroup>
          <Label for="email"><span className="starSize">*</span>Email</Label>
          <Input required type="email" value={this.state.form.emailId} name="emailId" placeholder="Email Id" onChange={(event) => this.inputChangedHandler(event)}/>
          {this.props.patientSignUpError ?
          <p className="red-font mt-3">Email Id already exists.</p>
          : null}
        </FormGroup>
      </Col>
      </Row>
      <Row>
      <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
      <FormGroup>
          <Label for="state"><span className="starSize">*</span>Password</Label>
          <Input required type="password" value={this.state.form.password} name="password" placeholder="Password" onChange={(event) => this.inputChangedHandler(event)}/>
        </FormGroup>
      </Col>
      <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
        <Label for="state"><span className="starSize">*</span>Date of Birth</Label>
        <FormGroup>
        <DatePicker
          value={this.state.form.dob}
          onChange={(date) => this.handleDOBChange(date)}
          maxDate={new Date()}
        />
        {/* <Input required type="date" name="dob" value={this.state.form.dob} onChange={(event) => this.inputChangedHandler(event)} /> */}
        </FormGroup>
        </Col>
      <Col sm={3} className="ml-md-3 mr-md-3 mr-lg-5">
      <FormGroup tag="fieldset">
        <legend className="col-form-label"><span className="starSize">*</span>Gender</legend>
        <Col>
          <FormGroup check>
            <Label check>
              <Input required type="radio" name="gender" value="M" checked={this.state.form.gender === "M"} onChange={(event) => this.inputChangedHandler(event)}/>
              Male
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input required type="radio" name="gender" value="F" checked={this.state.form.gender === "F"} onChange={(event) => this.inputChangedHandler(event)}/>
             Female
            </Label>
          </FormGroup>
          <FormGroup check >
            <Label check>
              <Input required type="radio" name="gender" value="Other" checked={this.state.form.gender === "Other"} onChange={(event) => this.inputChangedHandler(event)}/>
              Other
            </Label>
          </FormGroup>
        </Col>
      </FormGroup>
      </Col>
      </Row>
      <Row form>
      <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
      <FormGroup>
          <Label for="address"><span className="starSize">*</span>Address Line 1</Label>
          <Input required type="text" value={this.state.form.line1} name="line1" placeholder="Line 1" onChange={(event) => this.inputChangedHandler(event)}/>
        </FormGroup>
      </Col>
      <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5 mt-3">
      <FormGroup>
          <Label for="mobile">Address Line 2</Label>
          <Input type="text" value={this.state.form.line2} name="line2" placeholder="Line 2" onChange={(event) => this.inputChangedHandler(event)} />
        </FormGroup>
      </Col>
      </Row>
      <Row form>
      <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
      {/* <FormGroup>
          <Label for="address">*State</Label>
          <Input required type="text" value={this.state.form.state} name="state" placeholder="State" onChange={(event) => this.inputChangedHandler(event)}/>
        </FormGroup> */}
        <FormGroup>
          <Label for="email"><span className="starSize">*</span>City</Label>
          <Input required type="text" value={this.state.form.city} name="city" placeholder="City" onChange={(event) => this.inputChangedHandler(event)}/>
        </FormGroup>
      </Col>
      <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
      <FormGroup>
          <Label for="mobile"><span className="starSize">*</span>Pincode</Label>
          <Input required type="text" value={this.state.form.pincode} name="pincode" placeholder="Pincode" onChange={(event) => this.inputChangedHandler(event)} />
        </FormGroup>
      </Col>
      </Row>

      <Row>
        <Col>
          <Button disabled={this.checkRegisterFormDetails(this.state.form) ? false : true} className="button-lightred mt-0" size="md" style={{width: '120px'}} onClick={this.toggleModal}>Submit</Button>
        </Col>
      </Row>

      {this.checkRegisterFormDetails(this.state.form) ?
      null :
      <h5 style={{color: 'red'}} className="mt-3">Fields marked with  <span className="starSize"> * </span>  are required.</h5>
      }

      <h5>{this.props.successflag}</h5>

      {/* {this.props.invalidData === "403" ? 
                  <Alert className="mt-4" color="danger" isOpen={this.state.visible}>Invalid credentials, please try again.</Alert>
                  : null
                  } */}
    </Form> 

    <ModalComponent modal={this.state.modal} toggleModal={this.toggleModal}>
      <h5>Are you sure you what to submit the form ?</h5>
      <Button color="success" onClick={this.submitHandler}>Yes</Button>
      <Button color="danger" onClick={this.toggleModal} className="ml-3">No</Button>
    </ModalComponent>
    </React.Fragment>
      }
      </div>
      

    </div>
    
  </div>
</div>
  {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
</div>
  
  
);
}
}

const mapStateToProps = state => {
  return {
      userTypeId: state.userTypeId,
      successflag: state.successflag,
      message: state.message,
      patientSignUpError: state.patientSignUpError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onPatientSignUp: (updatedPatientForm) => dispatch(actions.patientsignup(updatedPatientForm))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientSignUp);