import React, {Component} from 'react';
import Picky from "react-picky";
import { Label, Row, Col, FormGroup, Input, Table } from 'reactstrap';
import * as actions from '../../redux/actions/actions';
import { connect } from 'react-redux';
import uniq from 'lodash/uniq';

class PatientEditOrderSample extends Component {

    render() {
        let optionArr = [];
        let result = [];
        return ( 

        this.props.editOrderData.userPatientOrderDetails.map((data) => {
        return this.props.stdata.map((el) => {
        return data.sampleTest.sample.id === el.id ?
        optionArr.push(el) : null
        })
        })  && 
        result.push(uniq(optionArr, 'id')) && 
        result.map(resultData => {
            return resultData.map(data => {
            return <option value={data.id}>{data.sampleName}</option>
        })
        })
                 
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        user: state.user,
        stestsuccess: state.stestsuccess,
        stestdata: state.stestdata,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onSampleTest: (id) => dispatch(actions.sampleTest(id)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PatientEditOrderSample);

