import React, {Component} from 'react';
import { Container, Table } from 'reactstrap';
import { Spinner, Row, Col } from 'reactstrap';
import { InputGroup, InputGroupText, InputGroupAddon, Input, Button, Alert } from 'reactstrap';
import Footer from '../../components/footer/footer';
import TitlebarComponent from '../../components/navbar/titlebar';
import Navbar from '../../components/navbar/navbar';
import HomeApi from '../../services/API/HomeApi';
import SubFooter from '../../components/subFooter/subFooter';
import { sortByColumn, convertToDateYYYYMMDD } from '../functions';
import './trainee.css';
import LoginRegisterApi from '../../services/API/LoginRegisterApi';
import { connect } from 'react-redux';
import axios from 'axios';
import * as actions from '../../redux/actions/actions';
import ModalComponent from '../../components/modal/modal';
import ModalWishlistComponent from '../../components/modal/modalwishlist';
import Login from '../login/Login';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';


class CoursesOffered extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          filter: null,
          toggle: false,
          activeColumn: 0,
          lastActiveColumn: 0,
          showModal: false,
          disable: true,
          disableKey: null,
          selectedId: '',
          confirmModal: false,
          addContainer: false,
          courseName: '',
          visible: false
        }
    }

    componentDidMount(){

      let isAuthenticated = localStorage.getItem('token') ? true : false;
      
      if(isAuthenticated) {
        this.props.oncourseData();
      }
      else{
        this.props.oncourseDataOpen();
      }
    }

    componentWillReceiveProps(nextProps){
      if(nextProps.enrollSuccess || nextProps.wishlistSuccess){
        this.props.oncourseData();
      }
    }

    updateSearch = (inputValue) => {
      let filter = this.state.filter;

      this.setState({
          filter: inputValue
      });
    }

    handleSearchChange = (event) => {
        this.updateSearch(event.target.value);
    }

    filter = (data) => {
      if (!this.state.filter) {
        return data;
      }
        return data.filter((data) => data.id.toString().indexOf(this.state.filter) >=0 ||
                                     data.courseName.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0 ||
                                     data.startDate.toString().indexOf(this.state.filter) >= 0 ||
                                     data.duration.toString().indexOf(this.state.filter) >= 0 
      )
    }

    handleClick = (title, key) => {
      let arrayCopy = [...this.state.testtabledata];
    if(key === 0 || key === 2 || key === 4){
      if (this.state.activeColumn === key) {
        let toggle = !this.state.toggle
        this.setState({
          toggle: toggle,
          activeColumn: key,
          testtabledata: sortByColumn(arrayCopy, title, toggle)
        })
      } else {
        this.setState({
          activeColumn: key,
          testtabledata: sortByColumn(arrayCopy, title, false)
        })
      }
    }
    }

    enrollCourse = (event) => {
      event.preventDefault();
      
        let courseId = this.state.selectedId;
        let disableKey = this.state.disableKey;
        var obj = [courseId]
        //console.log(obj);

        this.props.onCourseEnrolled(this.props.user.id, obj);

        this.setState(prevState => ({
          modal: !prevState.modal,
          disable: disableKey
        }));

        this.setState({
          visible: true
        }, () => {
          window.setTimeout(() => {
            this.setState({
              visible: false
            })
          }, 5000)
        });
      }

    togglemodalEnroll = (id, courseName, key) => {
      this.setState(prevState => ({
        modal: !prevState.modal,
        selectedId: id,
        courseName: courseName,
        disableKey: key
      }));
    }

    checkLoginTrainee = () => {
        //console.log("Clicked");

        this.setState(prevState => ({
          addContainer: !prevState.addContainer
        }));
    }

    wishlistCourse = (event) => {
      event.preventDefault();

        let courseId = this.state.selectedId;
        let disableKey = this.state.disableKey;
        var obj = [courseId]
        //console.log(obj);

        this.props.onCourseWishlist(this.props.user.id, obj);

        this.setState(prevState => ({
          confirmModal: !prevState.confirmModal,
          disable: disableKey
        }));

        this.setState({
          visible: true
        }, () => {
          window.setTimeout(() => {
            this.setState({
              visible: false
            })
          }, 5000)
        });
      }

    togglemodalWishlist = (id, courseName, key) => {
      this.setState(prevState => ({
        confirmModal: !prevState.confirmModal,
        selectedId: id,
        courseName: courseName,
        disableKey: key
      }));
    }

    render() {

      let isAuthenticated = localStorage.getItem('token') ? true : false;
      
        let list = <Spinner animation="border" variant="danger" />
        if (!this.state.loading) {
            list = <Table bordered>
            <thead>
              <tr>
              <th>Course Id</th>
              <th>Course Name</th>
              <th>Start Date</th>
              <th>Duration</th>
              <th>Classroom/Webinar</th>
              {this.props.userTypeId === "3" || this.props.userTypeId === null ?
              <th>Enroll</th>
              : null }
              {this.props.userTypeId === "3" || this.props.userTypeId === null ?
              <th>Wishlist</th>
              : null }
              </tr>
            </thead>
            <tbody>
              {this.props.coursedata ? this.filter(this.props.coursedata).map((data, key) => {
             
                  return  <tr>
                            <td>{data.id}</td>
                            <td>{data.courseName}</td>
                            <td>{convertToDateYYYYMMDD(data.startDate)}</td>
                            <td>{data.duration} days</td>
                            <td>Classroom</td>
                            {this.props.userTypeId === "3" && data.enrolled === true ?
                              <td><p className="green-font">Enrolled</p></td> :
                              this.props.userTypeId === "3" || this.props.userTypeId === null ?
                            <td><Button disabled={this.state.disable === key} onClick={isAuthenticated ? () => this.togglemodalEnroll(data.id, data.courseName, key) : this.checkLoginTrainee}>Enroll</Button></td>
                            : null }
                            {this.props.userTypeId === "3" && (data.enrolled === true || data.wishlist === true) ?
                             <td><Button disabled={true}>Wishlist</Button></td> :
                              this.props.userTypeId === "3" || this.props.userTypeId === null ?
                            <td><Button disabled={this.state.disable === key} onClick={isAuthenticated ? () => this.togglemodalWishlist(data.id, data.courseName, key) : this.checkLoginTrainee}>Wishlist</Button></td>
                            : null }
                            </tr>
                }) : <Spinner color="danger" />
              }
            </tbody>
          </Table>
        }
        return(
          
          <React.Fragment>
            {this.state.addContainer ? <Login /> : 
            <React.Fragment>
            <TitlebarComponent loggedinpatient={isAuthenticated} />
               <Navbar />
               <Container style={{marginBottom: '210px'}}>
               <Row className="mt-5">
                 <Col><h2>Courses Offered</h2>
                 </Col>
               </Row>
               <Row className="mt-5">
                 <Col md="3">
                 <InputGroup size="lg">
                  <InputGroupAddon addonType="prepend" style={{fontSize: '1rem'}}>Search</InputGroupAddon>
                  <Input value={this.state.filter} onChange={this.handleSearchChange} type="text" className="coursesSearch"/>
                </InputGroup>
                 </Col>
               </Row>
                
               {this.props.enrollSuccess ? 
                <Alert className="mt-4" color="success" isOpen={this.state.visible}>{this.props.enrollData}</Alert> : null
               } 
               {this.props.wishlistSuccess ? 
                <Alert className="mt-4" color="success" isOpen={this.state.visible}>{this.props.wishlistData}</Alert> : null
               } 

               <Row className="mt-5 mb-5">
                 <Col className="text-center">
                 {list} 
                 </Col>
               </Row>

              <ModalComponent modal={this.state.modal} togglemodalEnroll={this.togglemodalEnroll} className="trainee-modal">
                <h5>Are you sure you want to enroll for {this.state.courseName} course ?</h5>
                <Button color="success" onClick={this.enrollCourse}>Yes</Button>
                <Button color="danger" onClick={this.togglemodalEnroll} className="ml-3">No</Button>
              </ModalComponent>

              <ModalWishlistComponent confirmModal={this.state.confirmModal} togglemodalWishlist={this.togglemodalWishlist} className="trainee-modal">
                <h5>Are you sure you want to add {this.state.courseName} course to your Wishlist ?</h5>
                <Button color="success" onClick={this.wishlistCourse}>Yes</Button>
                <Button color="danger" onClick={this.togglemodalWishlist} className="ml-3">No</Button>
              </ModalWishlistComponent>
              
             </Container>
             {/* <hr/>
             <SubFooter /> */}
               <Footer />
            </React.Fragment>
              }
          </React.Fragment>
              
              
        )
    }
}

const mapStateToProps = state => {
  return {
      loading: state.loading,
      error: state.error,
      enrollSuccess: state.enrollSuccess,
      enrollData: state.enrollData,
      wishlistSuccess: state.wishlistSuccess,
      wishlistData: state.wishlistData,
      user: state.user,
      coursedata: state.coursedata,
      userTypeId: state.userTypeId,
      // isAuthenticated: state.token !== null,
      // authRedirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCourseEnrolled: (userId,cId) => dispatch(actions.courseEnroll(userId,cId)),
    onCourseWishlist: (userId,cId) => dispatch(actions.courseWishlist(userId,cId)),
    oncourseData: () => dispatch(actions.courseData()),
    oncourseDataOpen: () => dispatch(actions.courseDataOpen()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesOffered);