import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Button } from 'reactstrap';

const UserForgotSuccess = (props) => (
    <Container className="text-center">
        <Row className="pt-3 pb-3">
          <Col><p>{props.message}</p></Col>
        </Row>
        <Row className="pt-3 pb-3">
          <Col><p className="red-font raleway-light font-size">{props.forgotdata}<br />
          <br /></p></Col>
        </Row>
        {/* <Row className="pb-3">
          <Col><p className="red-font raleway-light ">To learn more about how Tata Trusts collects, uses, shares and protects your <br /> personal data please read Tata Trusts' Privacy Policy. </p><br />
          </Col>
        </Row> */}
        {/* <Row>
            <Col>
                <Link to={{pathname: "/resetpassword", 
                state: {
                    username: props.username
                }
  }}><Button className="button-lightred mt-0 mb-5" size="md" style={{width: '160px', fontSize: '18px'}} >Next</Button></Link>
            </Col>
        </Row> */}
    </Container>
);

export default UserForgotSuccess;