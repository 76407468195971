import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Alert } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import * as actions from '../../redux/actions/actions';
import PatientResetSuccess from './resetSuccess';

class PatientResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
        form: this.defaultForm(),
        resetPasswordCheck: false,
        visible: false,
      };
  }

  defaultForm = () => {
    return {
        username: '91' + this.props.location.state.contactNo,
        password: '',
        confirm_password: '',
        verifyKey: '',
        userType: 2,
    }
  }

  handleResetPassword(e){
    let validationMessages = [];

    const updatedForm = {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
      this.setState({form: updatedForm, validationMessages});
  }

  resetPasswordSubmitHandler = (event) => {
    event.preventDefault();
    
    const updatedPatientForm = {
        ...this.state.form,
    }

    if(this.state.form.password === this.state.form.confirm_password){
      this.setState({
        resetPasswordCheck: false
      })
      this.props.onResetPassword(updatedPatientForm);
    }
    else{
      this.setState({
        resetPasswordCheck: true
      })
    }

    this.setState({
      visible: true
    }, () => {
      window.setTimeout(() => {
        this.setState({
          visible: false
        })
      }, 5000)
  });
    
  }

render() {
return (
  <div class="container">
  <div class="row">
      <div class="col-md-6 offset-md-3 text-center">
        <div className="container-box text-center">
          <div class="logo">
            <img src={logo} className="tthomelogo"/>
          </div>
          {/* <h4>Centre for Oncopathology </h4> */}
            {this.props.resetsuccess ?
            <PatientResetSuccess /> :  
            <React.Fragment>
              <h2>Reset Password</h2>
              
                <div className="login-box-inner">
                <form onSubmit={this.resetPasswordSubmitHandler}>
                <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="username" name="username" type="number" pattern={0-9} placeholder="Mobile No." 
                      value={this.state.form.username} onChange={(event) => this.handleResetPassword(event)} />
                      <label for="username" className="patientLabel">Mobile No.</label>
                      <p className="red-font mt-3">{this.state.validationMessages}</p>
                    </span>
                </div>
                <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="password" name="password" type="password" placeholder="Password" 
                      value={this.state.form.password} onChange={(event) => this.handleResetPassword(event)} />
                      <label for="password" className="patientLabel">Password</label>
                    </span>
                </div>
                <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="confirm_password" name="confirm_password" type="password" placeholder="Confirm Password" 
                      value={this.state.form.confirm_password} onChange={(event) => this.handleResetPassword(event)} />
                      <label for="confirm_password" className="patientLabel">Confirm Password</label>
                    </span>
                </div>
                <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="verifyKey" name="verifyKey" type="number" placeholder="OTP" 
                      value={this.state.form.verifyKey} onChange={(event) => this.handleResetPassword(event)}/>
                      <label for="verifyKey" className="patientLabel">OTP</label>
                    </span>
                </div>

                {this.state.resetPasswordCheck === true ? 
                  <Alert className="mt-4" color="danger" isOpen={this.state.visible}>Password don't match.</Alert>
                  : null
                  }

                  <Button className="button-lightred" size="lg" style={{width: '190px'}}>Submit</Button>{' '}
                   {this.props.reseterror ? 
                    <Alert className="mt-4" color="danger" isOpen={this.state.visible}>{this.props.resetmessage}</Alert>
                    : null
                   }

                </form>
              </div>
            </React.Fragment>
            }
            </div>
            
            </div>
        </div>
        {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
      </div>
);
}
}

const mapStateToProps = state => {
    return {
        userTypeId: state.userTypeId,
        resetsuccess: state.resetsuccess,
        resetmessage: state.resetmessage,
        reseterror: state.reseterror,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onResetPassword: (patientForm) => dispatch(actions.patientResetPassword(patientForm))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(PatientResetPassword);