import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row, Button, Spinner } from "reactstrap";
import logo from "../../assets/TataTrustsNewLogo.png";

class PatientPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  loadingState = () => {
    this.setState({
      loading: true
    });
  };

  render() {
    return (
      <div class="container">
        <div class="row">
          <div class="col-md-6 offset-md-3 text-center">
            <div className="container-box text-center">
              <div class="logo">
                <img src={logo} className="tthomelogo" />
              </div>
              {/* <h4>Centre for Oncopathology </h4> */}

              <Row className="pt-3 pb-3">
                <Col>
                  <p className="red-font raleway-light font-size">
                    Click on the proceed button in order to complete payment for
                    order.
                    <br />
                  </p>
                </Col>
              </Row>
              {/* <Row className="pb-3">
          <Col><p className="red-font raleway-light ">To learn more about how Tata Trusts collects, uses, shares and protects your <br /> personal data please read Tata Trusts' Privacy Policy. </p><br />
          </Col>
        </Row> */}
              <Row>
                <Col>
                  <form
                    action="https://oncopath.org/payment/pay"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="requestString"
                      id="requestString"
                      value={this.props.message}
                    />
                    <input
                      type="submit"
                      value="Proceed"
                      style={{ width: "190px", fontSize: "18px" }}
                      className="mb-5"
                      onClick={this.loadingState}
                    />
                  </form>
                </Col>
              </Row>

              {this.state.loading ? (
                <Row>
                  <Col md={12} className="mt-3 text-center">
                    <Spinner
                      animation="border"
                      variant="danger"
                      className="text-center"
                    />
                    <p style={{ color: "red" }}>
                      Processing Your Request. Please do not refresh or close
                      the tab.
                    </p>
                  </Col>
                </Row>
              ) : null}
            </div>
          </div>
        </div>
        {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
      </div>
    );
  }
}
export default PatientPaymentForm;
