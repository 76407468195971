import React, {Component} from 'react';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import SubFooter from '../../components/subFooter/subFooter';
import HomeApi from '../../services/API/HomeApi';
import TitlebarComponent from '../../components/navbar/titlebar';

class ResearchNew extends Component {

    constructor(props){
        super(props);
        this.state = {
        }
    }

    render() {

        let isAuthenticated = localStorage.getItem('token') ? true : false;
        return(

            <React.Fragment>
               <TitlebarComponent loggedinpatient={isAuthenticated} />
               <Navbar />
               <Container>
               <h2 className="text-center" style={{marginTop: '100px', marginBottom: '285px'}}> Coming soon</h2>
               </Container>
               <Footer />
            </React.Fragment>
        )
    }
}

export default ResearchNew;
