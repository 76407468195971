import React, {Component} from 'react';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import SubFooter from '../../components/subFooter/subFooter';
import HomeApi from '../../services/API/HomeApi';
import TitlebarComponent from '../../components/navbar/titlebar';

class TrainingAcademy extends Component {

    constructor(props){
        super(props);
        this.state = {
        }
    }

    render() {

        let isAuthenticated = localStorage.getItem('token') ? true : false;
        return(

            <React.Fragment>
               <TitlebarComponent loggedinpatient={isAuthenticated} />
               <Navbar />
               <Container>
               <h3 className="text-center" style={{marginTop: '100px'}}>Stay tuned. The training academy is launching soon.</h3>
               <p className="text-center mt-5" style={{marginBottom: '205px'}}>Centre for Oncopathology aims to bridge the gap between the 
                   demand and supply of skilled oncopathologists in the country by conducting 
                   oncopathology-specific training programs for MD pathologists.</p>
               </Container>
               <Footer />
            </React.Fragment>
        )
    }
}

export default TrainingAcademy;
