import * as actionTypes from './actions/actionTypes';

const initialState = {
    token: null,
    user: null,
    homeData: null,
    mediaData: null,
    success: null,
    message: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    userTypeId: null,
    data: null,
    searchData: [],
    cpsuccess: null,
    cpmessage: null,
    cperror: null,
    coursedata: null,
    pordersuccess: null,
    pstatusdata: [],
    lpstatusdata: [],
    invalidData: null,
    phistorysuccess: null,
    phistoryerror: null,
    phistoryData: null,
    cphistorysuccess: null,
    cphistoryerror: null,
    cphistoryData: [],
    resetsuccess: null,
    resetmessage: null,
    reseterror: null,
    courseEWdata: null,
    courseEWdataerror: null,
    careerMessage: null,
    posuccess: null,
    porderData: [],
    pordermessage: null,
    ocmessage: null,
    ocsuccess: null,
    apsearchsuccess: null,
    apsearchdata: null,
    apsavesuccess: null,
    apsavemessage: null,
    lcdata: null,
    lcsuccess: null,
    stdata: null,
    stsuccess: null,
    getPAsuccess: null,
    getPAdata: null,
    stestsuccess: null,
    stestdata: null,
    addEditsuccess: null,
    addEditMessage: null,
    editordersuccess: null,
    editordermessage: null,
    patientupdatedAddsuccess: null,
    patientupdatedAddData: null,
    enrollSuccess: null,
    enrollData: null,
    wishlistData: null,
    wishlistSuccess: null,
    patientSignUpError: null,
    forgotsuccess: null,
    forgotdata: null,
    CBsuccess: null,
    CBerror: null,
    CBdata: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_START:
            return {
                ...state,
                error: null,
                success: null,
                loading: true,
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.token,
                user: action.user,
                userTypeId: action.userTypeId,
                error: null,
                loading: false,
            };
        case actionTypes.LOGIN_FAIL:
            return {
                ...state,
                error: action.error,
                success: null,
                loading: false,
            };
        case actionTypes.SIGNUP_SUCCESS:
            return {
                ...state,
                success: action.success,
                message: action.message,
                error: action.error,
                invalidFlag: action.invalidFlag,
                loading: false
            };
        case actionTypes.PATIENT_SIGNUP_SUCCESS:
            return {
                ...state,
                successflag: action.successflag,
                message: action.message,
                patientSignUpError: action.patientSignUpError,
                loading: false
            };
        case actionTypes.SIGNUP_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case actionTypes.AUTH_LOGOUT: 
            return {
                ...state,
                token: null,
                user: null,
                userTypeId: null,
                error: null,
                loading: false,
            };
        case actionTypes.DATA_SUCCESS:
            return {
                ...state,
                success: action.success,
                data: action.data,
                error: null,
                loading: false
            };
        case actionTypes.DATA_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case actionTypes.ENROLL_DATA_SUCCESS:
            return {
                ...state,
                enrollSuccess: action.enrollSuccess,
                enrollData: action.enrollData,
                error: null,
                loading: false
            };
        case actionTypes.ENROLL_DATA_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case actionTypes.WISHLIST_DATA_SUCCESS:
            return {
                ...state,
                wishlistSuccess: action.wishlistSuccess,
                wishlistData: action.wishlistData,
                error: null,
                loading: false
            };
        case actionTypes.ENROLL_DATA_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case actionTypes.CREDIT_PARTNER_ORDER_SUCCESS:
            return {
                ...state,
                cpsuccess: action.cpsuccess,
                cperror: action.cperror,
                cplimiterror: action.cplimiterror,
                cpmessage: action.cpmessage,
                error: null,
                loading: false
            };
        case actionTypes.CREDIT_PARTNER_ORDER_FAILURE:
            return {
                ...state,
                cperror: action.cperror,
                loading: false
            };
        case actionTypes.CREDIT_PARTNER_ORDER_RESET:
            return {
                ...state,
                cpsuccess: null,
                cperror: null,
                cpmessage: null,
                error: null,
                loading: false
            };
        case actionTypes.SEARCH_DATA_SUCCESS:
            return {
                ...state,
                success: action.success,
                searchData: action.searchData,
                error: null,
                loading: false
            };
        case actionTypes.SEARCH_DATA_FAILURE:
            return {
                ...state,
                searchData: [],
                loading: false
            };
        case actionTypes.COURSE_DATA_SUCCESS:
            return {
                ...state,
                coursedata: action.coursedata,
                loading: false,
            };
        case actionTypes.COURSE_DATA_FAILURE:
            return {
                ...state,
                coursedataerror: action.coursedataerror,
                loading: false,
            };
        case actionTypes.COURSE_EW_DATA_SUCCESS:
            return {
                ...state,
                courseEWdata: action.courseEWdata,
                loading: false,
            };
        case actionTypes.COURSE_EW_DATA_FAILURE:
            return {
                ...state,
                courseEWdataerror: action.courseEWdataerror,
                loading: false,
            };
        case actionTypes.PATIENT_ORDER_SUCCESS:
            return {
                ...state,
                pordersuccess: action.pordersuccess,
                pordermessage: action.pordermessage,
                loading: false,
            };
        case actionTypes.PATIENT_ORDER_FAILURE:
            return {
                ...state,
                pordererror: action.pordererror,
                loading: false,
            };
        case actionTypes.PATIENT_ORDER_RESET:
            return {
                ...state,
                pordersuccess: null,
                pinsuccess: null,
                pindata: null,
                loading: false,
            };
        case actionTypes.PATIENT_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                psuccess: action.psuccess,
                pstatusdata: action.pstatusdata ? state.pstatusdata.concat(action.pstatusdata) : state.pstatusdata,
                lpstatusdata: action.lpstatusdata ? state.lpstatusdata.concat({id: action.lpstatusdata[0].billId, list: action.lpstatusdata}) : state.lpstatusdata,
                phistoryData: state.phistoryData.length !== 0 && action.pstatusdata ? state.phistoryData.map(el => {
                    if(el.awbId === action.pstatusdata.id) {
                        el["logisticsStatus"] = {"id": el.awbId, "status": action.pstatusdata}
                        //el["logisticsStatus"] = {"id": action.pstatusdata.id, "status": action.pstatusdata.status}
                    }
                    return el
                }) : state.phistoryData,
                cphistoryData: state.cphistoryData.length !== 0 && action.lpstatusdata ? [[].concat.apply([], state.cphistoryData).map(el => {
                    if(el.billId === action.lpstatusdata[0].billId) {
                        el["livehealthStatus"] = action.lpstatusdata
                    }
                    return el
                })] : state.cphistoryData,
                loading: false
            };
        case actionTypes.CP_PATIENT_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                psuccess: action.psuccess,
                pstatusdata: action.pstatusdata ? state.pstatusdata.concat(action.pstatusdata) : state.pstatusdata,
                lpstatusdata: action.lpstatusdata ? state.lpstatusdata.concat({id: action.lpstatusdata[0].billId, list: action.lpstatusdata}) : state.lpstatusdata,
                phistoryData: state.phistoryData.length !== 0 && action.pstatusdata ? state.phistoryData.map(el => {
                    if(el.awbNo === action.pstatusdata.id) {
                        el["logisticsStatus"] = {"id": el.awbNo, "status": action.pstatusdata}
                        //el["logisticsStatus"] = {"id": action.pstatusdata.id, "status": action.pstatusdata.status}
                    }
                    return el
                }) : state.phistoryData,
                cphistoryData: state.cphistoryData.length !== 0 && action.lpstatusdata ? [[].concat.apply([], state.cphistoryData).map(el => {
                    if(el.creditPartnerDetails.lhBillId === action.lpstatusdata[0].billId) {
                        el["livehealthStatus"] = action.lpstatusdata
                    }
                    return el
                })] : state.cphistoryData,
                loading: false
            };
        case actionTypes.CREDIT_PARTNER_PATIENT_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                posuccess: action.posuccess,
                porderData: state.porderData.concat(action.porderData),
                cphistoryData: [[].concat.apply([], state.cphistoryData).map(el => {
                    if(el.id === action.porderData[0].creditPartnerPatientId) {
                        el["creditPartnerDetails"] = action.porderData[0]
                    }
                    return el
                })],
                loading: false
            };
        case actionTypes.PATIENT_ORDER_STATUS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case actionTypes.PIN_SUCCESS:
            return {
                ...state,
                pinsuccess: action.pinsuccess,
                pindata: action.pindata,
                pinfailure: action.pinfailure,
                loading: false,
            };
        case actionTypes.PIN_FAILURE:
            return {
                ...state,
                pinsuccess: false,
                loading: false,
            };
        case actionTypes.SET_USER_TYPE:
            return {
                ...state,
                userTypeId: action.userTypeId,
                loading: false
            };
        case actionTypes.PATIENT_INVALID_DATA:
            return {
                ...state,
                invalidData: action.invalidData,
                loading: false
            }
        case actionTypes.PATIENT_HISTORY_DATA_SUCCESS:
            return {
                ...state,
                phistorysuccess: action.phistorysuccess,
                phistoryerror: action.phistoryerror,
                phistoryData: action.phistoryData,
                loading: false
            }
        case actionTypes.PATIENT_HISTORY_DATA_FAILURE:
            return {
                ...state,
                phistoryerror: action.phistoryerror,
                loading: false
            }
        case actionTypes.CREDIT_PARTNER_ORDER_PATIENT_HISTORY_DATA_SUCCESS:
            return {
                ...state,
                cphistorysuccess: action.cphistorysuccess,
                cphistoryerror: action.cphistoryerror,
                cphistoryData: [...state.cphistoryData, action.cphistoryData.creditPartnerPatients.map(el => {
                    el["orderId"] = action.cphistoryData.orderId
                    return el
                })],
                phistoryData: state.phistoryData.map((el) => {
                    if(el.id === action.cphistoryData.orderId) {
                        el["creditPartnerPatients"] = action.cphistoryData.creditPartnerPatients
                        
                    }
                    return el
                }),
                loading: false
            }
        case actionTypes.CREDIT_PARTNER_ORDER_PATIENT_HISTORY_DATA_FAILURE:
            return {
                ...state,
                cphistoryerror: action.cphistoryerror,
                loading: false
            }
        case actionTypes.RESET_SUCCESS:
            return {
                ...state,
                resetsuccess: action.resetsuccess,
                resetmessage: action.resetmessage,
                reseterror: action.reseterror,
                loading: false
            }
        case actionTypes.RESET_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.CAREER_SUCCESS:
            return {
                ...state,
                careerMessage: action.careerMessage,
                loading: false
            }
        case actionTypes.CAREER_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.OC_SUCCESS:
            return {
                ...state,
                ocsuccess: action.ocsuccess,
                ocmessage: action.ocmessage,
                loading: false
            }
        case actionTypes.OC_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.APSEARCH_SUCCESS:
            return {
                ...state,
                apsearchsuccess: action.apsearchsuccess,
                apsearchdata: action.apsearchdata,
                loading: false
            }
        case actionTypes.APSEARCH_FAILURE:
            return {
                ...state,
                error: action.error,
                apsearchdata: [],
                loading: false
            }
        case actionTypes.APSAVE_SUCCESS:
            return {
                ...state,
                apsavesuccess: action.apsavesuccess,
                apsavemessage: action.apsavemessage,
                loading: false
            }
        case actionTypes.APSAVE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.LOGISTIC_COST_SUCCESS:
            return {
                ...state,
                lcsuccess: action.lcsuccess,
                lcdata: action.lcdata,
                loading: false
            }
        case actionTypes.LOGISTIC_COST_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.SAMPLE_TYPE_SUCCESS:
            return {
                ...state,
                stsuccess: action.stsuccess,
                stdata: action.stdata,
                loading: false
            }
        case actionTypes.SAMPLE_TYPE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.SAMPLE_TEST_SUCCESS:
            return {
                ...state,
                stestsuccess: action.stestsuccess,
                stestdata: action.stestdata,
                loading: false
            }
        case actionTypes.SAMPLE_TEST_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_PA_SUCCESS:
            return {
                ...state,
                getPAsuccess: action.getPAsuccess,
                getPAdata: action.getPAdata,
                loading: false
            }
        case actionTypes.GET_PA_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.EDIT_ORDER_SUCCESS:
            return {
                ...state,
                editordersuccess: action.editordersuccess,
                editordermessage: action.editordermessage,
                loading: false
            }
        case actionTypes.EDIT_ORDER_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.EDIT_ADD_SUCCESS:
            return {
                ...state,
                addEditsuccess: action.addEditsuccess,
                addEditMessage: action.addEditMessage,
                loading: false
            }
        case actionTypes.EDIT_ADD_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.PATIENT_UPDATED_ADD_SUCCESS:
            return {
                ...state,
                patientupdatedAddsuccess: action.patientupdatedAddsuccess,
                patientupdatedAddData: action.patientupdatedAddData,
                loading: false
            }
        case actionTypes.EDIT_ADD_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.USER_FORGOT_SUCCESS:
            return {
                ...state,
                forgotsuccess: action.forgotsuccess,
                forgotdata: action.forgotdata,
                loading: false
            }
        case actionTypes.USER_FORGOT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.CREDIT_BALANCE_SUCCESS:
            return {
                ...state,
                CBsuccess: action.CBsuccess,
                CBerror: action.CBerror,
                CBdata: action.CBdata,
                loading: false
            }
        case actionTypes.CREDIT_BALANCE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state;
    }
}

export default reducer;