import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../login/Login.css';
import { Button, Alert } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import LoginRegisterApi from '../../services/API/LoginRegisterApi';
import queryString from 'query-string';
import * as actions from '../../redux/actions/actions';
import { Spinner, Row, Col } from 'react-bootstrap';

class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationMessages: [],
      form: this.defaultForm(),
      loading: false,
      visible : false
    };
  }


defaultForm = () => {
  return {
      email: '',
      password: '',
  }
}

inputChangedHandler(e) {

  const updatedForm = {
    ...this.state.form,
    [e.target.name]: e.target.value
  }
  let validationMessages = [];
  this.setState({
    form: updatedForm, 
    validationMessages
  });
    
}

  submitHandler = (event) => {
    //console.log("login clicked!");
    event.preventDefault();

    let params = queryString.parse(this.props.location.search);

    this.props.onLogin(this.state.form.email, this.state.form.password, params.token);

    this.setState({
      visible: true
    }, () => {
      window.setTimeout(() => {
        this.setState({
          visible: false
        })
      }, 5000)
    });
  }

render() {

  if(this.props.user) {
    let userId = Number(this.props.userTypeId);
    if(userId === 4) {
      this.props.history.push('/adminHome');
    }
  }

return (
  <React.Fragment>
    <div class="container">
      <div class="row">
          <div class="col-md-6 offset-md-3 text-center">
            <div className="container-box text-center">
              <div class="logo">
                <img src={logo} className="tthomelogo"/>
              </div>
              <h2>Sign In</h2>
              <div className="login-box-inner">
                <form onSubmit={this.submitHandler}>
                  <div class="form-group">
                    <span class="has-float-label">
                      <input class="form-control" id="username" name="email" type="text" placeholder="Username" value={this.state.form.email} onChange={(event) => this.inputChangedHandler(event)}/>
                      <label for="username" className="patientLabel">Username</label>
                    </span>
                  </div>
                  <div class="form-group">
                    <span class="has-float-label">
                      <input class="form-control" id="password" name="password" type="password" placeholder="Password" value={this.state.form.password} onChange={(event) => this.inputChangedHandler(event)}/>
                      <label for="password" className="patientLabel">Password</label>
                    </span>
                  </div>
                  <Button className="button-lightred" size="lg" style={{width: '190px'}} >Submit</Button>{' '}
                  {this.props.loading ? 
                    <Row>
                    <Col md={12} className="mt-3 text-center">
                        <Spinner animation="border" variant="danger" className="text-center" />
                    </Col>
                    </Row>
                  : null}

                  {this.props.invalidData === "403" ?
                    <Alert className="mt-3" color="danger" isOpen={this.state.visible}>Invalid Credentials</Alert>
                    : null }
                  {this.props.invalidData === "601" ?
                    <Alert className="mt-3" color="danger" isOpen={this.state.visible}>User not registered. Please register.</Alert>
                    : null }
                  <h5>{this.props.success}</h5>
                </form>
              </div>
            </div>
            
          </div>
      </div>
      {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
    </div>
    </React.Fragment>
);
}
}

const mapStateToProps = state => {
  return {
      loading: state.loading,
      error: state.error,
      success: state.success,
      userTypeId: state.userTypeId,
      isAuthenticated: state.token !== null,
      token: state.token,
      user: state.user,
      invalidData: state.invalidData
      // isAuthenticated: state.token !== null,
      // authRedirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLogin: (username, password, token) => dispatch(actions.login(username, password, token)),
      onPatientLogin: (username, password) => dispatch(actions.patientLogin(username, password)),
      // onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLogin));