import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';
import TitlebarComponent from '../../components/navbar/titlebar';
import { Card, CardBody, Container, Row, Col, Spinner, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactToPrint from "react-to-print";
import PatientInvoice from './patientInvoice';

class PrintButton extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let isAuthenticated = localStorage.getItem('token') ? true : false;

        let orderReceiptData = this.props.location.state.order;

        return (
            <React.Fragment>
            <TitlebarComponent loggedinpatient={isAuthenticated} />
            <Container className="mt-3 mb-5">
            <Card className="collapse-box">
                <CardBody>
                    <Row>
                        <Col md={11}></Col>
                        <Col md={1}>
                            <Link to="/patientHome"><div>X</div></Link>
                        </Col>
                    </Row>
                    <PatientInvoice orderReceiptData={orderReceiptData} ref={el => (this.componentRef = el)} />
                    <Row>
                    <Col md={12} className="mt-3 text-center">
                        <ReactToPrint 
                          trigger = {() => 
                            <Button className="button-lightred" size="md" style={{width: '190px', cursor: 'pointer'}} >Print</Button>
                          }
                          content = {() => this.componentRef}
                        />
                    </Col>
                    </Row> 

                </CardBody>
            </Card>
            </Container>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        user: state.user,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      onPatientOrderList: (id) => dispatch(actions.patientOrderList(id)),
      onPayNowOrder: (orderId) => dispatch(actions.PayNowOrder(orderId)),
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(PrintButton);