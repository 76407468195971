import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText, Alert } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import './Registration.css';
import ModalComponent from '../../components/modal/modal';
import DatePicker from "react-date-picker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { convertDOB } from '../functions';
import * as actions from '../../redux/actions/actions';
import RegisterSuccess from './RegisterSuccess';
import RegisterUser from './RegisterUser';

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: this.defaultForm(),
      visible: false,
      modal: false,
      validationMessages: null
    };
  }


defaultForm = () => {
  return {
      userTypeId: this.props.userTypeId ? Number(this.props.userTypeId) : 3,
      firstName: '',
      lastName: '',
      countryCode: '91',
      contactNo: '',
      gender:'',
      emailId: '',
      address: '',
      dob: '',
      state: '',
      city: '',
      pin:'',
      password: '',
      age: '22',
  }
}

inputChangedHandler(e) {
  let validationMessages = [];

    if (e.target.type === "number") {
      e.target.value = e.target.value.slice(0,10);
    } 
    
    if(e.target.type === "number" && e.target.value.length < 10){
      validationMessages.push("Required 10 digits number, match requested format.");
      this.setState({validationMessages});
    }
    else {
      validationMessages = [];
      this.setState({validationMessages});
    }

  const updatedForm = {
    ...this.state.form,
    [e.target.name]: e.target.value
  }
  this.setState({form: updatedForm, validationMessages});
    
}

handleChange(date) {
  //let convertedDate = convertToDateYYYYMMDD(date);
  const updatedFormDate = {
    ...this.state.form,
    dob: date
  }
  let validationMessages = [];
  this.setState({
    form: updatedFormDate, validationMessages
  });
}


  submitHandler = (event) => {
    //console.log("login clicked!");
    event.preventDefault();

    const updatedFormDateandAddress = {
      designation:"Mr",
      firstName: this.state.form.firstName,
      lastName: this.state.form.lastName,
      emailId: this.state.form.emailId,
      contactNo: this.state.form.contactNo,
      countryCode: this.state.form.countryCode,
      userTypeId: this.props.userTypeId ? Number(this.props.userTypeId) : 3,
      gender:this.state.form.gender,
      age: this.state.form.age,
      password:this.state.form.password,
      dob: convertDOB(this.state.form.dob),
      address: {
        line1: this.state.form.address,
        line2:"Marol",
        city:this.state.form.city,
        state:this.state.form.state,
        pincode:this.state.form.pin
      }
    }
    this.props.onSignUp(updatedFormDateandAddress);

    this.setState({
      visible: true
    }, () => {
      window.setTimeout(() => {
        this.setState({
          visible: false
        })
      }, 5000)
    });

    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  checkRegisterFormDetails = () => {

    let flag = null;
    if(flag || flag === null){
            if(this.state.form.firstName !== "" && this.state.form.lastName !== "" && this.state.form.emailId !== ""
            && this.state.form.contactNo !== "" && this.state.form.gender !== "" && this.state.form.dob !== "" && this.state.form.password !== "" 
            && this.state.form.countryCode !== "" && this.state.form.address !== "" && this.state.form.state !== "" && this.state.form.city !== ""
            && this.state.form.pin !== "") {
                flag = true;
            }
            else {
                flag = false;
            }
    }
    //console.log(flag);
    return flag;
}

render() {

return (
  
  <div class="container">
  <div class="row">
  <div class="col-12 text-center">
    <div className="register-box">
      <div class="logo">
        <img src={logo} className="tthomelogo"/>
      </div>
      {/* <h4 className="red-font raleway-light">Centre for Oncopathology </h4> */}
      
      <div className="register-container-box login-box-inner">
      {this.props.success ? 
        <RegisterSuccess message = {this.props.message} /> 
      :
      <React.Fragment>
        <div class="row">
            <div class="col-11 register-heading">
                <span>Register</span>
                <span>Already have an account ?</span><Link to="/login"><span>Sign In</span></Link>
            </div>
        {/* <div class="col-1">
            <Link to="/register" ><div>X</div></Link>
        </div> */}
        </div>
        <div class="row">
      <div class="col-12 text-center mb-5">
          <span style={{fontSize: '18px'}}>Enter Your Details</span>
      </div>
      {/* <p className="red-font ml-4 mb-4">*Fields are mandatory</p> */}
  </div>     
    <Form>
      <Row form>
        <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
          <FormGroup>
            <Label for="fname"><span className="starSize">*</span>First Name</Label>
            <Input type="text" maxLength={25} value={this.state.form.firstName} name="firstName" placeholder="First Name" onChange={(event) => this.inputChangedHandler(event)} required/>
          </FormGroup>
        </Col>
        <Col md={3} className="ml-md-3 ml-lg-5 mr-md-3 mr-lg-5">
          <Label for="lname"><span className="starSize">*</span>Last Name</Label>
          <FormGroup>
            <Input type="text" maxLength={25} value={this.state.form.lastName} name="lastName" placeholder="Second Name" onChange={(event) => this.inputChangedHandler(event)} required/>
          </FormGroup>
        </Col>
        <Col md={3} className="ml-md-3 ml-lg-5 ">
          <FormGroup>
            <Label for="mobile"><span className="starSize">*</span>Mobile Number</Label>
            <Row>
            <Col xs={2} sm={2} md={2} lg={2} className="text-center" style={{paddingRight: '0px'}}>
            <Input type="text" value={this.state.form.countryCode} name="countryCode" onChange={(event) => this.inputChangedHandler(event)} required style={{padding: '0px'}}/>
            </Col>
            <Col xs={10} sm={10} md={10} lg={10} className="contactInitialTextNumber">
            <Input type="number" value={this.state.form.contactNo} name="contactNo" placeholder="Mobile Number" onChange={(event) => this.inputChangedHandler(event)} required/>
            </Col>
            </Row>
            <p className="red-font mt-3">{this.state.validationMessages}</p>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
      <Col sm={3} className="ml-md-3 ml-lg-3 mr-md-3 mr-lg-5">
      <FormGroup tag="fieldset">
        <legend className="col-form-label"><span className="starSize">*</span>Gender :</legend>
        <Col>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="gender" value="M" checked={this.state.form.gender === "M"} onChange={(event) => this.inputChangedHandler(event)}/>{' '}
              Male
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="gender" value="F" checked={this.state.form.gender === "F"} onChange={(event) => this.inputChangedHandler(event)}/>{' '}
             Female
            </Label>
          </FormGroup>
          <FormGroup check >
            <Label check>
              <Input type="radio" name="gender" value="Other" checked={this.state.form.gender === "Other"} onChange={(event) => this.inputChangedHandler(event)}/>{' '}
              Other
            </Label>
          </FormGroup>
        </Col>
      </FormGroup>
      </Col>
      <Col md={3} className="ml-md-3 ml-lg-5 mr-md-3 mr-lg-5">
          <FormGroup>
            <Label for="email"><span className="starSize">*</span>Email</Label>
            <Input type="email" maxLength={45} value={this.state.form.emailId} name="emailId" placeholder="Email Address" onChange={(event) => this.inputChangedHandler(event)} required/>
          </FormGroup>
      </Col>
      <Col md={3} className="ml-md-3 ml-lg-5 mr-md-3 mr-lg-5">
          <FormGroup>
            <Label for="address"><span className="starSize">*</span>Street Address</Label>
            <Input type="text" maxLength={45} value={this.state.form.address} name="address" placeholder="Street Address" onChange={(event) => this.inputChangedHandler(event)}/>
          </FormGroup>
      </Col>
      </Row>
      <Row form>
      <Col md={3} className="ml-md-3 ml-lg-3 mr-md-3 mr-lg-5">
      <Label for="state"><span className="starSize">*</span>Date of Birth :</Label>
      <FormGroup className="mt-1">
        <DatePicker
          value={this.state.form.dob}
          onChange={(date) => this.handleChange(date)}
          maxDate={new Date()}
          // className="datepicker"
          // dateFormat="yyyy-MM-dd"
        />
        
          {/* <Input
            type="date"
            name="dob"
            id="exampleDate"
            placeholder="date placeholder"
            value={this.state.form.dob}
            onChange={(event) => this.inputChangedHandler(event)}
          /> */}
      </FormGroup>
      </Col>
        <Col md={3} className="ml-md-3 ml-lg-5">
        <FormGroup>
            <Label for="state"><span className="starSize">*</span>Password</Label>
            <Input type="password" maxLength={15} value={this.state.form.password} name="password" placeholder="Password" onChange={(event) => this.inputChangedHandler(event)} required/>
          </FormGroup>
      </Col>
      </Row>
      <Row form>
        <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
          <FormGroup>
            <Label for="state"><span className="starSize">*</span>State</Label>
            <Input type="text" maxLength={45} value={this.state.form.state} name="state" placeholder="State" onChange={(event) => this.inputChangedHandler(event)}/>
          </FormGroup>
        </Col>
        <Col md={3} className="ml-md-3 ml-lg-5 mr-md-3 mr-lg-5">
          <Label for="city"><span className="starSize">*</span>City</Label>
          <FormGroup>
            <Input type="text" maxLength={45} value={this.state.form.city} name="city" placeholder="City" onChange={(event) => this.inputChangedHandler(event)}/>
          </FormGroup>
        </Col>
        <Col md={3} className="ml-md-3 ml-lg-5 ">
          <FormGroup>
            <Label for="pin"><span className="starSize">*</span>Pincode</Label>
            <Input type="text" maxLength={6} value={this.state.form.pin} name="pin" placeholder="Pincode" onChange={(event) => this.inputChangedHandler(event)}/>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button disabled={this.checkRegisterFormDetails(this.state.form) ? false : true} className="button-lightred mt-0" size="md" style={{width: '120px'}} onClick={this.toggleModal}>Submit</Button>
        </Col>
      </Row>

      {this.checkRegisterFormDetails(this.state.form) ?
      null :
      <h5 style={{color: 'red'}} className="mt-3">Fields marked with  <span className="starSize"> * </span>  are required.</h5>
      }

      {this.props.error ?
      <Alert className="mt-4" color="danger" isOpen={this.state.visible}>Email Id / Mobile No. already exists.</Alert>
      : null
      }
    </Form> 

    <ModalComponent modal={this.state.modal} toggleModal={this.toggleModal}>
      <h5>Are you sure you what to submit the form ?</h5>
      <Button color="success" onClick={this.submitHandler}>Yes</Button>
      <Button color="danger" onClick={this.toggleModal} className="ml-3">No</Button>
    </ModalComponent>

    </React.Fragment>
      }
        {/* <RegisterForm /> */}
        {/* <RegisterSuccess /> */}
      </div>
      

    </div>
    
  </div>
</div>
  {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
</div>
  
 
);
}
}

const mapStateToProps = state => {
  return {
      userTypeId: state.userTypeId,
      success: state.success,
      message: state.message,
      error: state.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onSignUp: (formData) => dispatch(actions.signup(formData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);