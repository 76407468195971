import React from 'react';
import { Collapse, Table, Row, Col} from 'reactstrap';
import { convertToDateYYYYMMDD, convertToTimeHHMMSS, convertToDateDDMMYYYY } from '../../functions';

class TrackSampleDataCol extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {collapse : false};
    }

    toggleCard = () => {
        this.setState({ collapse: !this.state.collapse });     
    }

    render() {
        var d = new Date();
      return (
        <React.Fragment>
            <Row className="mt-3">
               <Col md={1} xs={4} className="text-center">{this.props.order.srno}</Col>
               <Col md={2} xs={4} className="text-center">{this.props.order.id}</Col>
               <Col md={2} xs={4} className="text-center">{convertToDateDDMMYYYY(this.props.order.createdOn)}</Col>
               {this.props.order.parceledByCustomer === 1 ? 
               <Col md={2} xs={4} className="text-center">Not Applicable</Col> 
               : 
               this.props.order.awbId !== "" && this.props.order.logisticsStatus ? 
               this.props.order.logisticsStatus.status.status === "Incorrect Waybill number or No Information" && this.props.order.pickUpTime ? 
               <Col>Sample pickup scheduled on {convertToDateDDMMYYYY(this.props.order.pickUpTime)} between 4:00 PM to 7:00 PM</Col> :
               <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus.status.status}</Col> 
               : null
            //    this.props.order.awbId !== "" ?
            //    this.props.order.logisticsStatus ?
            //    this.props.order.logisticsStatus.status.status === "Incorrect Waybill number or No Information" ?
            //    this.props.order.pickUpTime ? <Col>Sample pickup scheduled on {convertToDateDDMMYYYY(this.props.order.pickUpTime)} at {convertToTimeHHMMSS(this.props.order.pickUpTime)}</Col>: 
            //    <Col>-</Col> : 
            //     <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus.status.status}</Col> : 
            //     <Col>-</Col>  
            //    :
            //    this.props.order.parceledByCustomer === 1 || this.props.order.parceledByCustomer === 0?
            //    <Col md={2} xs={4} className="text-center">{this.props.order.logisticStatus.status}</Col> :
            //    <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus}</Col>
               }
               {/* {this.props.pstatusdata === "Incorrect Waybill number or No Information" && this.props.order.pickUpTime ? 
               <Col md={2} xs={4} className="text-center">
                    <div>{convertToDateYYYYMMDD(this.props.order.pickUpTime)}</div>
                    <div>{convertToTimeHHMMSS(this.props.order.pickUpTime)}</div>
               </Col>
               :
               <Col md={2} xs={4} className="text-center">{this.props.order.logisticStatus.status}</Col> 
               } */}
               {/* {this.props.order.awbId ? 
               <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus.status}</Col> : 
               <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus.status}</Col>
               } */}
               {/* <Col md={2} xs={4} className="text-center">{this.props.order.logisticStatus.status}</Col> */}
               {/* <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus}</Col> */}
               {/* {this.props.order.isLogisticServiceable === 1 ? 
               <Col md={2} xs={4} className="text-center">{this.props.order.logisticsStatus}</Col> :
               <Col md={2} xs={4} className="text-center">NA</Col>
               } */}
               <Col md={5} xs={12} className="text-center">
                {this.props.order.sampleStatus.desc === "Sample Not Received" ? 
                <p>{this.props.order.sampleStatus.desc}</p> :
               this.props.order.logisticsStatus === "SHIPMENT DELIVERED" || (this.props.order.parceledByCustomer === 1 && this.props.order.logisticStatus.status) || this.props.order.parceledByCustomer === 0?  
               <div onClick={this.toggleCard}>
               <Collapse toggler="togglerinner" isOpen={this.state.collapse}>
                    <Table bordered responsive>
                        <thead>
                            <th>Test Name</th>
                            <th>Status</th>
                        </thead>
                        {this.props.order.livehealthStatus ? this.props.order.livehealthStatus.map((t) =>
                        <tbody>
                            <td style={{wordWrap: 'break-word'}}>{t["Test Name"]}</td>
                            <td>{t.status}</td>
                        </tbody> 
                    ) : null}
                    </Table>
                </Collapse>
                {this.state.collapse ? 
                    <i class="fa fa-angle-double-up fa-2x"></i> : 
                    <i class="fa fa-angle-double-down fa-2x"></i>
                }
               </div> 
               : "-" }
               </Col>     
            </Row>
            <hr/>
        </React.Fragment>  
        );
    }

}
  

export default TrackSampleDataCol;



