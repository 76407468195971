import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';
import TitlebarComponent from '../../components/navbar/titlebar';
import { Card, CardBody, Container, Row, Col, Spinner, Button, FormGroup, Label, Input, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Picky from "react-picky";
import PatientPaymentForm from './patientPayForm';
import queryString from 'query-string';
import PatientPaymentSuccess from './patientPaymentSuccess';
import PatientPaymentFailure from './patientPaymentFailure';
import uniq from 'lodash/uniq';
import PatientEditOrderSample from './patientEditOrderSample';

class PatientEditOrder extends Component {

constructor(props) {
    super(props);
    this.state = {
        testDetails: [
        ],
        testtableheader: [],
        testtabledata: [],
        modal: false,
        address:{
            line1:'',
            line2:'',
            city:'',
            state:'',
            pincode:''
        },
        loading: false,
        visible: false,
        yesToggle: false,
        noLGCharge: false,
        islogisticserviceable: null,
        // bigList: [{id: 1, name: 'Test Name 1'},{id: 2, name: 'Test Name 2'}, {id: 3, name: 'Test Name 3'} ]
    }
} 

componentDidMount () {
    this.props.onSampleType();
}

sampleInputChangedHandler(e, key) {

    //console.log(e.target.value);
    let id = e.target.value;
    
    if(e.target.value !== "Select Sample"){
    this.props.onSampleTest(id);
    }

    const updatedForm = [
        ...this.state.testDetails.slice(0,key),
        Object.assign({}, this.state.testDetails[key], {[e.target.name]: e.target.value}),
       ...this.state.testDetails.slice(key+1)
      ]
    let validationMessages = [];
    this.setState({
      testDetails: updatedForm, 
      validationMessages
    });
      
}

addSample = () => {
    this.setState(previousState => ({
        testDetails: [...previousState.testDetails, {
            value: null,
            selectedSample: '',
            selectedTests: []
        }],
    }));
}

removeSample = (item, key) => {

    const updatedTestDetailsafterdelete = this.state.testDetails;

    updatedTestDetailsafterdelete.splice(key, 1);

    this.setState({
        testDetails: updatedTestDetailsafterdelete
    });
}

selectMultipleOption = (value, key) => {
    console.count('onChange')
    //console.log("Val", value);
    const updatedTestDetails = [
        ...this.state.testDetails.slice(0,key),
        Object.assign({}, this.state.testDetails[key], {selectedTests: value}),
       ...this.state.testDetails.slice(key+1)
      ]
    
    this.setState({ testDetails: updatedTestDetails });
}

submitEditOrder = (event) => {
    event.preventDefault();

    const patientTestListIdArr = this.state.testDetails.map((el) => {
        return el.selectedTests.map(function(subtest) {
            return subtest.lhTestId;
          })
    })

    var patientTestListId = [].concat.apply([],patientTestListIdArr).filter((elem, index, self) => {
        return index === self.indexOf(elem);
    })

    const patientTestDetails = {
        testList: [...patientTestListId],
        userId: this.props.user.id,
        orderId: this.props.location.state.order.id,
    }

    //console.log(patientTestDetails);

    this.setState({loading: true});

    this.props.onSubmitEditOrder(patientTestDetails);
}

checkTestDetails = (testDetails) => {
    let flag = null;
    
        testDetails.forEach(function (test) {
            if(flag || flag === null) {
                if(test.selectedSample !== "" && test.selectedTests.length !== 0) {
                    flag = true;
                }
                else {
                    flag = false;
                }
            }   
            });
            
    //console.log(flag);
    return flag;

}

uniqueNames = (data) => {
   data.filter(function(i, index){
       return data.indexOf(i) >= index
   })
}

filterSample = (data) => {
data.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if(!x) {
        return acc.concat([current]);
    }
    else {
        return acc;
    }
}, []);
}

reduceSampleArr = (arr, comp) => {
    const unique = arr
    .map(e => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => arr[e]).map(e => arr[e]);

    return unique;
}

render() {

// let sampleListFilter = [];

// const filteredList = arr.reduce((acc, current) => {
//     const x = acc.find(item => item.id === current.id);
//     if(!x) {
//         return acc.concat([current]);
//     }
//     else {
//         return acc;
//     }
// }, []);


let testListArr = [];
let optionArr = [];
let result = [];


this.state.testDetails.map((el) => {
testListArr.push(el.selectedTests);
})

let testTotalCharge = [].concat.apply([],testListArr).reduce((acc, test) => {
return acc + Number(test.price)
}, 0)

//console.log(this.props.location.state.order);
let isAuthenticated = localStorage.getItem('token') ? true : false;
let editOrderData = this.props.location.state.order;
let params = queryString.parse(this.props.location.search);



return (

<React.Fragment>
    {params.paymentStatus === "SUCCESS" ? 
    <PatientPaymentSuccess data={params} userId={this.props.user.id}/> :
    params.paymentStatus === "FAILURE" ? 
    <PatientPaymentFailure data={params} /> :

    this.props.editordersuccess ? 
    <PatientPaymentForm message={this.props.editordermessage}/> :

<React.Fragment>
<TitlebarComponent loggedinpatient={isAuthenticated} />
<Container className="mt-5 mb-5">
<Card className="collapse-box">
<CardBody>
    <Row>
        <Col md={11}></Col>
        <Col md={1}>
            <Link to="/patientHome"><div>X</div></Link>
        </Col>
    </Row>
    
    <Row className='mt-5'>
        <Col md={3} xs={2} className="text-center"><b>Sample Name</b></Col>
        <Col md={6} xs={7} className="text-center"><b>Test Name</b></Col>
        <Col md={3} xs={3} className="text-center"><b>Amount</b></Col>
    </Row><hr/>
    
    {editOrderData.id ? editOrderData.userPatientOrderDetails.map((data) => {
        return <React.Fragment>
        <Row className='mt-4'>
        <Col md={3} xs={2} className="text-center">{data.sampleTest.sample.sampleName}</Col>
        <Col md={6} xs={7} className="text-center">{data.sampleTest.testName}</Col>
        <Col md={3} xs={3} className="text-center">&#8377; {(data.sampleTest.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</Col>
        </Row><hr/>
        </React.Fragment>
    }) : <Spinner color="danger" />
    }
    
    {this.state.testDetails ? this.state.testDetails.map((item, key) => 
    <React.Fragment>
    <Row>
        <Col md={1}>
            <i class="fa fa-trash mr-1 pr-3 pt-5 pb-5 pull-right" style={{fontSize: '20px'}} onClick={() => this.removeSample(item, key)}></i>
        </Col>
        {this.props.stdata ? 
        <Col md={3} className="mt-3">
            <FormGroup>
                <Label for="exampleSelect"><b>Select Department:</b></Label>
                    <Input type="select" name="selectedSample" id="sampleSelect" onChange={(event) => this.sampleInputChangedHandler(event, key)}>
                    <option>Select Sample</option>

                    <PatientEditOrderSample editOrderData={editOrderData} stdata={this.props.stdata} />

                </Input>
                </FormGroup> 
            </Col>
            : null }
            <Col md={3} className="mt-3">
                {this.props.stestdata ?
                <React.Fragment>
                <Label for="exampleSelect" id="selectTestLabelPatientEdit"><b>Select Tests:</b></Label>
                <Picky
                    value={item.selectedTests}
                    options={this.props.stestdata}
                    onChange={(undefined) => {this.selectMultipleOption(undefined, key); document.getElementById("selectTestLabelPatientEdit").click()}}
                    open={false}
                    valueKey= "id"
                    labelKey="testName"
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    numberDisplayed={1}
                    manySelectedPlaceholder="%s selected"
                    allSelectedPlaceholder="%s selected"
                    dropdownHeight={600}
                />
                </React.Fragment>
                : null }
            </Col>
            <Col md={5}>
                {item.selectedTests.length !== 0 ? <Table bordered>
                    <thead>
                        <tr>
                        <th>Sr No.</th>
                        <th>Test Name</th>
                        <th>Test Charges</th>
                        </tr>
                    </thead>
                    <tbody>
                        {item.selectedTests.map((test, key) => 
                        <tr>
                        <td>{key + 1}</td>
                        <td>{test.testName}</td>
                        <td>{test.price}</td>
                        </tr>)
                    }
                        <tr>
                        {/* <td colSpan={2}><b>Total</b></td> */}
                        {/* <td><b>{testTotalCharge}</b></td> */}
                        
                        </tr>
                    </tbody>
                </Table> : null 
                }
            </Col>
        </Row> 
        <hr/>
        </React.Fragment>
     ) : null }

    {(testTotalCharge !== 0) ?
        <Row>
            <Col md={6} className="mt-3 text-right">
            </Col>
            <Col md={6} className="mt-3">
                <Row>
                    <Col md={9}><b>Total</b></Col>
                    <Col md={3}><b>&#8377; {testTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</b></Col>
                </Row>

            </Col>
        </Row>
        : null}
      
            <Row>
                <Col md={12} className="mt-3 text-right">
                    <Button className="button-lightred" size="md" style={{width: '190px', cursor: 'pointer'}}  onClick={this.addSample}>Add Test(s)</Button>{' '}
                </Col>
            </Row>
    
        <Row>
        <Col md={12} className="mt-3 text-center">
            <Button onClick={this.submitEditOrder} disabled={this.checkTestDetails(this.state.testDetails) ? false : true} className="button-lightred" size="md" style={{width: '190px', cursor: 'pointer'}} >Confirm</Button>
        </Col>
        </Row> 

    </CardBody>
    </Card>
    </Container>
    </React.Fragment>
    }
    </React.Fragment>
    )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        user: state.user,
        stsuccess: state.stsuccess,
        stdata: state.stdata,
        stestsuccess: state.stestsuccess,
        stestdata: state.stestdata,
        editordersuccess: state.editordersuccess,
        editordermessage: state.editordermessage
    }
  }
  
const mapDispatchToProps = dispatch => {
return {
    onPatientOrderList: (id) => dispatch(actions.patientOrderList(id)),
    onSampleType: () => dispatch(actions.sampleType()),
    onSampleTest: (id) => dispatch(actions.sampleTest(id)),
    onSubmitEditOrder: (data) => dispatch(actions.submitEditOrder(data)),
}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PatientEditOrder);