import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Button } from 'reactstrap';

const RegisterSuccess = (props) => (
    <Container className="text-center">
        <Row className="pt-3 pb-3">
          <Col><h5 className="red-font font-size">Thank you, Registration process completed. <br /> Kindly click the link provided in the email to login.</h5></Col>
        </Row>
        {/* <Row>
            <Col>
                <Link to="/login"><Button className="button-lightred mt-0" size="md" style={{width: '160px'}} >Log In</Button>{' '}</Link>
            </Col>
        </Row> */}
    </Container>
);

export default RegisterSuccess;