import React, {Component} from 'react';
import { Container, Table } from 'reactstrap';
import { Spinner, Row, Col } from 'reactstrap';
import { InputGroup, InputGroupText, InputGroupAddon, Input, Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';
import { convertToDateYYYYMMDD, sortByColumn, convertToDateDDMMYYYY } from '../functions';
import PaginationComponent from '../../components/pagination/pagination';
import PatientCashReceipt from './patientcashReceipt';
import { Redirect, withRouter } from 'react-router-dom';

class PatientOrderHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
          testtableheader: [
            {table_header: "Sr No.", table_sort_name: ""},
            {table_header: "Order No.", table_sort_name: "id"},
            {table_header: "Order Date", table_sort_name: "createdOn"},
            {table_header: "Payment Status", table_sort_name: "status"},
            {table_header: "Action", table_sort_name: ""},
            {table_header: "Update Order", table_sort_name: ""},
          ],
          testtabledata: this.props.phistoryData,
          loading: false,
          filter: null,
          toggle: false,
          activeColumn: 0,
          lastActiveColumn: 0,
          currentPage: 0,
          showcashreceipt: false
        }
        this.pageSize = 10;
    }

    // componentDidMount = () => {
    //   this.setState({
    //     testtabledata:this.props.phistoryData
    //   })

    // }

    componentWillReceiveProps(nextProps){
        this.setState({
            testtabledata:nextProps.phistoryData
        })
      }

      handleOrderHistoryClick = (e, index) => {
    
        e.preventDefault();
    
        this.setState({
          currentPage: index
        });
        
      }


    updateSearch = (inputValue) => {
      let filter = this.state.filter;

      this.setState({
          filter: inputValue
      });
    }

    handleSearchChange = (event) => {
        this.updateSearch(event.target.value);
    }

    filter = (data) => {
      if (!this.state.filter) {
        return data;
      }
        return data.filter((data) => data.id.toString().indexOf(this.state.filter) >= 0 ||
        convertToDateDDMMYYYY(data.createdOn).toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0 
    )
      
    }

    handleClick = (title, key) => {
      let arrayCopy = [...this.state.testtabledata];
    if(key === 1 || key === 2){
      if (this.state.activeColumn === key) {
        let toggle = !this.state.toggle
        this.setState({
          toggle: toggle,
          activeColumn: key,
          testtabledata: sortByColumn(arrayCopy, title, toggle)
        })
      } else {
        this.setState({
          activeColumn: key,
          testtabledata: sortByColumn(arrayCopy, title, false)
        })
      }
    }
    }
     
    cashReceipt = (order) => {
      this.props.history.push({pathname: '/patientCashReceipt', state: {order: order}})
    }

    invoice = (order) => {
      this.props.history.push({pathname: '/patientInvoice', state: {order: order}})
    }

    editOrder = (order) => {
      this.props.history.push({pathname: '/patientEditOrder', state: {order: order}})
    }

    render() {

      //console.log(this.props.user);
      //console.log(this.state.testtabledata);
      const { currentPage } = this.state;

      if(this.state.testtabledata) {
        this.pagesCount = Math.ceil(this.state.testtabledata.length / this.pageSize);
        
        this.state.testtabledata.map((el, key) => {
          el["srno"] = key + 1;
        })
      }
      
        let list = <Spinner animation="border" variant="danger" />
        if (!this.state.loading) {
            list = <Table>
            <thead>
              <tr>
                {this.state.testtableheader ? this.state.testtableheader.map((data, key) => {
                    return <th onClick={() => this.handleClick(data.table_sort_name, key)} className="cursor">{data.table_header}{(key === 1 || key === 2) ? (this.state.toggle && this.state.activeColumn === key) ? " ↓": " ↑" : ""}</th>
                }) : <Spinner color="danger" />
              }
              </tr>
            </thead>
            <tbody>
            {this.state.testtabledata ? this.filter(this.state.testtabledata).slice(
              currentPage * this.pageSize,
              (currentPage + 1) * this.pageSize
            ).map((order, key) => {
                return <tr>
                    <td>{order.srno}</td>
                    <td>{order.id}</td>
                    <td>{convertToDateDDMMYYYY(order.createdOn)}</td>
                    <td>{order.paymentStatus.status}</td>
                    <td>{order.paymentStatus.status === "SUCCESS" ?
                      <Button onClick={() => this.invoice(order)}>Invoice</Button>   :
                      <Button onClick={() => this.cashReceipt(order)}>Confirm and Pay</Button>
                    }</td>
                    <td>{order.lhBillId === 0 ? 
                    <Button onClick={() => this.editOrder(order)} disabled>Edit</Button> :
                    <Button onClick={() => this.editOrder(order)}>Edit</Button> }
                    </td>
                </tr>
            }) : <Spinner color="danger" />
          }
            </tbody>
          </Table>
        }
        return(
         
          <React.Fragment>
               <Container className="mb-5">
               {this.state.testtabledata ?
               <React.Fragment>
               <Row className="mt-2">
                 <Col md="3">
                 <InputGroup size="lg">
                  <InputGroupAddon addonType="prepend">Search</InputGroupAddon>
                  <Input value={this.state.filter} onChange={this.handleSearchChange} type="text" style={{zIndex: 0}}/>
                </InputGroup>
                 </Col>
               </Row>
               <Row className="mt-5 mb-5">
                 <Col className="text-center">
                 {list} 
                 </Col>
               </Row>
               <PaginationComponent currentPage={this.state.currentPage} handleClick={this.handleOrderHistoryClick} pagesCount={this.pagesCount}></PaginationComponent>
               </React.Fragment>
               : <h5 style={{color: 'red'}} className="mt-5 text-center">No orders to display</h5>
               }
             </Container>
            </React.Fragment>
              
        )
    }
}

const mapStateToProps = state => {
  return {
      loading: state.loading,
      user: state.user,
      data: state.data,
      phistoryData: state.phistoryData,
      pstatusdata: state.pstatusdata
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onPatientOrderList: (id) => dispatch(actions.patientOrderList(id)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientOrderHistory));