import React, {Component} from 'react';
import { Row, Col, FormGroup, Label, Input, Card, CardBody, Alert, Button, Container } from 'reactstrap';
import ModalComponent from '../../components/modal/modal';

class AdminPatientAddress extends Component {

    render() {
    //console.log(this.props.getPAdata);
    let getPAdata = this.props.getPAdata;

    return(   
    <React.Fragment>
    <Container style={{paddingLeft: '0px', paddingRight: '0px'}}>
    <Card className="collapse-box">
    <CardBody>
    <Row>
        <Col md={7} className="mt-3">
            <FormGroup>
                <Label for="address"><b>Check for existing address:</b></Label>
            </FormGroup>
        </Col>
    </Row>
    <Row>
        <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
            <FormGroup>
            <Input type="select" name="getAddress" placeholder="Select" onChange={(event) => this.props.getAddressInputChangedHandler(event)}>
              <option>Select...</option>
              {getPAdata ? getPAdata.map((data) => {
                  return <option value={data.id}>
                  {data.line1} {data.line2} {data.city} {data.state} {data.pincode}</option>
              })
            : null }
            </Input>
            </FormGroup>
        </Col>
    </Row>
    <Row>
        <Col md={7} className="mt-3">
            <FormGroup>
                <Label for="address"><b>Please enter your address for sample pickup:</b></Label>
            </FormGroup>
        </Col>
    </Row>
    <Row form>
        <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
            <FormGroup>
            <Label for="email">Line 1</Label>
            <Input required maxLength="45" type="text" name="line1" placeholder="Line 1" value={this.props.apAddress.line1} onChange={(event) => this.props.inputChangedAPHandler(event)}/>
            </FormGroup>
        </Col>
        <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
            <FormGroup>
            <Label for="address">Line 2</Label>
            <Input required maxLength="45" type="text"  name="line2" placeholder="Line 2" value={this.props.apAddress.line2} onChange={(event) => this.props.inputChangedAPHandler(event)}/>
            </FormGroup>
        </Col>
        <Col md={3} className="ml-md-3 ml-lg-5 mr-md-3 mr-lg-5">
        <FormGroup>
                <Label for="mobile">City</Label>
                <Input required maxLength="45" type="text"  name="city" placeholder="City" value={this.props.apAddress.city} onChange={(event) => this.props.inputChangedAPHandler(event)} />
            </FormGroup>
        </Col>
    </Row>
    <Row>
        <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
            <FormGroup>
            <Label for="email">State</Label>
            <Input required maxLength="45" type="text"  name="state" placeholder="State" value={this.props.apAddress.state} onChange={(event) => this.props.inputChangedAPHandler(event)}/>
            </FormGroup>
        </Col>
        <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
            <FormGroup>
                <Label for="address">Pincode</Label>
                <Input required maxLength="6" type="text"  name="pincode" placeholder="Pincode" value={this.props.apAddress.pincode} onChange={(event) => this.props.inputChangedAPHandler(event)}/>
            </FormGroup>
        </Col>
    </Row>

    <Row>
        <Col md={5} className="mt-3">
            <FormGroup>
                <Label for="pincode"><b>Enter your pincode to check if your area is serviceable</b></Label>
                <Input required maxLength="6" type="text" name="pincode" onChange={(e) => this.props.handlePinVerification(e)} value={this.props.apAddress.pincode} placeholder="Enter Pincode" />
            </FormGroup>
        </Col>
        <Col md={2} className="mt-5">
        <Button onClick={(event) => this.props.pinVerification(event)} className="button-lightred" style={{marginTop: '0', cursor: 'pointer'}} >Verify</Button>
        </Col>
    </Row>

    {this.props.pinsuccess ? 
    <Alert color="success" isOpen={this.props.visible}>Verification Successfull, requested area is serviceable.</Alert> 
    : null }

    {this.props.pindata ? 
    <Alert color="danger" isOpen={this.props.visible}>Invalid pincode.</Alert> 
    : null }
    
    {this.props.pinfailure ? 
    <Alert color="danger" isOpen={this.props.visible}>Verification Unsuccessfull, requested area is not serviceable.</Alert>
    : null } <hr/>

    </CardBody>
    </Card>  

    {/* {this.props.pinfailure ?
    <ModalComponent modal={this.props.pinmodal} pinVerification={this.props.pinVerification} className="trainee-modal">
        <h5>The area is un-serviceable. If you still want to continue, Kindly send sample to XYZ address.</h5>
        <Button color="success" onClick={this.props.yescloseToggle}>Yes</Button>
        <Button color="danger" onClick={this.props.nocloseToggle} className="ml-3">No</Button>
    </ModalComponent>
    : null } */}
    
    </Container>
    </React.Fragment>
        )
    }
}

export default AdminPatientAddress;