import React, {Component} from 'react';
import { Container, Table } from 'reactstrap';
import { Spinner, Row, Col } from 'reactstrap';
import { InputGroup, InputGroupText, InputGroupAddon, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import HomeApi from '../../../services/API/HomeApi';
import { sortByColumn } from '../../functions';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/actions';
import { convertToDateYYYYMMDD, convertToDateDDMMYYYY } from '../../functions';
import { ListGroupItem } from 'react-bootstrap';
import TrackSampleDataCol from './trackSampleDataCol';
import PaginationComponent from '../../../components/pagination/pagination';


class TrackSampleTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
          testtabledata: [],
          loading: false,
          filter: null,
          toggle: false,
          activeColumn: 0,
          lastActiveColumn: 0,
          modal: false,
          currentPage: 0
        }
        this.pageSize = 10;
    }

    componentDidMount = () => {
      //this.props.onPatientOrderList(this.props.user.id);
      this.setState({
        //modal:nextProps.pstatusdata ? true : false,
        testtabledata:this.props.trackData
      })
    }

    componentWillReceiveProps(nextProps){
      this.setState({
          testtabledata:nextProps.phistoryData
      })
    }

    handlePatientTrackClick = (e, index) => {
    
      e.preventDefault();
  
      this.setState({
        currentPage: index
      });
      
    }
    
    toggle = () => {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    updateSearch = (inputValue) => {
      let filter = this.state.filter;

      this.setState({
          filter: inputValue
      });
    }

    handleSearchChange = (event) => {
        this.updateSearch(event.target.value);
    }

    filter = (data) => {
      if (!this.state.filter) {
        return data;
      }
      return data.filter((data) => data.id.toString().indexOf(this.state.filter) >= 0 ||
                                   convertToDateDDMMYYYY(data.createdOn).toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0 ||
                                    data.logisticStatus.status.toLowerCase().indexOf(this.state.filter) >= 0
    )
      
    }

    handleClick = (title, key) => {
      let arrayCopy = [...this.state.testtabledata];
    if(key === 1 || key === 2 || key === 3){
      if (this.state.activeColumn === key) {
        let toggle = !this.state.toggle
        this.setState({
          toggle: toggle,
          activeColumn: key,
          testtabledata: sortByColumn(arrayCopy, title, toggle)
        })
      } else {
        this.setState({
          activeColumn: key,
          testtabledata: sortByColumn(arrayCopy, title, false)
        })
      }
    }
    }

    render() {

      const paginationArray = [];
      const paginationNumberArray = [];

      //console.log("Patient Order Data");
      //console.log(this.props.phistoryData);

      //console.log("Patient logistics Statuses");
      //console.log(this.props.pstatusdata);

      //console.log("Patient livehealth Statuses");
      //console.log(this.props.lpstatusdata);

      if(this.props.phistoryData && this.props.pstatusdata && this.props.lpstatusdata.length !== 0) {
        this.props.phistoryData.forEach((o) => {
          o["livehealthStatus"] = [];
            // this.props.pstatusdata.forEach((p) => {
            //     if(o.awbId === p.id) {
            //         o["logisticsStatus"] = p.status;
            //     }
            // })
            this.props.lpstatusdata.forEach((p) => {
              if(o.lhBillId === p.id) {
                o["livehealthStatus"] = p.list;
              }
          })
        })
      }  

      const { currentPage } = this.state;

      if(this.state.testtabledata) {
        this.state.testtabledata.map((data) => {
          return data.paymentStatus.status === "SUCCESS" ? 
              paginationNumberArray.push(data) : null
        })

        this.pagesCount = Math.ceil(paginationNumberArray.length / this.pageSize);

        paginationNumberArray.map((el, key) => {
          el["srno"] = key + 1;
        })
      }
      
      //console.log(this.props.phistoryData);

        let list = <Spinner animation="border" variant="danger" />
        if (!this.state.loading) {
          list = <React.Fragment>
          <Row>
            <Col md={1} xs={4} className="text-center">Sr No.</Col>
            <Col md={2} xs={4} className="text-center" onClick={() => this.handleClick("id", 1)}>
            Order No. {(this.state.toggle && this.state.activeColumn === 1) ? " ↓": " ↑"}</Col>
            <Col md={2} xs={4} className="text-center" onClick={() => this.handleClick("createdOn", 2)}>
            Order Date {(this.state.toggle && this.state.activeColumn === 2) ? " ↓": " ↑"}</Col>
            {/* <Col md={2} xs={4} className="text-center">Pickup Date & Time</Col> */}
            <Col md={2} xs={4} className="text-center">Sample Status / Pickup Date & Time</Col>
            <Col md={5} xs={8} className="text-center">Lab Report Status</Col>
          </Row><hr/>
          <Row>
            <Col className="text-center">
              {this.state.testtabledata ? this.state.testtabledata.map((data) => {
              return data.paymentStatus.status === "SUCCESS" ? 
              paginationArray.push(data) : null
              }) &&  
              this.filter(paginationArray).slice(
              currentPage * this.pageSize,
              (currentPage + 1) * this.pageSize
            ).map((order, key) =>
                   order.paymentStatus.status === "SUCCESS" ?
                  <TrackSampleDataCol order={order} keyValue = {key} pstatusdata={this.props.pstatusdata}/> : null
                  ) : <Spinner color="danger" />
              }
            </Col>
          </Row>
          </React.Fragment>
        }
        return(
          <React.Fragment>
               <Container className="mb-5">
               {this.state.testtabledata ?
               <React.Fragment>
               <Row className="mt-2">
                 <Col md="3">
                 <InputGroup size="lg">
                  <InputGroupAddon addonType="prepend">Search</InputGroupAddon>
                  <Input value={this.state.filter} onChange={this.handleSearchChange} type="text" style={{zIndex: 0}}/>
                </InputGroup>
                 </Col>
               </Row>
               <Row className="mt-5 mb-5">
                 <Col className="text-center">
                 {list} 
                 </Col>
               </Row>
               <PaginationComponent currentPage={this.state.currentPage} handleClick={this.handlePatientTrackClick} pagesCount={this.pagesCount} />
               </React.Fragment>
             : <h5 style={{color: 'red'}} className="mt-5 text-center">No orders to display</h5>
              }
             </Container>

             <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
                  {/* <ModalBody>
            {this.props.pstatusdata ? <h2>{this.props.pstatusdata[0].status}</h2> : null}
                </ModalBody> */}
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
          </React.Fragment>
              
        )
    }
}

const mapStateToProps = state => {
  return {
      loading: state.loading,
      user: state.user,
      phistoryData: state.phistoryData,
      lpstatusdata: state.lpstatusdata
  }
}

const mapDispatchToProps = dispatch => {
  return {
    //onPatientOrderList: (id) => dispatch(actions.patientOrderData(id)),
    //onPatientGetOrderStatus: (billId) => dispatch(actions.patientGetOrderStatus(billId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackSampleTable);