import React, {Component} from 'react';
import { connect } from 'react-redux';
import TitlebarComponent from '../../components/navbar/titlebar';
import Navbar from '../../components/navbar/navbar';
import { Row, Col, Container, Form, Table, UncontrolledCollapse, Button, CardBody, Card, Alert, FormGroup, Input, Label,ListGroup, ListGroupItem } from 'reactstrap';
import "react-picky/dist/picky.css";
import TrackSampleTable from './trackSample/trackSampleTable';
import * as actions from '../../redux/actions/actions';
import HomeApi from '../../services/API/HomeApi';
import ModalComponent from '../../components/modal/modal';
import { Spinner } from 'react-bootstrap';
import CreditPartnerCard from './creditpartnerPatientCard';
import CreditPartnerOrderHistory from './creditPartnerOrderHistory';
import OrderDataCard from './orderDataCard';
import './creditPartner.css';
import logo from '../../assets/TataTrustsNewLogo.png';
import ModalWishlistComponent from '../../components/modal/modalwishlist';
import { convertDOB } from '../functions';

class CreditPartnerHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testList: this.defaultTestDetails(),
            patient: [
                {   
                    userId: '',
                    designation: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    mobile: '',
                    gender: '',
                    dob: '',
                    testList: [
                        {
                            value: null,
                            selectedSample: '',
                            testListArr: [],
                            testTotalCharge: 0
                        }
                    ],
                    
                }
            ],
            patientForm: this.defaultPatientForm(),
            validationMessages: [],
            form: this.defaultForm(),
            testtableheader: [],
            testtabledata: [],
            modal: false,
            filter: null,
            pincode: null,
            duplicatePatientError: false,
            toggler: false,
            toggler1: false,
            toggler2: false,
            toggler3: false,
            toggler4: false,
            loading: false,
            testModal: false,
            visible: false,
            noValidationMessages: [],
            courierCost: false
            // bigList: [{id: 1, name: 'Test Name 1'},{id: 2, name: 'Test Name 2'}, {id: 3, name: 'Test Name 3'} ]
        }
    }

    componentDidMount(){
        this.props.onSampleType();
        this.props.onLogisticCost();
        this.props.oncreditPartnerOrderHistoryData(this.props.user.id);
        this.props.onCreditBalance(this.props.user.id);
    }

    defaultTestDetails = () => {
        return{
            value: null,
            selectedSample: '',
            testListArr: []
        }
    }

    defaultForm = () => {
        return {
            userTypeId: this.props.userTypeId ? this.props.userTypeId : 3,
            firstName: '',
            lastName: '',
            contactNo: '',
            gender:'',
            emailId: '',
            // address: '',
            // dob: new Date(),
            // state: '',
            // city: '',
            // pincode:'',
            // password: ''
        }
      }
    
    defaultPatientForm = () => {
        return {   
            userId: '',
            designation: '',
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            gender: '',
            dob: '',
            testList: [
                {
                    value: null,
                    selectedSample: '',
                    testListArr: [],
                }
            ]
        }
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.cpsuccess) {
            this.setState({
                patient: [
                    {   
                        userId: '',
                        designation: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        mobile: '',
                        gender: '',
                        dob: '',
                        testList: [
                            {
                                value: null,
                                selectedSample: '',
                                testListArr: [],
                                testTotalCharge: 0
                            }
                        ],
                        
                    }
                ],
                loading: false,
                successMsg: true
            })

            setTimeout(() => {
                this.props.oncreditpartnerOrderReset();
                this.props.oncreditPartnerOrderHistoryData(this.props.user.id);
                this.props.onCreditBalance(this.props.user.id);
              }, 2000);
        }
        
        if(nextProps.cperror) {

            let duplicatePatient = [];

            this.state.patient.map((patient, key) => {
                nextProps.cpmessage.map((sequence) => {
                    if(key === sequence) {
                        duplicatePatient.push(patient);
                    }
                }) 
            });

            duplicatePatient.forEach(function (patientItem) {
                patientItem["validationMessages"] = "Patient already registered. Kindly check the details.";
            });

            //console.log(duplicatePatient);

            this.setState({
                loading: false,
                
            })
            
        }

        if(nextProps.cplimiterror){
            this.setState({
                loading: false,
            })
        }

        // if(nextProps.cphistorysuccess && nextProps.posuccess === null) {
        //     this.props.oncreditPartnerPatientOrderData();
        // }
        // if(nextProps.posuccess) {
        
        //     this.props.oncreditPartnerPatientStatus();
            
        // }
        
    }

    inputChangedPatientHandler = (e, key, key1) => {
      
        if(key1 !== undefined) {
            let patient = this.state.patient;

            let id = e.target.value;
        
            if(e.target.value !== "Select Sample"){
            this.props.onSampleTest(id);
            }
      
            const updatedForm = [
                ...patient[key].testList.slice(0,key1),
                Object.assign({}, patient[key].testList[key1], {[e.target.name]: e.target.value}),
                ...patient[key].testList.slice(key1 + 1),
              ]
      
              const updatedPatient = [
                  ...this.state.patient.slice(0,key),
                  Object.assign({}, this.state.patient[key], {testList: updatedForm}),
                  ...this.state.patient.slice(key + 1),
              ]

              let validationMessages = [];
              this.setState({
                patient: updatedPatient, 
                validationMessages
              });
        }else {
                let patient = this.state.patient;
                let validationMessages = [];
                let noValidationMessages = [];
                if(e.target.name === 'mobile') {
                    let validMobile = patient.some(el => el.mobile === e.target.value)
                    if(validMobile) {
                        let errormessages = "Repeated Mobile no";

                        const updatedPatient = [
                            ...patient.slice(0,key),
                        Object.assign({}, patient[key], {validationMessages: errormessages}),
                            ...patient.slice(key + 1),
                        ]

                        this.setState({
                            patient: updatedPatient
                          });
                        return;
                    }
                    else {
                            delete patient[key].validationMessages;
                            this.setState({
                                duplicatePatientError: false
                            });
                    }

                }

                if (e.target.type === "number") {
                    e.target.value = e.target.value.slice(0,10);
                  } 
                  
                  if(e.target.type === "number" && e.target.value.length < 10){
                    noValidationMessages.push("Required 10 digits number, match requested format.");
                    this.setState({noValidationMessages});
                  }
                  else {
                    validationMessages = [];
                    this.setState({noValidationMessages});
                }

                const updatedPatient = [
                    ...patient.slice(0,key),
                Object.assign({}, patient[key], {[e.target.type === 'radio' ? e.target.name.slice(0, -1) : e.target.name]: e.target.value}),
                    ...patient.slice(key + 1),
                ]

                this.setState({
                  patient: updatedPatient, 
                  validationMessages
                });
        }  
    }

    handleDOBChange = (date, key) => {
        let convertedDate = convertDOB(date);
        // const updatedFormDate = {
        //   ...this.state.patient[key],
        //   dob: date
        // }
        let patient = this.state.patient;

        const updatedFormDate = [
            ...this.state.patient.slice(0,key),
            Object.assign({}, this.state.patient[key], {dob: date}),
            ...this.state.patient.slice(key + 1),
        ]

        let validationMessages = [];
        this.setState({
        patient: updatedFormDate, validationMessages
        });
        
        
      }

    profileinputChangedHandler = (e) => {
        const updatedprofileForm = {
          ...this.state.form,
          [e.target.name]: e.target.value
        }
        let validationMessages = [];
        this.setState({
          form: updatedprofileForm, 
          validationMessages
        });
          
    }

    submitHandler = (event) => {
        //console.log("profile form clicked!");
        event.preventDefault();
    
        // const updatedFormDate = {
        //   ...this.state.form,
        //   dob: convertToDateYYYYMMDD(this.state.form.dob)
        // }
    
        //this.props.onSignUp(updatedFormDate);
    }

    selectPatientMultipleOption = (value, key, key1, event) => {
        console.count('onChange')
        //console.log("Val", value);

        let patient = this.state.patient;

        const updatedPatientTestDetails = [
            ...patient[key].testList.slice(0,key1),
            Object.assign({}, patient[key].testList[key1], {testListArr: value}),
            ...patient[key].testList.slice(key1 + 1),
          ]
  
        const updatedPatient = [
              ...patient.slice(0,key),
              Object.assign({}, patient[key], {testList: updatedPatientTestDetails}),
              ...patient.slice(key + 1),
          ]

        

        this.setState({ patient: updatedPatient }, () => {
            this.calculateCharge(value, key, key1);
        });
    }

    calculateCharge = (test, key, key1) => {
        // let testListChargeArr = [];
        // this.state.patient.map((el) => {
        //     el.testList.map((elsub) => {
        //     testListChargeArr.push(elsub.testListArr);
        // })
        // })
 

        let testTotalCharge = test.reduce((acc, test) => {
            return acc + Number(test.price)
        }, 0)

        const updatedPatientTestDetails = [
            ...this.state.patient[key].testList.slice(0,key1),
            Object.assign({}, this.state.patient[key].testList[key1], {testTotalCharge: testTotalCharge}),
            ...this.state.patient[key].testList.slice(key1 + 1),
          ]
  
        const updatedPatient = [
              ...this.state.patient.slice(0,key),
              Object.assign({}, this.state.patient[key], {testList: updatedPatientTestDetails}),
              ...this.state.patient.slice(key + 1),
          ]

          this.setState({ patient: updatedPatient });
    }

    togglemodal = () => {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
    }

    addPatient = (patientForm) => {
        this.setState(previousState => ({
            patient: [...previousState.patient, patientForm],
            patientForm: this.defaultPatientForm()
        }));
    }

    removePatient = (patientData, key) => {
        const patientafterDeleteArr = this.state.patient;

        patientafterDeleteArr.splice(key, 1);

        this.setState({
            patient: patientafterDeleteArr
        });
    }

    addSample = (pdata, key) => {
        pdata.testList.push({
            value: null,
            selectedSample: '',
            testListArr: []
        });

        const updatedPatientTestDetails = [
            ...this.state.patient.slice(0,key),
            Object.assign({}, this.state.patient[key], {testList: pdata.testList}),
           ...this.state.patient.slice(key+1)
        ]
        
        this.setState({ patient: updatedPatientTestDetails });
    }

    removeSample = (pdata, key, key1) => {

        pdata.testList.splice(key1, 1);

        const updatedPatientTestDetails = [
            ...this.state.patient.slice(0,key),
            Object.assign({}, this.state.patient[key], {testList: pdata.testList}),
           ...this.state.patient.slice(key+1)
        ]

        this.setState({
            patient: updatedPatientTestDetails
        });
    }

    updateSearch = (inputValue) => {
        let filter = this.state.filter;
    
        this.setState({
        filter: inputValue
        });
    }

    handleSearchChange = (e) => {
        this.updateSearch(e.target.value);

        if(e.target.value.length >= 2) {
            this.props.onPatientSearch(this.props.user.id, e.target.value);
        }else{
            this.props.onPatientSearch(this.props.user.id, e.target.value);
        }
        
    }

    searchExistingPatient = (event) => {
        event.preventDefault();

        this.props.onPatientSearch(this.props.user.id,this.state.filter);
    }

    selectExistingPatient = (existingPatient) => {
        
        this.props.onPatientSearch(this.props.user.id,"");

        let patientwithExistingPatient = {   
            userId: existingPatient.id,
            designation: existingPatient.designation,
            firstName: existingPatient.firstName,
            lastName: existingPatient.lastName,
            email: existingPatient.emailId,
            mobile: existingPatient.mobile,
            gender: existingPatient.gender,
            dob: existingPatient.dob,
            testList: [
                {
                    value: null,
                    selectedSample: '',
                    testListArr: [],
                }
            ]
        };

        this.setState(prevState => ({
            filter: "",
            patient: [...prevState.patient, patientwithExistingPatient]
          }))

        
    }


    submitPatientTestDetails = (event) => {
        event.preventDefault();

        let bulkPatientTestDetails = this.state.patient.map((patient, key) => {
            const patientTestListIdArr = patient.testList.map((el) => {
                return el.testListArr.map(function(subtest) {
                    return subtest.lhTestId;
                  })
            })

            var convertedDateTest = convertDOB(patient.dob);

            var patientTestListId = [].concat.apply([],patientTestListIdArr).filter((elem, index, self) => {
                return index === self.indexOf(elem);
            })
            
            const patientTestDetails = {
                ...patient,
                dob: convertedDateTest,
                testList: [...patientTestListId],
                sequence: key,
            }
            return patientTestDetails;
        })

        let duplicatePatient = this.state.patient.map((patient) => {
            if (patient.hasOwnProperty('validationMessages')) {
                return true;
            }else {
                return false;
            }
        });

        
        //console.log(bulkPatientTestDetails);
            if(!duplicatePatient.includes(true)) {
                this.setState({
                    loading: true,
                });

                this.setState(prevState => ({
                    testModal: !prevState.testModal
                }));

                this.props.onBulkPatientSampleSubmit(this.props.user.id, bulkPatientTestDetails, this.state.courierCost);

                this.setState({
                    visible: true
                  }, () => {
                    window.setTimeout(() => {
                      this.setState({
                        visible: false
                      })
                    }, 5000)
                  });
            }else {
                this.setState({
                    duplicatePatientError: true
                });
                this.setState(prevState => ({
                    testModal: !prevState.testModal
                }));
                this.setState({
                    visible: true
                  }, () => {
                    window.setTimeout(() => {
                      this.setState({
                        visible: false
                      })
                    }, 5000)
                  });
            }
        
    }

    handlePinVerification = (e) => {
        this.setState({
            pincode: e.target.value
        })
    }

    pinVerification = (event) => {
        event.preventDefault();
        
        this.props.onPinVerification(this.state.pincode);
    }


    checkPatientDetails = (patients) => {

        let flag = null;

        patients.forEach(function (patient) {
            if(flag || flag === null) {
                if(patient.designation !== "" && patient.firstName !== "" && patient.lastName !== "" && patient.dob !== "" && patient.gender !=="") {
                    patient.testList.forEach(function (test) {
                        if(flag || flag === null) {
                        if(test.selectedSample !== "" && test.testListArr.length !== 0) {
                            flag = true;
                        }
                        else {
                            flag = false;
                        }
                        }
                    });
                    
                }
                else {
                    flag = false;
                }
            }
        });
        //console.log(flag);
        return flag;

    }

    toggle = (tabno) => {
        switch(tabno) {
            case 0:
                this.setState(prevState => ({
                    toggler: !prevState.toggler
                }));
                break;
            case 1:
                this.setState(prevState => ({
                    toggler1: !prevState.toggler1
                }));
                break;
            case 2:
                this.setState(prevState => ({
                    toggler2: !prevState.toggler2
                }));
                break;
            case 3:
                this.setState(prevState => ({
                    toggler3: !prevState.toggler3
                }));
                break;
            case 4:
                this.setState(prevState => ({
                    toggler4: !prevState.toggler4
                }));
                break;
        }
    }

    // togglerinner = (key) => {
    //     switch(key) {
    //         case key:
    //             this.setState(prevState => ({
    //                 togglerinner: !prevState.togglerinner
    //             }));
    //             break;
    //     }
    // }

    testToggleModal = () => {
        this.setState(prevState => ({
          testModal: !prevState.testModal
        }));
    }

    totalOfTestCharge = (data) => {
        //console.log(data);
        let start = 0;
        data.forEach(function(item){
           return start = start + item.testTotalCharge;
        })
        return start;
    }

    handleCourierCost = () => {
        this.setState(prevState => ({
            courierCost: !prevState.courierCost
        }))
    }

    scrollDown = () =>{
        window.scrollTo(0, 500);
    }

    scrollDownOrderHistory =() => {
        window.scrollTo(0, 1500);
    }

render(){
    
    let grandTotalArr = [];
    let charge = this.props.lcdata;   
    this.state.patient.forEach((el) => {
        el.testList.forEach((testEl) => {
            grandTotalArr.push(testEl.testListArr);
        })
    })
        
    let grandTotalCharge = [].concat.apply([],grandTotalArr).reduce((acc, test) => {
        return acc + Number(test.price)
    }, 0)

    // if(this.props.phistoryData && this.props.cphistoryData.length !== 0) {
    //     this.props.phistoryData.forEach((o) => {
    //         o["creditPartnerPatients"] = [];
    //         const patientsOrderArr = [].concat.apply([], this.props.cphistoryData);

    //         patientsOrderArr.forEach((p) => {
    //             if(o.id === p.orderId) {
    //                 o.creditPartnerPatients.push(p);
    //             }
    //         })
    //     })
    // }  

    //console.log(this.props.cphistoryData);
    //console.log(this.props.stdata);

return(
<React.Fragment>
<TitlebarComponent loggedinpatient={this.props.isAuthenticated ? true : false} />
<Navbar patientHome={this.props.isAuthenticated ? true : false} />
<Container>
    <Row className="mt-5">
        <Col>
            <h2>Welcome, {this.props.user.orgName}</h2>
        </Col>
    </Row>
    <Row className="mt-5" style={{cursor: 'pointer'}}>
        <Col>
        <div className="profile-tab" id="toggler" onClick={() => this.toggle(0)}>{this.state.toggler ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>} Lab Details </div>
        <UncontrolledCollapse toggler="toggler">
        <Card className="collapse-box">
            <CardBody>
                <Row>
                    {/* <Col md={12} className="text-right"><i class="fa fa-edit" onClick={this.togglemodal}></i></Col> */}
                    <Col md={6} className="mt-3"><b>Organization ID:</b> {this.props.user.orgId}</Col>
                    <Col md={6} className="mt-3"><b>Lab Name:</b> {this.props.user.orgName}</Col>
                    <Col md={6} className="mt-3"><b>Mobile No.: </b> {this.props.user.contactNo}</Col>
                    <Col md={6} className="mt-3"><b>Email ID:</b> {this.props.user.emailId}</Col>
                    {this.props.CBsuccess ?
                    <Col md={6} className="mt-3"><b>Credit Balance:</b> &#8377; {(this.props.CBdata).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</Col>
                    : null}
                </Row>
                {/* <Row className="mt-5">
                    <Col className="text-center">
                        <Alert color="danger">
                        The final diagnosis report will be generated on the registered patient name ; Incase the order is being placed for a different patient, please register a new patient.
                        </Alert>
                        <Button className="button-lightred" size="md" style={{width: '190px'}} onClick={this.props.ongetReports}>Register New User</Button>{' '}
                    </Col>
                </Row> */}
            </CardBody>
        </Card>
        </UncontrolledCollapse>
        </Col>
    </Row>

    <ModalComponent modal={this.state.modal} togglemodal={this.togglemodal} submit={this.submitHandler} title="Edit Patient Profile" className="patient-profile-modal">
        <Form onSubmit={this.submitHandler}>
                <FormGroup>
                <Label for="fname">First Name</Label>
                <Input type="text" value={this.state.form.firstName} name="firstName" placeholder="First Name" onChange={(event) => this.profileinputChangedHandler(event)} />
                </FormGroup>
                <Label for="lname">Last Name</Label>
                <FormGroup>
                <Input type="text" value={this.state.form.lastName} name="lastName" placeholder="Second Name" onChange={(event) => this.profileinputChangedHandler(event)} />
                </FormGroup>
                <FormGroup>
                <Label for="mobile">Mobile Number</Label>
                <Input type="text" value={this.state.form.contactNo} name="contactNo" placeholder="Mobile Number" onChange={(event) => this.profileinputChangedHandler(event)} />
                </FormGroup>
                <FormGroup>
                <Label for="email">Email</Label>
                <Input type="email" value={this.state.form.emailId} name="emailId" placeholder="Email Address" onChange={(event) => this.profileinputChangedHandler(event)}/>
                </FormGroup>
                <FormGroup>
                <Label for="exampleDate">Date of Birth</Label>
                <Input
                type="date"
                name="dob"
                id="exampleDate"
                placeholder="Date of Birth"
                onChange={(event) => this.profileinputChangedHandler(event)}
                />
            </FormGroup>
                <FormGroup tag="fieldset">
            <legend className="col-form-label">Gender :</legend>
            <Col>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="gender" value={this.state.form.gender} onChange={(event) => this.profileinputChangedHandler(event)}/>{' '}
                    Male
                </Label>
                </FormGroup>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="gender" value={this.state.form.gender} onChange={(event) => this.profileinputChangedHandler(event)}/>{' '}
                    Female
                </Label>
                </FormGroup>
                <FormGroup check >
                <Label check>
                    <Input type="radio" name="gender" value={this.state.form.gender} onChange={(event) => this.profileinputChangedHandler(event)}/>{' '}
                    Other
                </Label>
                </FormGroup>
                
            </Col>
            </FormGroup>
            <Button className="button-lightred mt-0" size="md" style={{width: '120px'}} >Submit</Button>{' '}
            <Button color="secondary" onClick={this.togglemodal}>Cancel</Button>

        </Form>
    </ModalComponent>

    <Row className="mt-3" style={{cursor: 'pointer'}}>
        <Col>
        <div className="profile-tab" id="toggler2" onClick={() => this.toggle(2)}>{this.state.toggler2 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>}  Order tests for patients </div>
        <UncontrolledCollapse toggler="toggler2">
        <Card className="collapse-box">
            <CardBody>
                <React.Fragment>
                    <Row>
                        <i className="fa fa-search cpSearchColor ml-3 mt-1"></i>
                        <Col md={4}>
                        <Input type="text" name="search" placeholder="Search" onChange={(e) => this.handleSearchChange(e)} value={this.state.filter}/>
                        {this.props.searchData.length !== 0 ? 
                        <ListGroup flush>
                        {this.props.searchData.map((el) => 
                        <ListGroupItem onClick={() => this.selectExistingPatient(el)}>{el.firstName + ' ' + el.lastName + ' - (' + el.mobile + ')'}</ListGroupItem>
                        )
                        }
                    </ListGroup>
                        :
                        <div className="text-center p-3">
                            {/* {!this.props.success ? this.props.loading ? <Spinner animation="border" variant="danger" /> : null : <div style={{color: 'red'}}>Patient Not Found</div>} */}
                        </div> 
                        }
                        
                        </Col>
                        <Col md={2}>
                          {/* <Button onClick={(event) => this.searchExistingPatient(event)} className="button-lightred" style={{marginTop: '0'}} >Search</Button> */}
                        </Col>
                    </Row>
                {this.state.patient ? this.state.patient.map((pdata, key) =>
                   <CreditPartnerCard stestdata={this.props.stestdata} stdata={this.props.stdata} keyValue={key} pdata={pdata} removePatient={this.removePatient} inputChangedPatientHandler={this.inputChangedPatientHandler} 
                   removeSample={this.removeSample} addSample={this.addSample} selectPatientMultipleOption={this.selectPatientMultipleOption} handleDOBChange={this.handleDOBChange} noValidationMessages={this.state.noValidationMessages}/>
                ) : null}
                <Row>
                    <Col md={12} className="text-right mt-3">
                        <Button className="button-lightred" size="md" style={{width: '190px', marginTop:'0'}} onClick={() => this.addPatient(this.state.patientForm)}>Add new patient</Button>{' '}
                    </Col>
                </Row>

        {this.props.user.address ?
        <Row>
            <Col>
            {this.props.user.address.islogisticserviceable === 0 ?
            <p className="mb-5 mt-5"><b style={{fontSize: '15px'}}>Centre for Oncopathology's sample pickup services are not available at your address, in pincode - {this.props.user.address.pincode}. 
            Please use any courier service to send the sample to the Centre for Oncopathology, Mumbai.</b></p>
            : null }

            {this.props.user.address.islogisticserviceable === 1 ?
            <React.Fragment>
             <p className="mt-5"><b style={{fontSize: '15px'}}>Centre for Oncopathology offers sample pickup services from your address in pincode - {this.props.user.address.pincode}. <br/><br/>
             The logistics charges for sample collection and delivery will be shared with your organization at the end of the month along with the test billing cycle. 
             The logistics charges will be levied as per the contract terms signed between your organization and Centre for Oncopathology.<br/><br/>
             If you don’t wish to use our pickup services, Select the "No pickup service required" checkbox below.</b></p>   
            <Row className="mb-5">
            <Col md={12}>
            <FormGroup check>
            <Label check>
                <Input type="checkbox" value={this.state.courierCost} onChange={this.handleCourierCost}/>{' '}
                No pickup service required 
            </Label>
            </FormGroup>
            {/* {this.state.courierCost ?
            <p className="red-font mt-3 ml-3">User to courier on their own.</p>
            : null} */}
            </Col>
            </Row>
            
            </React.Fragment>
            : null }
            </Col>
        </Row>
        : null }
                
                {/* {this.state.duplicatePatientError ? <h4 className="text-center" style={{color: 'red'}}> Please enter unique mobile number.</h4> : null} */}
                
                {/* <Row>
                    <Col md={12} className="mt-3 text-center">
                        {grandTotalCharge ?
                        <div style={{fontWeight: 700, fontSize: '12pt'}}>Grand Total: &#8377; {grandTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
                        : null }
                    </Col>
                </Row>  */}

                {this.props.user.address ?
                this.props.user.address.islogisticserviceable === 1 && this.props.lcdata && grandTotalCharge !== 0 && this.state.courierCost === false? 
                <p className="text-center mt-4" style={{fontWeight: 'bold'}}>Grand Total : &#8377; {(Number(grandTotalCharge)).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
                :  null
                : null }

                {this.props.user.address ?
                    (this.props.user.address.islogisticserviceable === 0 && this.props.lcdata && grandTotalCharge !== 0) || 
                    (this.props.user.address.islogisticserviceable === 1 && this.props.lcdata && grandTotalCharge !== 0 && this.state.courierCost === true)? 
                    <p className="text-center mt-4" style={{fontWeight: 'bold'}}>Grand Total : &#8377; {grandTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p> 
                    : null
                : null }         
                
                <Row>
                    <Col md={12} className="mt-3 text-center">
                        <Button onClick={this.testToggleModal} disabled={this.checkPatientDetails(this.state.patient) ? false : true} className="button-lightred" size="md" style={{width: '190px'}} >Confirm order for all the above listed patient(s)</Button>{' '}
                    </Col>
                </Row>
                {/* onClick={this.submitPatientTestDetails} */}
                {this.state.loading ? 
                    <Row>
                    <Col md={12} className="mt-3 text-center">
                        <Spinner animation="border" variant="danger" className="text-center" />
                        <p style={{color: 'red'}}>Processing Your Request. Please do not refresh or close the tab.</p>
                    </Col>
                    </Row>
                : null}

                {this.props.cpsuccess ? <Alert color="success" className="text-center mt-3" isOpen={this.state.visible}>Order Placed Successfully</Alert> : null}

                {this.props.cperror ? <Alert color="danger" className="text-center mt-3" isOpen={this.state.visible}>Order not completed. Please try again.<br/>Mobile number already registered.</Alert> : null}
                
                {this.props.cplimiterror ? <Alert color="danger" className="text-center mt-3" isOpen={this.state.visible}>
                    The order can not be placed, due to insufficient credit balance. Kindly request Oncopath Lab to increase credit limit.</Alert> : null}
                </React.Fragment>

            </CardBody>
        </Card>
        </UncontrolledCollapse>
        </Col>
    </Row>
    <Row className="mt-3" style={{cursor: 'pointer'}}>
        <Col>
        <div className="profile-tab" id="toggler3" onClick={() => this.toggle(3)}>{this.state.toggler3 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>} Track Order</div>
        <UncontrolledCollapse toggler="toggler3">
        <Card className="collapse-box">
            <CardBody>
            <Row className="mt-3">
        <Col>
        
            <TrackSampleTable trackOrderData={this.props.phistoryData} />
        </Col>
    </Row>
            </CardBody>
        </Card>
        </UncontrolledCollapse>
        </Col>
    </Row>

    <Row className="mt-3 mb-5" style={{cursor: 'pointer'}}>
        <Col>
        <div className="profile-tab" id="toggler4" onClick={() => this.toggle(4)}>{this.state.toggler4 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>} Order History</div>
        <UncontrolledCollapse toggler="toggler4">
        <Card className="collapse-box">
            <CardBody>
               <CreditPartnerOrderHistory orderHistoryData={this.props.phistoryData} />
            </CardBody>
        </Card>
        </UncontrolledCollapse>
        </Col>
    </Row>

    <Row noGutters={true} className="mb-5">
        <Col xs="12"><img src={logo} className="tthomelogo ml-0 ml-md-5" style={{float: 'right'}}/></Col>
    </Row>
    
    <ModalWishlistComponent confirmModal={this.state.testModal} togglemodalEnroll={this.testToggleModal} className="trainee-modal cpDetailModal">
       {this.state.patient ? this.state.patient.map( pdata => {
           return <React.Fragment>
            <Row className="mb-2 mt-3">
                <Col md={2} className="text-center">
                    <Row>
                        <Col md={12}><b>Name:</b></Col>
                        <Col md={12}>{pdata.firstName} {pdata.lastName}</Col>
                    </Row>
                </Col>
                <Col md={2} xs={2} className="text-center"><b>Sample Name</b></Col>
                <Col md={6} xs={7} className="text-center"><b>Test Name</b></Col>
                <Col md={2} xs={3} className="text-center"><b>Amount</b></Col>
            </Row>
            { pdata ? pdata.testList.map( testarr => {
                return <React.Fragment>
                {testarr ? testarr.testListArr.map( samplearr => {
                    return <React.Fragment>
                        <Row className='mt-3'>
                            <Col md={{size: 2, offset: 2}} xs={2} className="text-center">{samplearr.sample.sampleName}</Col>
                            <Col md={6} xs={7} className="text-center">{samplearr.testName}</Col>
                            {samplearr.price ?
                            <Col md={2} xs={3} className="text-center">&#8377; {(samplearr.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</Col>
                            : null }
                        </Row>
                        </React.Fragment>
                }) : null }
                </React.Fragment>
            }) : null }<hr/>
            
            { pdata.testList ? 
              <React.Fragment>
              <Row className="mt-3 mb-3">
                  <Col md={{size: 6, offset: 4}} className="text-center"><b>Total:</b></Col>
                  <Col md={2} className="text-center"><b>&#8377; {(this.totalOfTestCharge(pdata.testList)).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</b>
                  </Col>
              </Row><hr/>
              </React.Fragment> 
            : null
            }

            </React.Fragment>
        }) : null }

        {this.props.user.address ?
        this.props.user.address.islogisticserviceable === 1 && this.props.lcdata && grandTotalCharge !== 0 && this.state.courierCost === false? 
        <p className="text-center mt-4" style={{fontWeight: 'bold'}}>Grand Total : &#8377; {(Number(grandTotalCharge)).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
        :  null
        : null }

        {this.props.user.address ?
            (this.props.user.address.islogisticserviceable === 0 && this.props.lcdata && grandTotalCharge !== 0) || 
            (this.props.user.address.islogisticserviceable === 1 && this.props.lcdata && grandTotalCharge !== 0 && this.state.courierCost === true)? 
            <p className="text-center mt-4" style={{fontWeight: 'bold'}}>Grand Total : &#8377; {grandTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p> 
            : null
        : null } 
        <hr/>

        {this.props.user.address && this.state.courierCost === false && this.props.user.address.islogisticserviceable === 1? 
            <React.Fragment>
            <p className="mt-4 ml-3 mr-3" style={{marginBottom: '0px'}}>The sample pick-up is requested from : </p>
            <p className="ml-3 mr-3" style={{fontWeight: 'bold', marginBottom: '0px'}}>{this.props.user.address.line1}, { " " }
            {this.props.user.address.line2 ? this.props.user.address.line2 : null} 
            {this.props.user.address.line2 ? ", " : null}  
            {this.props.user.address.city}, { " " } {this.props.user.address.pincode}.</p><hr/>
            </React.Fragment>
            : null
        } 
            
        {this.props.user.address && this.state.courierCost === false && this.props.user.address.islogisticserviceable === 1 ? 
        <p className="mt-4 ml-3 mr-3"><b>Note: </b>Sample will be pickup within 2 working days, between 4:00 PM - 7:00 PM.</p>
        : null
        }

        <p className="mt-4 ml-3 mr-3">Before confirming order please go through 
        <a href="https://tatatrustbucket.s3.ap-south-1.amazonaws.com/tatatrustspdf/Ordering+a+Test.pdf" target="_blank"> Terms & Conditions</a>.
        </p>

        <Row>
            <Col md={12} className="mt-3 text-center">
                <Button onClick={this.submitPatientTestDetails}  className="button-lightred" size="md" style={{width: '190px'}} >Confirm</Button>{' '}
            </Col>
        </Row>

    </ModalWishlistComponent>
    
</Container>
</React.Fragment>
)
}
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        error: state.error,
        success: state.success,
        userTypeId: state.userTypeId,
        isAuthenticated: state.token !== null,
        token: state.token,
        user: state.user,
        searchData: state.searchData,
        cpsuccess: state.cpsuccess,
        cpmessage: state.cpmessage,
        cperror: state.cperror,
        cplimiterror: state.cplimiterror,
        phistorysuccess: state.phistorysuccess,
        phistoryData: state.phistoryData,
        cphistorysuccess: state.cphistorysuccess,
        cphistoryData: state.cphistoryData,
        lpstatusdata: state.lpstatusdata,
        posuccess: state.posuccess,
        stsuccess: state.stsuccess,
        stdata: state.stdata,
        stestsuccess: state.stestsuccess,
        stestdata: state.stestdata,
        lcsuccess: state.lcsuccess,
        lcdata: state.lcdata,
        CBsuccess: state.CBsuccess,
        CBdata: state.CBdata,
        // isAuthenticated: state.token !== null,
        // authRedirectPath: state.auth.authRedirectPath
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onPatientSearch: (id,data) => dispatch(actions.searchPatient(id,data)),
        onPinVerification: (pincode) => dispatch(actions.pinVerification(pincode)),
        onBulkPatientSampleSubmit: (id,bulkPatientTestDetails,courierCost) => dispatch(actions.bulkPatientSampleDetails(id,bulkPatientTestDetails,courierCost)),
        oncreditpartnerOrderReset: () => dispatch(actions.creditpartnerOrderReset()),
        oncreditPartnerOrderHistoryData: (id) => dispatch(actions.creditPartnerOrderHistoryData(id)),
        onSampleType: () => dispatch(actions.sampleType()),
        onSampleTest: (id) => dispatch(actions.sampleTest(id)),
        onLogisticCost: () => dispatch(actions.logisticCost()),
        //oncreditPartnerPatientOrderData: (id) => dispatch(actions.creditPartnerPatientOrderData(id)),
        //oncreditPartnerPatientStatus: () => dispatch(actions.creditPartnerPatientStatus()),
        onCreditBalance: (id) => dispatch(actions.creditBalance(id)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CreditPartnerHome);

