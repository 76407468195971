import React, {Component} from 'react';
import { Container, Table } from 'reactstrap';
import { Spinner, Row, Col } from 'reactstrap';
import { InputGroup, InputGroupText, InputGroupAddon, Input } from 'reactstrap';
import Footer from '../../components/footer/footer';
import TitlebarComponent from '../../components/navbar/titlebar';
import Navbar from '../../components/navbar/navbar';
import HomeApi from '../../services/API/HomeApi';
import SubFooter from '../../components/subFooter/subFooter';
import { sortByColumn } from '../functions';
import PaginationComponent from '../../components/pagination/pagination';


class Antibodies extends Component {
    constructor(props) {
        super(props);
        this.state = {
          antibodytableheader: [],
          antibodytabledata: [],
          loading: false,
          filter: null,
          toggle: false,
          activeColumn: 0,
          lastActiveColumn: 0,
          currentPage: 0,
        }
        this.pageSize = 10;
    }

    componentDidMount(){
      HomeApi.getTestListContent().then(res => {
        let newArray = res[0].antibodydata;
        newArray.sort(function(a, b) {
          var nameA = a.antibody_name.toUpperCase(); 
          var nameB = b.antibody_name.toUpperCase(); 
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }        
          return 0;
        });

        this.setState({ 
            antibodytableheader: res[0].antibodyheaders,
            antibodytabledata: newArray,
            loading: false,
            filter: null
         });
      });
    }

    updateSearch = (inputValue) => {
      let filter = this.state.filter;

      this.setState({
          filter: inputValue
      });
    }

    handleSearchChange = (event) => {
        this.updateSearch(event.target.value);
    }

    filter = (data) => {
      if (!this.state.filter) {
        return data;
      }
        return data.filter((data) => data.antibodydata_id.toString().indexOf(this.state.filter) >= 0 ||
                                     data.antibody_name.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0 ||
                                     data.antibody_clone.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0 ||
                                     data.antibody_company.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0
    )
    }

    handleClick = (title, key) => {
      let arrayCopy = [...this.state.antibodytabledata];
    if(key === 0 || key === 2 || key === 4){
      if (this.state.activeColumn === key) {
        let toggle = !this.state.toggle
        this.setState({
          toggle: toggle,
          activeColumn: key,
          antibodytabledata: sortByColumn(arrayCopy, title, toggle)
        })
      } else {
        this.setState({
          activeColumn: key,
          antibodytabledata: sortByColumn(arrayCopy, title, false)
        })
      }
    }
    }

    handlePatientTrackClick = (e, index) => {
    
      e.preventDefault();
  
      this.setState({
        currentPage: index
      });
      
    }


    render() {
      const { currentPage } = this.state;
      const paginationArray = [];
      const paginationNumberArray = [];

      if(this.state.antibodytabledata) {
        this.state.antibodytabledata.map((data) => {
          return paginationNumberArray.push(data) 
        })

        this.pagesCount = Math.ceil(paginationNumberArray.length / this.pageSize);

        paginationNumberArray.map((el, key) => {
          el["srno"] = key + 1;
        })
      }

      let isAuthenticated = localStorage.getItem('token') ? true : false;

        let list = <Spinner animation="border" variant="danger" />
        if (!this.state.loading) {
            list = <Table striped>
            <thead>
              <tr>
                {this.state.antibodytableheader ? this.state.antibodytableheader.map((header) => {
                 return <th>{header.antibodyheader_name}</th>
                }) : null }
              </tr>
            </thead>
            <tbody>
              {this.state.antibodytabledata ? this.filter(this.state.antibodytabledata).slice(
              currentPage * this.pageSize,
              (currentPage + 1) * this.pageSize
            ).map((data, key) => {
                  return  <tr>
                            <td>{key + 1 + currentPage * this.pageSize}</td>
                            <td>{data.antibody_name}</td>
                            <td>{data.antibody_clone}</td>
                            <td>{data.antibody_company}</td>
                          </tr>
                }) : <Spinner color="danger" />
              }
            </tbody>
          </Table>
        }
        return(
          <React.Fragment>
            <TitlebarComponent loggedinpatient={isAuthenticated} />
               <Navbar />
               <Container style={{marginBottom: '210px'}}>
               <Row className="mt-5">
                 <Col><h2>List of Antibodies</h2>
                 </Col>
               </Row>
               <Row className="mt-5">
                 <Col md="3">
                 <InputGroup size="lg">
                  <InputGroupAddon addonType="prepend">Search</InputGroupAddon>
                  <Input value={this.state.filter} onChange={this.handleSearchChange} type="text" className="coursesSearch"/>
                </InputGroup>
                 </Col>
               </Row>
               <Row className="mt-5 mb-5">
                 <Col className="text-center">
                 {list} 
                 </Col>
               </Row>
            <PaginationComponent currentPage={this.state.currentPage} handleClick={this.handlePatientTrackClick} pagesCount={this.pagesCount} />
             </Container>
             {/* <hr/>
             <SubFooter /> */}
               <Footer />
          </React.Fragment>
              
              
        )
    }
}

export default Antibodies;

