import React, { Component } from 'react';
import { Row } from "reactstrap";
import logo from '../../assets/TataTrustsNewLogo.png';
import './navbar.css';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

class NavbarComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            activeTabClassName: window.location.pathname,
            openMenu: false
        };
    }
    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleMobileMenu = () => {
        this.setState({
            openMenu: !this.state.openMenu
        });
    }


    render() {
        let isAuthenticated = localStorage.getItem('userTypeId') !== null;
        let userTypeId = localStorage.getItem('userTypeId');


        return ( <
                React.Fragment >
                <
                nav class = "menu"
                onClick = { this.toggleMobileMenu } >
                <
                ol className = { this.state.openMenu ? 'mobile_menu' : '' } > {
                    userTypeId === "2" ?
                    <
                    li class = "menu-item" > < a href = "/patientHome"
                    className = {
                        (this.state.activeTabClassName === "/patientHome") ? "active" : ""
                    } > Home < /a></li >
                    :
                        null
                } {
                    userTypeId === "1" ?
                        <
                        li class = "menu-item" > < a href = "/creditpartnerHome"
                    className = {
                            (this.state.activeTabClassName === "/creditpartnerHome") ? "active" : ""
                        } > Home < /a></li >
                        : null
                } {
                    userTypeId === "3" ?
                        <
                        li class = "menu-item" > < a href = "/traineeHome"
                    className = {
                            (this.state.activeTabClassName === "/traineeHome") ? "active" : ""
                        } > Home < /a></li >
                        : null
                } <
                li class = "menu-item" > < a href = "/"
                className = {
                    (this.state.activeTabClassName === "/") ? "active" : ""
                } > About Us < /a> <
                ol class = "sub-menu" >
                <
                li class = "menu-item" > < a href = "/aboutCOP" > About COP < /a></li >
                <
                /ol> < /
                li > <
                li class = "menu-item" >
                <
                a href = "/joinTeam"
                className = {
                    (this.state.activeTabClassName === "/joinTeam") || (this.state.activeTabClassName === "/careers") ? "active" : ""
                } > Team < /a> < /
                li > <
                li class = "menu-item" >
                <
                a href = "#"
                className = {
                    (this.state.activeTabClassName === "/testslist") || (this.state.activeTabClassName === "/antibodies") ? "active" : ""
                } > Lab Services < /a> <
                ol class = "sub-menu" >
                <
                li class = "menu-item" > < a href = "/testslist" > List of Tests Offered < /a></li >
                <
                li class = "menu-item" > < a href = "/antibodies" > List of Antibodies < /a></li > {!isAuthenticated ?
                    <
                    li class = "menu-item" > < a href = "/register"
                    dis > Order a Test < /a></li >
                    :
                        null
                } <
                /ol> < /
                li > {
                    (userTypeId === "3" || userTypeId === null) ?
                    <
                    li class = "menu-item" >
                    <
                    a href = "/trainingAcademy"
                    className = {
                        (this.state.activeTabClassName === "/trainingAcademy") ? "active" : ""
                    } > Training Academy < /a> {
                    /* <ol class="sub-menu">
                            <li class="menu-item"><a href="/courses-offered">Courses Offered</a></li>
                            <li class="menu-item"><a href="#0">Timetable</a></li>
                            {!isAuthenticated ?
                            <li class="menu-item"><a href="/login">Trainee Sign In</a></li>
                            : null
                            }
                          </ol> */
                } < /li> : null
            } { /* <li class="menu-item"><a href="#0">Accreditations</a></li> */ } <
            li class = "menu-item" >
            <
            a href = "#0"
        className = {
                (this.state.activeTabClassName === "/researchMaterial") ? "active" : ""
            } > Insights < /a>  <
            ol class = "sub-menu" > { /* <li class="menu-item"><a href="#0">Case of the Week</a></li> */ } <
            li class = "menu-item" > < a href = "/researchMaterial" > Research Material < /a></li >
            <
            /ol> < /
            li > <
            li class = "menu-item" > < a href = "/contactUs"
        className = {
                (this.state.activeTabClassName === "/contactUs") ? "active" : ""
            } > Contact Us < /a></li >

            <
            /ol> < /
            nav > {
                /* <Row noGutters={true}>
                          <div class="ticker-wrap">
                <div class="ticker">
                  <div class="ticker__item">“Dear Doctor / Patient,</div>
                  <div class="ticker__item">In these difficult times of COVID-19 related lockdown, many of our colleagues are unable to reach the workplace. Courier facilities are also not available.</div>
                  <div class="ticker__item">Hence, our laboratory will not be able to accept samples currently.  We regret the inconvenience caused to you.</div>
                  <div class="ticker__item">We will let you know once we are functional again.      Thank you.” </div>
                   
                </div>
                </div></Row> */
            } <
            /React.Fragment>
    )
}
}

export default NavbarComponent;