import React, { Component } from "react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import SubFooter from "../../components/subFooter/subFooter";
import HomeApi from "../../services/API/HomeApi";
import TitlebarComponent from "../../components/navbar/titlebar";
import "./aboutCOP.css";

class AboutCOP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutCOPContent: null
    };
  }

  componentDidMount() {
    HomeApi.getAboutCOPContent().then(data => {
      this.setState({
        aboutCOPContent: data
      });
    });
  }

  render() {
    //console.log(this.state.aboutCOPContent);

    let isAuthenticated = localStorage.getItem("token") ? true : false;
    return (
      <React.Fragment>
        <TitlebarComponent loggedinpatient={isAuthenticated} />
        <Navbar />
        <Container>
          {this.state.aboutCOPContent
            ? this.state.aboutCOPContent.map(data => {
                return data.id === 1 ? (
                  <Row className="mt-5">
                    <Col xs={12} md={6} className="mb-0">
                      {data.Image
                        ? data.Image.map(img => {
                            return (
                              <Card className="aboutCOPImageCard">
                                <img src={img.url} className="aboutCOPImage" />
                              </Card>
                            );
                          })
                        : null}
                    </Col>
                    <Col xs={12} md={6}>
                      <h3
                        style={{
                          marginLeft: "3px",
                          textAlign: "justify",
                          color: "rgb(236, 95, 87)"
                        }}
                      >
                        {data.Title}
                      </h3>
                      <br />

                      <p style={{ marginLeft: "3px", textAlign: "justify" }}>
                        {data.Description}
                      </p>
                      {/* <p style={{marginLeft: '35px'}}>{data.Description2}</p> */}
                    </Col>
                    {/* <Row noGutters={true}> */}
                    <Col xs={12} md={12} className="mt-1">
                      <p className="Paraalign" style={{ textAlign: "justify" }}>
                        {data.Description2}
                      </p>
                    </Col>
                    {/* </Row> */}
                  </Row>
                ) : data.id === 2 ? (
                  <Row className="mt-5 pb-3">
                    <Col xs={12} md={6} className="mb-5">
                      <h3
                        style={{
                          textAlign: "justify",
                          color: "rgb(236, 95, 87)"
                        }}
                        className="Paraalign"
                      >
                        {data.Title}
                      </h3>
                      <br />
                      <p style={{ textAlign: "justify" }} className="Paraalign">
                        {data.Description}
                      </p>
                    </Col>
                    <Col xs={12} md={6}>
                      {data.Image
                        ? data.Image.map(img => {
                            return (
                              <Card className="aboutCOPImageCard">
                                <img src={img.url} className="aboutCOPImage" />
                              </Card>
                            );
                          })
                        : null}
                    </Col>
                  </Row>
                ) : null;
              })
            : null}
        </Container>
        {/* <hr/>
               <SubFooter /> */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default AboutCOP;
