import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';
import PatientRegisterSuccess from './patientRegisterSuccess';
import PatientOTPSuccess from './patientOTPSuccess';
import './patient.css';

class PatientRequestOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
        form: this.defaultForm()
      };
  }

  defaultForm = () => {
    return {
        mobile: '',
        countryCode: '91',
    }
  }
  
  handleOtpRequest(e){
    let validationMessages = [];

    if (e.target.type === "number") {
      e.target.value = e.target.value.slice(0,10);
    } 
    
    if(e.target.type === "number" && e.target.value.length < 10){
      validationMessages.push("Required 10 digits number, match requested format.");
      this.setState({validationMessages});
    }
    else {
      validationMessages = [];
      this.setState({validationMessages});
    }

    const updatedForm = {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
      
      this.setState({form: updatedForm, validationMessages});
  }

  otpRequestSubmitHandler = (event) => {
    //console.log("OTP Request clicked!");
    event.preventDefault();
    
    const updatedPatientForm = {
        ...this.state.form,
    }
    if(this.state.validationMessages.length === 0){
      this.props.onOtpRequest(updatedPatientForm);
    }
  }



render() {

//   let otpSuccess = null;
//   if(this.props.success) {

//     otpSuccess = <PatientOTPSuccess />  
  
// }


return (
    <div class="container">
      <div class="row">
          <div class="col-md-6 offset-md-3 text-center">
            <div className="container-box text-center">
              <div class="logo">
                <img src={logo} className="tthomelogo"/>
              </div>
              {/* <h4>Centre for Oncopathology </h4> */}
              {this.props.success ? 
              <PatientOTPSuccess message={this.props.success} mobile={this.state.form.mobile} countryCode={this.state.form.countryCode}/> :
              <React.Fragment>
              <p className="red-font request-otp mt-5">Please enter your mobile number for new registration.</p>
              
              <div className="login-box-inner">
                <form onSubmit={this.otpRequestSubmitHandler}>
                <Row>
                  <Col md={2} style={{paddingLeft: '5px', paddingRight: '5px'}}>
                  <div class="form-group">
                      <input required class="form-control" style={{height: '60px'}} name="countryCode" type="text" value={this.state.form.countryCode} onChange={(event) => this.handleOtpRequest(event)}/>
                  </div>
                  </Col>
                  <Col>
                  <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="mobile" name="mobile" type="number" pattern={0-9} placeholder="Patient Mobile No." 
                      value={this.state.form.mobile} onChange={(event) => this.handleOtpRequest(event)} />
                      <label for="mobile" className="patientLabel">Patient Mobile No.</label>
                      <p className="red-font mt-3">{this.state.validationMessages}</p>
                    </span>
                  </div>
                  </Col>
                </Row>
                {/* <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="mobile" name="mobile" type="number" pattern={0-9} placeholder="Patient Mobile No." 
                      value={this.state.form.mobile} onChange={(event) => this.handleOtpRequest(event)} />
                      <label for="mobile">Patient Mobile No.</label>
                      <p className="red-font mt-3">{this.state.validationMessages}</p>
                    </span>
                </div>
                <div class="form-group">
                    <span class="has-float-label">
                      <input required class="form-control" id="countryCode" name="countryCode" type="text" placeholder="Country Code of the Mobile No." value={this.state.form.countryCode} />
                      <label for="countryCode">Country Code</label>
                    </span>
                </div> */}

                  <Button className="button-lightred" size="lg" style={{width: '190px'}}>
                  Next
                  </Button>
                  {this.props.error ? 
                  <h5 className="mt-4" style={{color:'red'}}>{this.props.message}</h5>
                  : null
                  }

                </form>
              </div>
              </React.Fragment>
              }
            </div>
            
          </div>
      </div>
      {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
    </div>
);
}
}

const mapStateToProps = state => {
    return {
        userTypeId: state.userTypeId,
        success: state.success,
        message: state.message,
        error: state.error,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onOtpRequest: (patientForm) => dispatch(actions.patientOtpRequest(patientForm))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(PatientRequestOTP);