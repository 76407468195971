import * as actionTypes from './actionTypes';
import LoginRegisterApi from '../../services/API/LoginRegisterApi';
import HomeApi from '../../services/API/HomeApi';

export const loginStart = () => {
    return {
        type: actionTypes.LOGIN_START
    }
}

export const loginSuccess = (token, user, userTypeId) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        token: token,
        user: user,
        userTypeId: userTypeId,
        loading: false
    }
}

export const patientInvalidLogin = (invalidData) => {
    return {
        type: actionTypes.PATIENT_INVALID_DATA,
        invalidData: invalidData,
        loading: false
    }
}

export const loginFail = (error) => {
    return {
        type: actionTypes.LOGIN_FAIL,
        error: error
    }
}

export const signupSuccess = (success, message, error, invalidFlag) => {
    return {
        type: actionTypes.SIGNUP_SUCCESS,
        success: success,
        message: message,
        error: error,
        invalidFlag: invalidFlag,
        loading: false
    }
}

export const resetSuccess = (resetsuccess, resetmessage, reseterror) => {
    return {
        type: actionTypes.RESET_SUCCESS,
        resetsuccess: resetsuccess,
        resetmessage: resetmessage,
        reseterror: reseterror,
        loading: false
    }
}

export const resetFail = (error) => {
    return {
        type: actionTypes.RESET_FAIL,
        error: error
    }
}

export const patientSignupSuccess = (successflag, message, patientSignUpError) => {
    return {
        type: actionTypes.PATIENT_SIGNUP_SUCCESS,
        successflag: successflag,
        message: message,
        patientSignUpError: patientSignUpError,
        loading: false
    }
}

export const signupFail = (error) => {
    return {
        type: actionTypes.SIGNUP_FAIL,
        error: error
    }
}

export const userDataSuccess = (success, data) => {
    return {
        type: actionTypes.DATA_SUCCESS,
        success: success,
        data: data
    }
}

export const userDataFailure = (error) => {
    return {
        type: actionTypes.DATA_FAILURE,
        error: error,
    }
}

export const userEnrollDataSuccess = (enrollSuccess, enrollData) => {
    return {
        type: actionTypes.ENROLL_DATA_SUCCESS,
        enrollSuccess: enrollSuccess,
        enrollData: enrollData
    }
}

export const userEnrollDataFailure = (error) => {
    return {
        type: actionTypes.ENROLL_DATA_FAILURE,
        error: error,
    }
}

export const userWishlistDataSuccess = (wishlistSuccess, wishlistData) => {
    return {
        type: actionTypes.WISHLIST_DATA_SUCCESS,
        wishlistSuccess: wishlistSuccess,
        wishlistData: wishlistData
    }
}

export const userWishlistDataFailure = (error) => {
    return {
        type: actionTypes.WISHLIST_DATA_FAILURE,
        error: error,
    }
}

export const patientHistoryDataSuccess = (success, error, phistoryData) => {
    return {
        type: actionTypes.PATIENT_HISTORY_DATA_SUCCESS,
        phistorysuccess: success,
        phistoryerror: error,
        phistoryData: phistoryData
    }
}

export const patientHistoryDataFailure = (error) => {
    return {
        type: actionTypes.PATIENT_HISTORY_DATA_FAILURE,
        error: error,
    }
}

export const creditPartnerOrderPatientHistoryDataSuccess = (cphistorysuccess, cphistoryerror, cphistoryData) => {
    return {
        type: actionTypes.CREDIT_PARTNER_ORDER_PATIENT_HISTORY_DATA_SUCCESS,
        cphistorysuccess: cphistorysuccess,
        cphistoryerror: cphistoryerror,
        cphistoryData: cphistoryData
    }
}

export const creditPartnerOrderPatientHistoryDataFailure = (error) => {
    return {
        type: actionTypes.CREDIT_PARTNER_ORDER_PATIENT_HISTORY_DATA_FAILURE,
        error: error,
    }
}

export const creditPartnerOrderSuccess = (cpsuccess, cperror, cplimiterror, cpmessage) => {
    return {
        type: actionTypes.CREDIT_PARTNER_ORDER_SUCCESS,
        cpsuccess: cpsuccess,
        cperror: cperror,
        cplimiterror: cplimiterror,
        cpmessage: cpmessage
    }
}

export const creditPartnerOrderFailure = (cperror) => {
    return {
        type: actionTypes.CREDIT_PARTNER_ORDER_FAILURE,
        cperror: cperror,
    }
}

export const creditpartnerOrderReset = () => {
    return {
        type: actionTypes.CREDIT_PARTNER_ORDER_RESET
    }
}

export const searchPatientSuccess = (success, searchData) => {
    return {
        type: actionTypes.SEARCH_DATA_SUCCESS,
        success: success,
        searchData: searchData
    }
}

export const searchPatientFailure = () => {
    return {
        type: actionTypes.SEARCH_DATA_FAILURE,
    }
}

export const patientOrderSuccess = (pordersuccess, pordermessage) => {
    return {
        type: actionTypes.PATIENT_ORDER_SUCCESS,
        pordersuccess: pordersuccess,
        pordermessage: pordermessage
    }
}

export const patientOrderFailure = (pordererror) => {
    return {
        type: actionTypes.PATIENT_ORDER_FAILURE,
        pordererror: pordererror,
    }
}

export const patientOrderReset = () => {
    return {
        type: actionTypes.PATIENT_ORDER_RESET
    }
}

export const courseDataSuccess = (coursedata) => {
    return {
        type: actionTypes.COURSE_DATA_SUCCESS,
        coursedata: coursedata,
    }
}

export const courseDataFailure = (error) => {
    return {
        type: actionTypes.COURSE_DATA_FAILURE,
        error: error
    }
}

export const courseEWSuccess = (courseEWdata) => {
    return {
        type: actionTypes.COURSE_EW_DATA_SUCCESS,
        courseEWdata: courseEWdata,
    }
}

export const courseEWFailure = (courseEWdataerror) => {
    return {
        type: actionTypes.COURSE_EW_DATA_FAILURE,
        courseEWdataerror: courseEWdataerror
    }
}

export const patientOrderStatusSuccess = (psuccess, pstatusdata, lpstatusdata) => {
    return {
        type: actionTypes.PATIENT_ORDER_STATUS_SUCCESS,
        psuccess: psuccess,
        pstatusdata: pstatusdata,
        lpstatusdata: lpstatusdata
    }
}

export const CPpatientOrderStatusSuccess = (psuccess, pstatusdata, lpstatusdata) => {
    return {
        type: actionTypes.CP_PATIENT_ORDER_STATUS_SUCCESS,
        psuccess: psuccess,
        pstatusdata: pstatusdata,
        lpstatusdata: lpstatusdata
    }
}

export const creditpartnerPatientOrderDetailsSuccess = (posuccess, porderData) => {
    return {
        type: actionTypes.CREDIT_PARTNER_PATIENT_ORDER_DETAILS_SUCCESS,
        posuccess: posuccess,
        porderData: porderData
    }
}

export const patientOrderStatusFailure = (error) => {
    return {
        type: actionTypes.PATIENT_ORDER_STATUS_FAILURE,
        error: error,
    }
}

export const careerSuccess = (careerMessage) => {
    return {
        type: actionTypes.CAREER_SUCCESS,
        careerMessage: careerMessage
    }
}

export const careerFailure = (error) => {
    return {
        type: actionTypes.CAREER_FAILURE,
        error: error,
    }
}

export const orderCompleteSuccess = (ocsuccess, ocmessage) => {
    return {
        type: actionTypes.OC_SUCCESS,
        ocsuccess: ocsuccess,
        ocmessage: ocmessage
    }
}

export const orderCompleteFailure = (error) => {
    return {
        type: actionTypes.OC_FAILURE,
        error: error,
    }
}

export const apSearchSuccess = (apsearchsuccess, apsearchdata) => {
    return {
        type: actionTypes.APSEARCH_SUCCESS,
        apsearchsuccess: apsearchsuccess,
        apsearchdata: apsearchdata
    }
}

export const apSearchFailure = (error) => {
    return {
        type: actionTypes.APSEARCH_FAILURE,
        error: error,
    }
}

export const apSaveSuccess = (apsavesuccess, apsavemessage) => {
    return {
        type: actionTypes.APSAVE_SUCCESS,
        apsavesuccess: apsavesuccess,
        apsavemessage: apsavemessage
    }
}

export const apSaveFailure = (error) => {
    return {
        type: actionTypes.APSAVE_FAILURE,
        error: error,
    }
}

export const logisticCostSuccess = (lcsuccess, lcdata) => {
    return {
        type: actionTypes.LOGISTIC_COST_SUCCESS,
        lcsuccess: lcsuccess,
        lcdata: lcdata
    }
}

export const logisticCostFailure = (error) => {
    return {
        type: actionTypes.LOGISTIC_COST_FAILURE,
        error: error,
    }
}

export const sampleTypeSuccess = (stsuccess, stdata) => {
    return {
        type: actionTypes.SAMPLE_TYPE_SUCCESS,
        stsuccess: stsuccess,
        stdata: stdata
    }
}

export const sampleTypeFailure = (error) => {
    return {
        type: actionTypes.SAMPLE_TYPE_FAILURE,
        error: error,
    }
}

export const sampleTestSuccess = (stestsuccess, stestdata) => {
    return {
        type: actionTypes.SAMPLE_TEST_SUCCESS,
        stestsuccess: stestsuccess,
        stestdata: stestdata
    }
}

export const sampleTestFailure = (error) => {
    return {
        type: actionTypes.SAMPLE_TEST_FAILURE,
        error: error,
    }
}

export const getPAddSuccess = (getPAsuccess, getPAdata) => {
    return {
        type: actionTypes.GET_PA_SUCCESS,
        getPAsuccess: getPAsuccess,
        getPAdata: getPAdata
    }
}

export const getPAddFailure = (error) => {
    return {
        type: actionTypes.GET_PA_FAILURE,
        error: error,
    }
}

export const editOrderSuccess = (editordersuccess, editordermessage) => {
    return {
        type: actionTypes.EDIT_ORDER_SUCCESS,
        editordersuccess: editordersuccess,
        editordermessage: editordermessage
    }
}

export const editOrderFailure = (error) => {
    return {
        type: actionTypes.EDIT_ORDER_FAILURE,
        error: error,
    }
}

export const editAddSuccess = (addEditsuccess, addEditMessage) => {
    return {
        type: actionTypes.EDIT_ADD_SUCCESS,
        addEditsuccess: addEditsuccess,
        addEditMessage: addEditMessage
    }
}

export const editAddFailure = (error) => {
    return {
        type: actionTypes.EDIT_ADD_FAILURE,
        error: error,
    }
}

export const patientUpdatedAddSuccess = (patientupdatedAddsuccess, patientupdatedAddData) => {
    return {
        type: actionTypes.PATIENT_UPDATED_ADD_SUCCESS,
        patientupdatedAddsuccess: patientupdatedAddsuccess,
        patientupdatedAddData: patientupdatedAddData
    }
}

export const patientUpdatedAddFailure = (error) => {
    return {
        type: actionTypes.PATIENT_UPDATED_ADD_FAILURE,
        error: error,
    }
}

export const login = (username, password, token) => {
    return dispatch => {
        dispatch(loginStart());
        LoginRegisterApi.reduxLogin(username, password, token);
    }
}

export const patientLogin = (patientLoginForm) => {
    return dispatch => {
        dispatch(loginStart());
        LoginRegisterApi.reduxPatientLogin(patientLoginForm);
    }
}

export const signup = (formData) => {
    return dispatch => {
        dispatch(loginStart());
        LoginRegisterApi.reduxSignup(formData);
    }
}

export const patientsignup = (patientData) => {
    return dispatch => {
        dispatch(loginStart());
        LoginRegisterApi.reduxPatientSignUp(patientData);
    }
}

export const userCheck = (userCheckFormData) => {
    return dispatch => {
        dispatch(loginStart());
        LoginRegisterApi.reduxUserCheck(userCheckFormData);
    }
}

export const patientOtpRequest = (patientForm) => {
    return dispatch => {
        dispatch(loginStart());
        LoginRegisterApi.reduxPatientOtpRequest(patientForm);
    }
}

export const patientForgotPassword = (patientForm) => {
    return dispatch => {
        dispatch(loginStart());
        LoginRegisterApi.reduxPatientForgotPassword(patientForm);
    }
}

export const patientResetPassword = (patientForm) => {
    return dispatch => {
        dispatch(loginStart());
        LoginRegisterApi.reduxPatientResetPassword(patientForm);
    }
}

export const setUserType = (userId) => {
    return {
        type: actionTypes.SET_USER_TYPE,
        userTypeId: userId
    }
}

export const getReports = (dipatch) => {
    return dispatch => {
        LoginRegisterApi.reduxgetReports();
    }
}

export const pinSuccess = (pinsuccess, pindata, pinfailure) => {
    return {
        type: actionTypes.PIN_SUCCESS,
        pinsuccess: pinsuccess,
        pindata: pindata,
        pinfailure: pinfailure,
    }
}

export const pinFailure = (pinfailure) => {
    return {
        type: actionTypes.PIN_FAILURE,
        pinfailure: pinfailure,
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userTypeId');
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            //dispatch(logout());
        } else {
            const user = JSON.parse(localStorage.getItem('user'));
            const userTypeId = localStorage.getItem('userTypeId');
            dispatch(loginSuccess(token, user, userTypeId));
        }
    }
}

export const patientTestSampleDetails = (patientTestDetails) => {
    return dispatch => {
        LoginRegisterApi.reduxTestSampleDetails(patientTestDetails);
    }
}

export const searchPatient = (id,data) => {
    return dispatch => {
        LoginRegisterApi.reduxSearchPatient(id,data);
    }
}

export const bulkPatientSampleDetails = (id,bulkPatientTestDetails,courierCost) => {
    return dispatch => {
        //dispatch(loginStart());
        LoginRegisterApi.reduxBulkTestSampleDetails(id,bulkPatientTestDetails,courierCost);
    }
}

export const pinVerification = (pincode) => {
    return dispatch => {
        LoginRegisterApi.reduxPinVerification(pincode);
    }
}


export const courseEnroll = (userId, cId) => {
    return dispatch => {
        LoginRegisterApi.reduxCourseEnroll(userId, cId);
    }
}

export const courseWishlist = (userId, cId) => {
    return dispatch => {
        LoginRegisterApi.reduxCourseWishlist(userId, cId);
    }
}

export const courseData = () => {
    return dispatch => {
        LoginRegisterApi.reduxcourseData();
    }
}

export const courseDataOpen = () => {
    return dispatch => {
        LoginRegisterApi.reduxcourseDataOpen();
    }
}

export const courseAndWishlist = (id) => {
    return dispatch => {
        LoginRegisterApi.reduxcourseAndWishlist(id);
    }
}

export const patientOrderList =  (id) => {
    return dispatch => {
        return LoginRegisterApi.reduxPatientOrderList(id);
    }
}

export const patientGetOrderStatus = (billId) => {
    return dispatch => {
        return LoginRegisterApi.reduxPatientGetOrderStatus(billId);
    }
}

export const CPpatientGetOrderStatus = (billId) => {
    return dispatch => {
        return LoginRegisterApi.reduxCPPatientGetOrderStatus(billId);
    }
}

export const patientGetOrderLogisticsStatus = (awbId) => {
    return dispatch => {
        return LoginRegisterApi.reduxPatientGetOrderLogisticsStatus(awbId);
    }
}

export const CPpatientGetOrderLogisticsStatus = (awbId) => {
    return dispatch => {
        return LoginRegisterApi.reduxCPPatientGetOrderLogisticsStatus(awbId);
    }
}

export const patientOrderData = (id) => {
    return (dispatch, getState) => {
        dispatch(patientOrderList(id)).then(() => {
          const ordersArr = getState().phistoryData;
            ordersArr.forEach(order => {
                if(order.awbId !== "") {
                // dispatch(patientOrderStatusSuccess(true))
                //     const logisticsStatusArr = getState().pstatusdata;
                //     //console.log(logisticsStatusArr);
                    dispatch(patientGetOrderLogisticsStatus(order.awbId))
                }
                
            });
        }).then(() => {
            const ordersArr = getState().phistoryData;
              ordersArr.forEach(order => {
                if(order.lhBillId !== 0) {
                //   dispatch(patientOrderStatusSuccess(true))
                //       const logisticsStatusArr = getState().pstatusdata;
                //       //console.log(logisticsStatusArr);
                  
                  dispatch(patientGetOrderStatus(order.lhBillId));
              }
              });
          });
      };
}

export const creditPartnerOrderList = (id) => {
    return dispatch => {
        return LoginRegisterApi.reduxCreditPartnerOrderList(id);
            
    }
}

export const creditPartnerOrderPatientsList = (ordersArr) => (dispatch) => {
    return Promise.all(ordersArr.map((order, index) => {
        return LoginRegisterApi.reduxCreditPartnerOrderPatientsList(order.id);
    }));
    // return dispatch => {
    //     return LoginRegisterApi.reduxCreditPartnerOrderPatientsList(id);
    // }
}

export const creditPartnerPatientOrderDetails = (patientOrder) => (dispatch) => {
    return Promise.all(patientOrder.map((order, index) => {
        return LoginRegisterApi.reduxCreditPartnerPatientOrderDetails(order.orderId, order.id)
    }));
}

export const creditPartnerOrderHistoryData = (id) => {
    return (dispatch, getState) => {
        dispatch(creditPartnerOrderList(id)).then(() => {
          const ordersArr = getState().phistoryData;
                dispatch(creditPartnerOrderPatientsList(ordersArr)).then(() => {
                    //this callback will run after getting patients for each order
                    const patientsArr = getState().cphistoryData;
                    const ordersArr = getState().phistoryData;
                    const patientsOrderArr = [].concat.apply([], getState().cphistoryData);
        
                    //console.log(patientsOrderArr);
                    dispatch(creditPartnerPatientOrderDetails(patientsOrderArr)).then(() => {
                        //this callback will run after getting order details for each patient

                        const porderData = getState().porderData;
                        //console.log(porderData);
                            porderData.forEach(porder => {
                            if(porder.lhBillId !== 0){
                            dispatch(CPpatientGetOrderStatus(porder.lhBillId));
                            }
                        });
                         //console.log("IT IS FINISHED");
                    });

                })
        }).then(() => {
            const ordersArr = getState().phistoryData;
              ordersArr.forEach(order => {
                if(order.awbNo !== null){
                dispatch(CPpatientGetOrderLogisticsStatus(order.awbNo))
                }
              });
          })
      };
}


// export const creditPartnerPatientOrderData = () => {
//     return (dispatch, getState) => {
//         const ordersArr = getState().phistoryData;
//         const patientsArr = getState().cphistoryData;
//         const patientsOrderArr = [].concat.apply([], getState().cphistoryData);
        
//         //console.log(patientsOrderArr);
        
//         if(ordersArr.length === patientsArr.length) {

              
            // dispatch(creditPartnerPatientOrderDetails(patientsOrderArr)).then((data) => {
            //     const porderData = getState().porderData;
            //     //console.log(porderData);
            //         porderData.forEach(porder => {
            //         dispatch(patientGetOrderStatus(porder.lhBillId));
            //     });
            //     // //console.log("IT IS FINISHED");
            // });
          
//         }
        
//       };
// }

// export const creditPartnerPatientStatus = () => {
//     return (dispatch, getState) => {
//         const porderData = getState().porderData;
//         //console.log(porderData);
//     }
// }

export const careerForm = (form) => {
    return dispatch => {
        LoginRegisterApi.reduxCareerForm(form);
    }
}

export const completeOrder = (orderId) => {
    return dispatch => {
        LoginRegisterApi.reduxCompleteOrder(orderId).then(() => {
            const user = JSON.parse(localStorage.getItem('user'));

            dispatch(patientOrderData(user.id));
          });
    }
}

export const adminPatientSearch = (name, mobile) => {
    return dispatch => {
        LoginRegisterApi.reduxAdminPatientSearch(name, mobile);
    }
}

export const adminSavePatientDetails = (id, data) => {
    return dispatch => {
        LoginRegisterApi.reduxAdminSavePatientDetails(id, data);
    }
}

export const PayNowOrder =  (orderId, courierCost,admin) => {
    return dispatch => {
        return LoginRegisterApi.reduxPayNowOrder(orderId, courierCost, admin);
    }
}

export const logisticCost =  () => {
    return dispatch => {
        return LoginRegisterApi.reduxLogisticCost();
    }
}

export const sampleType =  () => {
    return dispatch => {
        return LoginRegisterApi.reduxSampleType();
    }
}

export const sampleTest =  (id) => {
    return dispatch => {
        return LoginRegisterApi.reduxSampleTest(id);
    }
}

export const getPatientAddress =  (userId) => {
    return dispatch => {
        return LoginRegisterApi.reduxGetPatientAddress(userId);
    }
}

export const submitEditOrder =  (data) => {
    return dispatch => {
        return LoginRegisterApi.reduxSubmitEditOrder(data);
    }
}

export const patientEditAddress = (updatedAddress) => {
    return dispatch => {
        return LoginRegisterApi.reduxPatientEditAddress(updatedAddress);
    }
}


export const getPatientUpdatedAddress = (id) => {
    return dispatch => {
        return LoginRegisterApi.reduxGetPatientUpdatedAddress(id);
    }
}

// .then(() => {
//     const patientsArr = getState().cphistoryData;
    //     patientsArr[0].creditPartnerPatients.forEach(patient => {
    //         dispatch(creditPartnerPatientOrderDetails(order.awbNo))
    //   });
//   })

export const userForgotPassword = (username) => {
    return dispatch => {
        dispatch(loginStart());
        LoginRegisterApi.reduxUserForgotPassword(username);
    }
}

export const userForgotSuccess = (forgotsuccess, forgotdata) => {
    return {
        type: actionTypes.USER_FORGOT_SUCCESS,
        forgotsuccess: forgotsuccess,
        forgotdata: forgotdata
    }
}

export const userForgotFailure = (error) => {
    return {
        type: actionTypes.USER_FORGOT_FAILURE,
        error: error,
    }
}

export const creditBalance =  (id) => {
    return dispatch => {
        return LoginRegisterApi.reduxCreditBalance(id);
    }
}

export const creditBalanceSuccess = (CBsuccess, CBerror, CBdata) => {
    return {
        type: actionTypes.CREDIT_BALANCE_SUCCESS,
        CBsuccess: CBsuccess,
        CBerror: CBerror,
        CBdata: CBdata
    }
}

export const creditBalanceFailure = (error) => {
    return {
        type: actionTypes.CREDIT_BALANCE_FAILURE,
        error: error,
    }
}