import React, {Component} from 'react';
import { connect } from 'react-redux';
import TitlebarComponent from '../../components/navbar/titlebar';
import Navbar from '../../components/navbar/navbar';
import { Spinner, Row, Col, Container, Form, Table, UncontrolledCollapse, Button, CardBody, Card, Alert, FormGroup, Input, Label } from 'reactstrap';
import "react-picky/dist/picky.css";
import * as actions from '../../redux/actions/actions';
import HomeApi from '../../services/API/HomeApi';
import ModalComponent from '../../components/modal/modal';
import ModalWishlistComponent from '../../components/modal/modalwishlist';
import { convertToDateYYYYMMDD } from '../functions';
import logo from '../../assets/TataTrustsNewLogo.png';

class TraineeHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enrolltesttableheader: [
                {table_header: "Course Id"},
                {table_header: "Course Name"},
                {table_header: "Start Date"},
                {table_header: "Duration"},
                {table_header: "Classroom / Webinar"},
            ],
            wishlisttesttableheader: [
                {table_header: "Course Id"},
                {table_header: "Course Name"},
                {table_header: "Start Date"},
                {table_header: "Duration"},
                {table_header: "Classroom / Webinar"},
                {table_header: "Enroll"}
            ],
            showModal: false,
            disable: true,
            disableKey: null,
            form: this.defaultForm(),
            selectedId: '',
            confirmModal: false,
            courseName: '',
            visible: false,
        }
    }

    defaultForm = () => {
        return {
            userTypeId: this.props.userTypeId ? this.props.userTypeId : 3,
            firstName: '',
            lastName: '',
            contactNo: '',
            gender:'',
            emailId: '',
            // address: '',
            // dob: new Date(),
            // state: '',
            // city: '',
            // pincode:'',
            // password: ''
        }
    }

    profileinputChangedHandler(e) {
        const updatedprofileForm = {
          ...this.state.form,
          [e.target.name]: e.target.value
        }
        let validationMessages = [];
        this.setState({
          form: updatedprofileForm, 
          validationMessages
        });
          
    }

    togglemodal = () => {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
    }

    componentDidMount(){

        let id = this.props.user.id;
        this.props.onCourseAndWishlist(id);
        
        let isAuthenticated = localStorage.getItem('token') ? true : false;
        if(isAuthenticated) {
            this.props.oncourseData();
        }
        else{
            this.props.oncourseDataOpen();
        }
        
      }

      componentWillReceiveProps(nextProps) {
        if(nextProps.enrollSuccess){
           let id = this.props.user.id;
           this.props.onCourseAndWishlist(id);
        }
      }
    
      toggle = (tabno) => {
        switch(tabno) {
            case 0:
                this.setState(prevState => ({
                    toggler: !prevState.toggler
                }));
                break;
            case 1:
                this.setState(prevState => ({
                    toggler1: !prevState.toggler1
                }));
                break;
            case 2:
                this.setState(prevState => ({
                    toggler2: !prevState.toggler2
                }));
                break;
            case 3:
                this.setState(prevState => ({
                    toggler3: !prevState.toggler3
                }));
                break;
            case 4:
                this.setState(prevState => ({
                    toggler4: !prevState.toggler4
                }));
                break;
        }
    }

    enrollCourse = (event) => {
        event.preventDefault();
        
          let courseId = this.state.selectedId;
          let disableKey = this.state.disableKey;
          var obj = [courseId]
          //console.log(obj);
  
          this.props.onCourseEnrolled(this.props.user.id, obj);
  
          this.setState(prevState => ({
            confirmModal: !prevState.confirmModal,
            disable: disableKey
          }));

          this.setState({
            visible: true
          }, () => {
            window.setTimeout(() => {
              this.setState({
                visible: false
              })
            }, 5000)
          });
      }
  
      togglemodalEnroll = (id, courseName, key) => {
        this.setState(prevState => ({
          confirmModal: !prevState.confirmModal,
          selectedId: id,
          courseName: courseName,
          disableKey: key
        }));
      }

    render(){

    let isAuthenticated = localStorage.getItem('token') ? true : false;

return(
<React.Fragment>
<TitlebarComponent loggedinpatient={this.props.isAuthenticated ? true : false} />
<Navbar patientHome={this.props.isAuthenticated ? true : false} />
<Container>
    <Row className="mt-5">
        <Col>
            <h2>Welcome, {this.props.user.firstName + ' ' + this.props.user.lastName}</h2>
        </Col>
    </Row>
    {this.props.enrollSuccess ? 
        <Alert className="mt-4" color="success" isOpen={this.state.visible}>{this.props.enrollData}</Alert> : null
    } 
    <Row className="mt-5" style={{cursor: 'pointer'}}>
        <Col>
        <div className="profile-tab" id="toggler" onClick={() => this.toggle(0)}>{this.state.toggler ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>} Trainee Details </div>
        <UncontrolledCollapse toggler="toggler">
        <Card className="collapse-box">
            <CardBody>
                <Row>
                    {/* <Col md={12} className="text-right"><i class="fa fa-edit" onClick={this.togglemodal}></i></Col> */}
                    <Col md={6} className="mt-3"><b>Trainee Name:</b> {this.props.user.firstName + ' ' + this.props.user.lastName}</Col>
                    <Col md={6} className="mt-3"><b>Mobile No.: </b>{this.props.user.contactNo}</Col>
                    <Col md={6} className="mt-3"><b>Email Id:</b> {this.props.user.emailId}</Col><br/>
                    <Col md={6} className="mt-3"><b>Address Line 1:</b> {this.props.user.address.line1}</Col>
                    <Col md={6} className="mt-3"><b>City:</b> {this.props.user.address.city}</Col>
                    <Col md={6} className="mt-3"><b>State:</b> {this.props.user.address.state}</Col>
                    <Col md={6} className="mt-3"><b>Pincode:</b> {this.props.user.address.pincode}</Col>
                </Row>
            </CardBody>
        </Card>
        </UncontrolledCollapse>
        </Col>
    </Row>

    <Row className="mt-3" style={{cursor: 'pointer'}}>
        <Col>
        <div className="profile-tab" id="toggler1" onClick={() => this.toggle(1)}>{this.state.toggler1 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>} Courses Enrolled </div>
        <UncontrolledCollapse toggler="toggler1">
        <Card className="collapse-box">
            <CardBody>
                <Table className="text-center">
                    <thead>
                    <tr>
                        {this.state.enrolltesttableheader ? this.state.enrolltesttableheader.map((data) => {
                            return <th>{data.table_header}</th>
                        }) : <Spinner color="danger" />
                    }
                    </tr>
                    </thead>
                    <tbody>
                        {this.props.courseEWdata ? Object.values(this.props.courseEWdata.trainee.reduce((acc,cur)=>Object.assign(acc,{[cur.courseId]:cur}),{})).map((data) => {
                            return this.props.coursedata ? this.props.coursedata.map((cdata) => {
                                return (data.courseId === cdata.id)  ? 
                                <tr>
                                   <td>{cdata.id}</td>
                                   <td>{cdata.courseName}</td>
                                   <td>{convertToDateYYYYMMDD(cdata.startDate)}</td>
                                   <td>{cdata.duration} days</td>
                                   <td>Classroom</td>
                                </tr> 
                                : null
                            }) : null
                        }) : <Spinner color="danger" />
                    }
                    </tbody>
                </Table>
            </CardBody>
        </Card>
        </UncontrolledCollapse>
        </Col>
    </Row>

    <ModalComponent modal={this.state.modal} togglemodal={this.togglemodal} submit={this.submitHandler} title="Edit Patient Profile" className="patient-profile-modal">
        <Form onSubmit={this.submitHandler}>
                <FormGroup>
                <Label for="fname">First Name</Label>
                <Input type="text" value={this.state.form.firstName} name="firstName" placeholder="First Name" onChange={(event) => this.profileinputChangedHandler(event)} />
                </FormGroup>
                <Label for="lname">Last Name</Label>
                <FormGroup>
                <Input type="text" value={this.state.form.lastName} name="lastName" placeholder="Second Name" onChange={(event) => this.profileinputChangedHandler(event)} />
                </FormGroup>
                <FormGroup>
                <Label for="mobile">Mobile Number</Label>
                <Input type="text" value={this.state.form.contactNo} name="contactNo" placeholder="Mobile Number" onChange={(event) => this.profileinputChangedHandler(event)} />
                </FormGroup>
                <FormGroup>
                <Label for="email">Email</Label>
                <Input type="email" value={this.state.form.emailId} name="emailId" placeholder="Email Address" onChange={(event) => this.profileinputChangedHandler(event)}/>
                </FormGroup>
                <FormGroup>
                <Label for="exampleDate">Date of Birth</Label>
                <Input
                type="date"
                name="dob"
                id="exampleDate"
                placeholder="Date of Birth"
                onChange={(event) => this.profileinputChangedHandler(event)}
                />
            </FormGroup>
                <FormGroup tag="fieldset">
            <legend className="col-form-label">Gender :</legend>
            <Col>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="gender" value={this.state.form.gender} onChange={(event) => this.profileinputChangedHandler(event)}/>{' '}
                    Male
                </Label>
                </FormGroup>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="gender" value={this.state.form.gender} onChange={(event) => this.profileinputChangedHandler(event)}/>{' '}
                    Female
                </Label>
                </FormGroup>
                <FormGroup check >
                <Label check>
                    <Input type="radio" name="gender" value={this.state.form.gender} onChange={(event) => this.profileinputChangedHandler(event)}/>{' '}
                    Other
                </Label>
                </FormGroup>
                
            </Col>
            </FormGroup>
            <Button className="button-lightred mt-0" size="md" style={{width: '120px'}} >Submit</Button>{' '}
            <Button color="secondary" onClick={this.togglemodal}>Cancel</Button>

        </Form>
    </ModalComponent>

    <Row className="mt-3" style={{cursor: 'pointer'}}>
        <Col>
        <div className="profile-tab" id="toggler2" onClick={() => this.toggle(2)}>{this.state.toggler2 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>} Wishlist </div>
        <UncontrolledCollapse toggler="toggler2">
        <Card className="collapse-box">
            <CardBody>
                <Table className="text-center">
                    <thead>
                        <tr>
                            {this.state.wishlisttesttableheader ? this.state.wishlisttesttableheader.map((data) => {
                                return <th>{data.table_header}</th>
                            }) : <Spinner color="danger" />
                        }
                        </tr>
                    </thead>
                    <tbody>
                    {this.props.courseEWdata ? Object.values(this.props.courseEWdata.wishlist.reduce((acc,cur)=>Object.assign(acc,{[cur.courseId]:cur}),{})).map((data, key) => {
                        return this.props.coursedata ? this.props.coursedata.map((cdata) => {
                            return (data.courseId === cdata.id)  ? 
                            cdata.enrolled === true ?
                            null :
                            <tr>
                                <td>{cdata.id}</td>
                                <td>{cdata.courseName}</td>
                                <td>{convertToDateYYYYMMDD(cdata.startDate)}</td>
                                <td>{cdata.duration}</td>
                                <td>Webinar</td>
                                {this.props.userTypeId === "3" && cdata.enrolled === true ?
                                <td><p className="green-font">Enrolled</p></td> :
                                this.props.userTypeId === "3" ?
                                <td><Button disabled={this.state.disable === key} onClick={isAuthenticated ? () => this.togglemodalEnroll(cdata.id, cdata.courseName, key) : this.checkLoginTrainee}>Enroll</Button></td>
                            : null }
                            </tr> 
                            : null
                        }) : null
                    }) : <Spinner color="danger" />
                    }
                    </tbody>
                </Table>
            </CardBody>
        </Card>
        </UncontrolledCollapse>
        </Col>
    </Row>

    <Row className="mt-3" style={{cursor: 'pointer'}}>
        <Col>
        <div className="profile-tab" id="toggler3" onClick={() => this.toggle(3)}>{this.state.toggler3 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>} Certificates </div>
        <UncontrolledCollapse toggler="toggler3">
        <Card className="collapse-box">
            <CardBody>
            
            </CardBody>
        </Card>
        </UncontrolledCollapse>
        </Col>
    </Row>

    <Row className="mt-3 mb-5" style={{cursor: 'pointer'}}>
        <Col>
        <div className="profile-tab" id="toggler4" onClick={() => this.toggle(4)}>{this.state.toggler4 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>}Schedule of courses enrolled</div>
        <UncontrolledCollapse toggler="toggler4">
        <Card className="collapse-box">
            <CardBody>
           
            </CardBody>
        </Card>
        </UncontrolledCollapse>
        </Col>
    </Row>

    <Row noGutters={true} className="mb-5">
        <Col xs="12"><img src={logo} className="tthomelogo ml-0 ml-md-5" style={{float: 'right'}}/></Col>
    </Row>
    
    <ModalWishlistComponent confirmModal={this.state.confirmModal} togglemodalEnroll={this.togglemodalEnroll} className="trainee-modal">
        <h5>Are you sure you want to enroll for {this.state.courseName} course ?</h5>
        <Button color="success" onClick={this.enrollCourse}>Yes</Button>
        <Button color="danger" onClick={this.togglemodalEnroll} className="ml-3">No</Button>
    </ModalWishlistComponent>
</Container>
</React.Fragment>
)
}
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        error: state.error,
        enrollSuccess: state.enrollSuccess,
        enrollData:state.enrollData,
        userTypeId: state.userTypeId,
        isAuthenticated: state.token !== null,
        token: state.token,
        user: state.user,
        coursedata: state.coursedata,
        courseEWdata: state.courseEWdata
        // isAuthenticated: state.token !== null,
        // authRedirectPath: state.auth.authRedirectPath
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onPatientSearch: (id,data) => dispatch(actions.searchPatient(id,data)),
        onPinVerification: (pincode) => dispatch(actions.pinVerification(pincode)),
        onBulkPatientSampleSubmit: (id,bulkPatientTestDetails) => dispatch(actions.bulkPatientSampleDetails(id,bulkPatientTestDetails)),
        oncourseData: () => dispatch(actions.courseData()),
        onCourseEnrolled: (userId,cId) => dispatch(actions.courseEnroll(userId,cId)),
        onCourseAndWishlist: (id) => dispatch(actions.courseAndWishlist(id)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TraineeHome);
