import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as actions from '../../redux/actions/actions';
import { Button } from 'reactstrap';
import logo from '../../assets/TataTrustsNewLogo.png';
import './ForgotPassword.css';
import { connect } from 'react-redux';
import UserForgotSuccess from './ForgotPasswordSuccess';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      };
  }

  handleForgotPassword = (event) => {
    event.preventDefault();
    this.setState({
      username: event.target.value,
    });
  }

  forgotPasswordSubmitHandler = (event) => {
    event.preventDefault();
      this.props.onForgotPassword(this.state.username);
  }

render() {
return (
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 text-center">
        <div className="container-box text-center">
          <div class="logo">
            <img src={logo} className="tthomelogo"/>
          </div>
          {/* <h4>Centre for Oncopathology </h4> */}
          {this.props.forgotsuccess ? 
          <UserForgotSuccess username={this.state.username} forgotdata={this.props.forgotdata}/> :
          <React.Fragment>
          <h2>Forgot Password</h2>
          
          {/* <div className="register-text"><span>Already a member ? &nbsp;</span><span>
            <Link to="/login">Sign In.</Link></span></div> */}

          <div className="login-box-inner">
            <form onSubmit={this.forgotPasswordSubmitHandler}>
            <div class="form-group">
              <span class="has-float-label">
                <input required class="form-control" id="username" name="username" type="email" placeholder="Username"
                  value={this.state.username} onChange={(event) => this.handleForgotPassword(event)}/>
                <label for="username" className="patientLabel">Username</label>
              </span>
            </div>
            
            <Button className="button-lightred" size="lg" style={{width: '190px'}}>Next</Button>{' '}

            {/* <div className="register-text"><span>New User ? &nbsp;</span><span>
            <Link to="/register">Register here.</Link></span></div> */}
            </form>
          </div>
          </React.Fragment>
          }
        </div>
      </div>
    </div>
    {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
  </div>
);
}
}

const mapStateToProps = state => {
  return {
      userTypeId: state.userTypeId,
      forgotsuccess: state.forgotsuccess,
      forgotdata: state.forgotdata,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onForgotPassword: (username) => dispatch(actions.userForgotPassword(username))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);