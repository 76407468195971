import React, { Component } from "react";
import Navbar from "../../components/navbar/navbar";
import HomePageSection1 from "../homePageSection1/homeSection1";
import Footer from "../../components/footer/footer";
import SubFooter from "../../components/subFooter/subFooter";

import HomeApi from "../../services/API/HomeApi";
import { Spinner, Container } from "react-bootstrap";
import TitlebarComponent from "../../components/navbar/titlebar";
import TestsList from "../testsList/testsList";

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      loading: true,
      tatatruststitle: null,
      tatatrustssubtitle: null,
      content: null,
      abouttitle: null,
      aboutsubtitle: null,
      aboutcontent: [],
      servicecontent: [],
      hometeamtitle: null,
      hometeambuttontext: null,
      hometeamcontent: [],
      homekey: [],
      homevalue: []
    };
  }

  componentDidMount() {
    HomeApi.getHomeContent().then(res => {
      //console.log(res);
      if (res.message !== "Network Error") {
        this.setState({
          title: res[0].home_title,
          homevalue: res[0].homevalues,
          homekey: res[0].homekeys,
          tatatruststitle: res[0].tatatrusts_title,
          tatatrustssubtitle: res[0].tatatrusts_sub_title,
          content: res[0].home_content,
          loading: false
        });
      }
    });

    // HomeApi.getAboutUsContent().then( data => {
    //     //console.log(data);
    //     this.setState({
    //         abouttitle: data[1].aboutus_title,
    //         aboutsubtitle: data[1].aboutus_sub_title,
    //         aboutcontent: data[1].about_id,
    //         loading: false,
    //     });
    // });

    HomeApi.getServiceContent().then(data => {
      this.setState({
        servicecontent: data,
        loading: false
      });
    });

    // HomeApi.getHomeTeamContent().then( data => {
    //     //console.log(data);
    //     this.setState({
    //         hometeamtitle: data[0].hometeam_title,
    //         hometeambuttontext: data[0].hometeam_button_text,
    //         hometeamcontent: data[0].hometeam_newId,
    //         loading: false,
    //     });
    // });
  }

  render() {
    let isAuthenticated = localStorage.getItem("token") ? true : false;

    let home = <Spinner animation="border" variant="danger" />;
    if (!this.state.loading) {
      home = (
        <React.Fragment>
          <HomePageSection1 {...this.state} />
        </React.Fragment>
      );
    }
    return this.state.title !== null &&
      this.state.homevalue.length !== 0 &&
      this.state.homekey.length !== 0 &&
      this.state.tatatruststitle !== null &&
      this.state.tatatrustssubtitle !== null &&
      this.state.content !== null ? (
      <React.Fragment>
        <TitlebarComponent loggedinpatient={isAuthenticated} />
        <Navbar />
        {home}
        {/* <hr/>
               <SubFooter /> */}
        <Footer />
      </React.Fragment>
    ) : (
      <Container>
        <h2 className="text-center mt-5">
          The site can not be reached. Please check your internet connection or
          try after connecting to a different network.{" "}
        </h2>
      </Container>
    );
  }
}

export default HomeContainer;
