import React from "react";
import { Col, Collapse, Row } from "reactstrap";
import { Card } from "react-bootstrap";
import ToggleDisplay from "react-toggle-display";
import "./team.css";

class TeamToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: this.props.key === 0 ? true : false,
      width: null
    };
  }

  toggleArrow = () => {
    this.setState({ collapse: !this.state.collapse }, () => {
      this.props.getcollapseId(this.props.data.id);
      this.setState({ show: !this.state.show });
      //console.log(this.props.data.id);
    });
  };

  toggleArrowxs = () => {
    this.setState({ collapse: !this.state.collapse }, () => {
      // this.props.getcollapseId(this.props.data.id);
      //console.log(this.props.data.id);
    });
  };

  render() {
    this.state.width = window.innerWidth;

    if (this.state.width >= 1024) {
      return (
        <React.Fragment>
          <Col lg={3} md={6} sm={12} xs={12}>
            <Card className="teamCard">
              <Card.Img
                variant="top"
                src={this.props.data.teamtype_img.url}
                style={{ height: "190px", width: "78%", margin: "auto" }}
              />
              <Card.Body className="text-center">
                <Card.Title>
                  {this.props.data.teamtype_name} <br />
                  {this.props.data.team_type_designation}{" "}
                  {/* {this.props.data.teamtype_designation} */}
                </Card.Title>

                {/* <Collapse toggler="togglerinner" isOpen={this.state.collapse}>
                <p style={{ textAlign: "justify" }}>
                  {this.props.data.team_collapse}
                </p>
              </Collapse> */}
                <div id="togglerinner" onClick={this.toggleArrow}>
                  {this.props.collapseId == this.props.data.id ? (
                    <i
                      class="fa fa-angle-double-up fa-2x "
                      style={{ color: "#ec5f57" }}
                    ></i>
                  ) : (
                    <i class="fa fa-angle-double-down fa-2x "></i>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* <Row>
          {" "}
          <Col xs={12} lg={12} md={12} sm={12}>
            <Collapse toggler="togglerinner" isOpen={this.state.collapse}>
              <p style={{ textAlign: "justify" }}>
                {this.props.data.team_collapse}
              </p>
            </Collapse>
          </Col>
        </Row> */}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Col lg={4} md={6} sm={12} xs={12}>
            <Card className="teamCard">
              <Card.Img
                variant="top"
                src={this.props.data.teamtype_img.url}
                style={{ height: "190px", width: "70%", margin: "auto" }}
              />
              <Card.Body className="text-center">
                <Card.Title>{this.props.data.teamtype_name}</Card.Title>

                <Collapse toggler="togglerinner" isOpen={this.state.collapse}>
                  <div className="Scrollablediv">
                    <p
                      style={{ textAlign: "justify" }}
                      className="DescBox fade-in "
                    >
                      {this.props.data.team_collapse}
                    </p>
                  </div>
                </Collapse>

                <div id="togglerinner" onClick={this.toggleArrowxs}>
                  {this.state.collapse ? (
                    <i
                      class="fa fa-angle-double-up fa-2x"
                      style={{ color: "#ec5f57" }}
                    ></i>
                  ) : (
                    <i class="fa fa-angle-double-down fa-2x"></i>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </React.Fragment>
      );
    }
  }
}

export default TeamToggle;
