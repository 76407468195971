import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import redLogo from '../../assets/TataTrustsNewLogo.png';
import './subFooter.css';
import { Link } from 'react-router-dom';

class SubFooter extends Component {
    render() {
        return(
            <React.Fragment>
               {/* <Container fluid>
                   <Row className="mt-5 mb-4">
                       <Col md={3} className="text-center mt-4">
                          <img src={redLogo} className="tthomelogo"/>
                          <p className="mt-3 subFooterLogoText"></p>
                       </Col>
                       <Col md={3}>
                         <p className="ml-4 subFooterListHeader">MORE ABOUT US</p>
                         <hr/>
                         <ul className="subFooterList">
                             <li><Link to={"/"}>About Us</Link></li>
                             <li><Link to={"/contactUs"}>Contact Us</Link></li>
                             <li><Link to={"/careers"}>Careers</Link></li>
                             <li>Media</li>
                             <li>Accreditation</li>
                         </ul>
                       </Col>
                       <Col md={3}>
                          <p className="ml-4 subFooterListHeader">LINKS</p>
                          <hr/>
                          <ul className="subFooterList">
                            <li>Our Partners</li>
                            <li>Our Services</li>
                            <li><Link to={"/researchMaterial"}>Research Material</Link></li>
                          </ul>
                       </Col>
                       <Col md={3}>
                         
                       </Col>
                   </Row>
               </Container> */}
            </React.Fragment>
        )
    }
}

export default SubFooter;