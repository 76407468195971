import React, {Component} from 'react';
import { Container, Table } from 'reactstrap';
import { Spinner, Row, Col } from 'reactstrap';
import { InputGroup, InputGroupText, InputGroupAddon, Input, Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';
import { convertToDateYYYYMMDD, sortByColumn, convertToDateDDMMYYYY } from '../functions';
import OrderDataCard from './orderDataCard';
import PaginationComponent from '../../components/pagination/pagination';

class CreditPartnerOrderHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
          testtableheader: [
            {table_header: "Sr No.", table_sort_name: ""},
            {table_header: "Order No.", table_sort_name: "id"},
            {table_header: "Order Date", table_sort_name: "createdOn"}
          ],
          testtabledata: [],
          loading: false,
          filter: null,
          toggle: false,
          activeColumn: 0,
          lastActiveColumn: 0,
          collapse: false,
          currentPage: 0
        }
        this.pageSize = 10;
       
    }


    componentWillReceiveProps(props){
        this.setState({
            testtabledata: props.orderHistoryData,
            loading: false
        })
    }

    handleClick = (e, index) => {
    
      e.preventDefault();
  
      this.setState({
        currentPage: index
      });
      
    }

    toggleCard = (orderId) => {
        this.setState({ collapse: !this.state.collapse });      
    }

    updateSearch = (inputValue) => {
      let filter = this.state.filter;

      this.setState({
          filter: inputValue
      });
    }

    handleSearchChange = (event) => {
        this.updateSearch(event.target.value);
    }

    filter = (data) => {
      if (!this.state.filter) {
        return data;
      }
        return data.filter((data) => data.id.toString().indexOf(this.state.filter) >= 0 ||
                                     convertToDateDDMMYYYY(data.createdOn).toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0 
    )
      
    }

    render() {
      const { currentPage } = this.state;
      if(this.state.testtabledata){
      this.pagesCount = Math.ceil(this.state.testtabledata.length / this.pageSize);

      this.state.testtabledata.map((el, key) => {
        el["srno"] = key + 1;
      })
    }
        let list = <Spinner animation="border" variant="danger" />
        if (!this.state.loading) {

           list = <h4>Order</h4>

        }
        return(
          <React.Fragment>
               <Container className="mb-5">
               <Row className="mt-2">
                 <Col md="3">
                 <InputGroup size="lg">
                  <InputGroupAddon addonType="prepend">Search</InputGroupAddon>
                  <Input value={this.state.filter} onChange={this.handleSearchChange} type="text" style={{zIndex: 0}}/>
                </InputGroup>
                 </Col>
               </Row>
               {!this.state.loading ? 
               <React.Fragment>
               <Row className="mt-5">
               <Col md={1} xs={4}className="text-center">Sr No.</Col>
               <Col md={2} xs={4} className="text-center">Order ID</Col>
               <Col md={2} xs={4} className="text-center">Order Date</Col>
               <Col md={7} xs={12} className="text-center">Order List</Col>
               </Row>
               <hr/>
               <Row>
                 <Col className="text-center">
                    {this.state.testtabledata ? this.filter(this.state.testtabledata).slice(
              currentPage * this.pageSize,
              (currentPage + 1) * this.pageSize
            ).map((order, key) => 
                        <OrderDataCard order={order} keyValue = {key}/> 
                        ) : <Spinner color="danger" />
                    }

                 </Col>
               </Row>

               <PaginationComponent currentPage={this.state.currentPage} handleClick={this.handleClick} pagesCount={this.pagesCount}></PaginationComponent>

               </React.Fragment>
               
               : null}
             </Container>
          </React.Fragment>
              
        )
    }
}

const mapStateToProps = state => {
  return {
      loading: state.loading,
      user: state.user,
      data: state.data,
      phistoryData: state.phistoryData,
      pstatusdata: state.pstatusdata
  }
}

const mapDispatchToProps = dispatch => {
  return {
    //onCreditPartnerOrderList: (id) => dispatch(actions.creditPartnerOrderList(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditPartnerOrderHistory);