export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const PATIENT_SIGNUP_SUCCESS = 'PATIENT_SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SUBMIT_PATIENT_SAMPLE = 'SUBMIT_PATIENT_SAMPLE';
export const DATA_SUCCESS = 'DATA_SUCCESS';
export const DATA_FAILURE = 'DATA_FAILURE';
export const SEARCH_DATA_SUCCESS = 'SEARCH_DATA_SUCCESS';
export const SEARCH_DATA_FAILURE = 'SEARCH_DATA_FAILURE';
export const CREDIT_PARTNER_ORDER_SUCCESS = 'CREDIT_PARTNER_ORDER_SUCCESS';
export const CREDIT_PARTNER_ORDER_FAILURE = 'CREDIT_PARTNER_ORDER_FAILURE';
export const CREDIT_PARTNER_ORDER_RESET = 'CREDIT_PARTNER_ORDER_RESET';
export const COURSE_DATA_SUCCESS = 'COURSE_DATA_SUCCESS';
export const COURSE_DATA_FAILURE = 'COURSE_DATA_FAILURE';
export const PATIENT_ORDER_SUCCESS = 'PATIENT_ORDER_SUCCESS';
export const PATIENT_ORDER_FAILURE = 'PATIENT_ORDER_FAILURE';
export const PATIENT_ORDER_STATUS_SUCCESS = 'PATIENT_ORDER_STATUS_SUCCESS';
export const CP_PATIENT_ORDER_STATUS_SUCCESS = 'CP_PATIENT_ORDER_STATUS_SUCCESS';
export const PATIENT_ORDER_STATUS_FAILURE = 'PATIENT_ORDER_STATUS_FAILURE';
export const PATIENT_ORDER_RESET = 'PATIENT_ORDER_RESET';
export const PIN_SUCCESS = 'PIN_SUCCESS';
export const PIN_FAILURE = 'PIN_FAILURE';
export const PATIENT_INVALID_DATA = 'PATIENT_INVALID_DATA';
export const PATIENT_HISTORY_DATA_SUCCESS = 'PATIENT_HISTORY_DATA_SUCCESS';
export const PATIENT_HISTORY_DATA_FAILURE = 'PATIENT_HISTORY_DATA_FAILURE';
export const CREDIT_PARTNER_ORDER_PATIENT_HISTORY_DATA_SUCCESS = 'CREDIT_PARTNER_ORDER_PATIENT_HISTORY_DATA_SUCCESS';
export const CREDIT_PARTNER_ORDER_PATIENT_HISTORY_DATA_FAILURE = 'CREDIT_PARTNER_ORDER_PATIENT_HISTORY_DATA_FAILURE';
export const CREDIT_PARTNER_PATIENT_ORDER_DETAILS_SUCCESS = 'CREDIT_PARTNER_PATIENT_ORDER_DETAILS_SUCCESS';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
export const COURSE_EW_DATA_SUCCESS = 'COURSE_EW_DATA_SUCCESS';
export const COURSE_EW_DATA_FAILURE = 'COURSE_EW_DATA_FAILURE';
export const CAREER_SUCCESS = 'CAREER_SUCCESS';
export const CAREER_FAILURE = 'CAREER_FAILURE';
export const OC_SUCCESS = 'OC_SUCCESS';
export const OC_FAILURE = 'OC_FAILURE';
export const APSEARCH_SUCCESS = 'APSEARCH_SUCCESS';
export const APSEARCH_FAILURE = 'APSEARCH_FAILURE';
export const APSAVE_SUCCESS = 'APSAVE_SUCCESS';
export const APSAVE_FAILURE = 'APSAVE_FAILURE';
export const LOGISTIC_COST_SUCCESS = 'LOGISTIC_COST_SUCCESS';
export const LOGISTIC_COST_FAILURE = 'LOGISTIC_COST_FAILURE';
export const SAMPLE_TYPE_SUCCESS = 'SAMPLE_TYPE_SUCCESS';
export const SAMPLE_TYPE_FAILURE = 'SAMPLE_TYPE_FAILURE';
export const GET_PA_SUCCESS = 'GET_PA_SUCCESS';
export const GET_PA_FAILURE = 'GET_PA_FAILURE';
export const SAMPLE_TEST_SUCCESS = 'SAMPLE_TEST_SUCCESS';
export const SAMPLE_TEST_FAILURE = 'SAMPLE_TEST_FAILURE';
export const EDIT_ADD_SUCCESS = 'EDIT_ADD_SUCCESS';
export const EDIT_ADD_FAILURE = 'EDIT_ADD_FAILURE';
export const EDIT_ORDER_SUCCESS = 'EDIT_ORDER_SUCCESS';
export const EDIT_ORDER_FAILURE = 'EDIT_ORDER_FAILURE';
export const PATIENT_UPDATED_ADD_SUCCESS = 'PATIENT_UPDATED_ADD_SUCCESS';
export const PATIENT_UPDATED_ADD_FAILURE = 'PATIENT_UPDATED_ADD_FAILURE';
export const ENROLL_DATA_SUCCESS = 'ENROLL_DATA_SUCCESS';
export const ENROLL_DATA_FAILURE = 'ENROLL_DATA_FAILURE';
export const WISHLIST_DATA_SUCCESS = 'WISHLIST_DATA_SUCCESS';
export const WISHLIST_DATA_FAILURE = 'WISHLIST_DATA_FAILURE';
export const USER_FORGOT_SUCCESS = 'USER_FORGOT_SUCCESS';
export const USER_FORGOT_FAILURE = 'USER_FORGOT_FAILURE';
export const CREDIT_BALANCE_SUCCESS = 'CREDIT_BALANCE_SUCCESS';
export const CREDIT_BALANCE_FAILURE = 'CREDIT_BALANCE_FAILURE';