import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';
import { Card, CardBody, Container, Row, Col, Spinner, Button } from 'reactstrap';
import { convertToDateFormat, convertToDateYYYYMMDD } from '../functions';
import logo from '../../assets/TataTrustsNewLogo.png';

class PatientInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.onGetPatientUpdatedAddress(this.props.user.id);
    }

    render() {
        
        var date = new Date().getDate();
        var month = ((new Date().getMonth() + 1) <10?'0':'') + (new Date().getMonth() + 1) ;
        var year = new Date().getFullYear();
        var hours = new Date().getHours();
        var time = new Date();
        var min = (time.getMinutes()<10?'0':'') + time.getMinutes() ;
        //console.log(this.props.orderReceiptData);

        return (
            <div>
            <Row noGutters={true}>
            <Col xs={12} sm={6} lg={4} className="mt-2 mb-2"></Col>
                <Col xs={12} sm={6} lg={4} className="text-center mt-5 mb-3">
                  <img src={logo} className="tthomelogoInvoice"/>
                </Col>
            </Row>
            <h3 className="text-center mt-3">Payment Confirmation Voucher</h3>
            <Row className="mt-5 ml-3">
                <Col md={6}><h5>Patient Name: {this.props.user.firstName + ' ' + this.props.user.lastName}</h5></Col>
                <Col md={6}><h5>Order ID: {this.props.orderReceiptData.id}</h5></Col>
            </Row>
            <Row className="mt-3 ml-3">
                <Col md={6}><h5>Patient ID: {this.props.user.id}</h5></Col>
                <Col md={6}><h5>Date: {convertToDateYYYYMMDD(this.props.orderReceiptData.createdOn)}</h5></Col>
            </Row>
            {/* <Row className="mt-3 ml-3">
                <Col md={6}><h5>Transaction ID: </h5></Col>
            </Row> */}

            <Row className='mt-5'>
                <Col md={3} xs={2} className="text-center"><b>Sample Name</b></Col>
                <Col md={6} xs={7} className="text-center"><b>Test Name</b></Col>
                <Col md={3} xs={3} className="text-center"><b>Amount</b></Col>
            </Row><hr/>
            
            {this.props.orderReceiptData.id ? this.props.orderReceiptData.userPatientOrderDetails.map((data) => {
                return <React.Fragment>
                <Row className='mt-4'>
                <Col md={3} xs={2} className="text-center">{data.sampleTest.sample.sampleName}</Col>
                <Col md={6} xs={7} className="text-center">{data.sampleTest.testName}</Col>
                <Col md={3} xs={3} className="text-center">&#8377; {(data.sampleTest.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</Col>
                </Row><hr/>
                </React.Fragment>
            }) : <Spinner color="danger" />
            }
            
            {this.props.patientupdatedAddsuccess ?
                this.props.patientupdatedAddData.islogisticserviceable === 1 && this.props.orderReceiptData.parceledByCustomer === 0?
            <React.Fragment>
            <Row className='mt-4'>
                <Col md={3} xs={2} className="text-center"></Col>
                <Col md={6} xs={7} className="text-center"><b>Logistic Cost</b></Col>
                <Col md={3} xs={3} className="text-center">&#8377; {this.props.orderReceiptData.logisticAmount}</Col>
            </Row><hr/>
            </React.Fragment>
            : null :null}

            <Row className='mt-4'>
                <Col md={3} xs={2} className="text-center"></Col>
                <Col md={6} xs={7} className="text-center"><b>Grand Total</b></Col>
                <Col md={3} xs={3} className="text-center">&#8377; {(this.props.orderReceiptData.totalAmount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</Col>
            </Row><hr/>
           </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        user: state.user,
        patientupdatedAddsuccess: state.patientupdatedAddsuccess,
        patientupdatedAddData: state.patientupdatedAddData,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      onPatientOrderList: (id) => dispatch(actions.patientOrderList(id)),
      onPayNowOrder: (orderId) => dispatch(actions.PayNowOrder(orderId)),
      onGetPatientUpdatedAddress: (id) => dispatch(actions.getPatientUpdatedAddress(id)),
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(PatientInvoice);