import React, {Component} from 'react';
import './media.css';
import { Row, Col, Container } from 'reactstrap';
import Navbar from '../../components/navbar/navbar';

import { Carousel, Spinner } from 'react-bootstrap';
import { Card, Button, CardTitle, CardText } from 'reactstrap';
import dummyImg from '../../assets/dummy.jpg';
import MediaApi from '../../services/API/MediaApi';


import { convertToDateFormat } from '../functions';

class Media extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        MediaApi.getMediaContent().then(res => {
            //console.log(res);
            this.setState({ 
                mediaYoutubeVideoLink: res[0].media_youtube_video_link,
                mediaEventtypes: res[0].mediaeventtypes,
                mediaNewsTypes: res[0].medianewstypes,
                loading: false
             });
             
          });
    }

    render(){
        return(
            <React.Fragment>
                <Navbar/>
                <Container className="container-box mt-3 media-background mb-3">
                    <Row className="pt-3 pl-3 pr-3 pb-0">
                        <Col md={10}>
                            <h1 className="red-font">Social</h1>
                        </Col>
                        <Col md={2} className="mt-3">
                            <h5>10th April 2019</h5>
                        </Col>
                    </Row>
                    <Row className="p-3">
                        <Col md={4}>
                        <a class="twitter-timeline" data-width="300" data-height="500" data-theme="light" data-link-color="#E95F28" href="https://twitter.com/tatatrusts?ref_src=twsrc%5Etfw">Tweets by tatatrusts</a>
                        </Col>
                        <Col md={4} className="mt-3">
                        <h5>Upcoming Events</h5>
                        <div className="events-timeline">
                        {this.state.mediaEventtypes ? this.state.mediaEventtypes.map((type) => {
                            return <Row className="mb-3 pr-0 pr-md-3">
                            <Col>
                            <Card body className="media-card">
                                <CardTitle><span>{type.media_event_title}</span><span className="pull-right">{convertToDateFormat(type.media_event_date)}</span></CardTitle>
                                <CardText>{type.media_event_content}</CardText>
                                <p className="text-right red-font mb-0">Read More</p>
                            </Card>
                            </Col>
                            </Row>
                            }) : <Spinner color="danger"></Spinner>
                        }
                        
                        </div>
                        </Col>
                        <Col md={4} className="mt-3">
                        <Row>
                            <Col>
                            <Card body className="media-card p-0 carousel-card">
                            <Carousel controls={false}>
                                <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={dummyImg}
                                    alt="First slide"
                                />
                                </Carousel.Item>
                                <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={dummyImg}
                                    alt="Third slide"
                                />
                                </Carousel.Item>
                                <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={dummyImg}
                                    alt="Third slide"
                                />
                                </Carousel.Item>
                                </Carousel>
                                <Row>
                                    <Col>
                                        <p className="mt-3 pl-3 pr-3">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        </p>
                                        <p className="text-right red-font mb-0 pl-3 pr-3">Read More</p>
                                    </Col>
                                </Row>
                            </Card>
                            </Col>
                        </Row>
                        </Col>
                    </Row>   
                </Container>


                <Container className="container-box mt-5 news-background mb-3">
                    <Row className="pt-3 pl-3 pr-3 pb-0">
                        <Col md={2}>
                            <h1 className="red-font">News |</h1>
                        </Col>
                        <Col md={8}>
                            <h5 className="mt-3">News from around the globe</h5>
                        </Col>
                        <Col md={2} className="mt-3">
                            <h5>10th April 2019</h5>
                        </Col>
                    </Row>
                    <Row className="p-3">
                        <Col md={7}>
                        <iframe className="youtube-iframe" width="600" height="315"
                                src={this.state.mediaYoutubeVideoLink}>
                        </iframe>
                        </Col>
                        <Col md={5} className="mt-3">
                        <div className="events-timeline">
                        {this.state.mediaNewsTypes ? this.state.mediaNewsTypes.map((type) => {
                            return <Row className="mb-3 pr-0 pr-md-3">
                            <Col>
                            <Card body className="media-card">
                                <CardTitle><span>{type.media_news_title}</span><span className="pull-right">{convertToDateFormat(type.media_news_date)}</span></CardTitle>
                                <CardText>{type.media_news_content}</CardText>
                                <p className="text-right red-font mb-0">Read More</p>
                            </Card>
                            </Col>
                            </Row> 
                            }) : <Spinner color="danger"></Spinner>
                        }
                        </div>
                        </Col>
                    </Row>   
                </Container>

               
                
            </React.Fragment>
        )
    }
}

export default Media;
